/* eslint-disable no-debugger */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';

import { User, ApplicationUser } from '../models/User';
import { FIELDS } from '../constants/FIELDS';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { map, tap, delay, finalize, filter, takeUntil, catchError } from 'rxjs/operators';
import { LoginResult, RefreshTokenResponse } from '../models/LoginResult';
import { SharedService } from './shared.service';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private timer: Subscription;
  public isLogged: boolean;
  private isNonAcUserLogged = new BehaviorSubject<boolean>(false);
  private _user = new BehaviorSubject<ApplicationUser>(null);
  user$: Observable<ApplicationUser> = this._user.asObservable();
  username: string;
  userPassword: string;
  screenKey: string;
  public userDetails: BehaviorSubject<any>;
  public profilePicture: BehaviorSubject<string>;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private storageService: StorageService,
    private _injector: Injector,
    private httpClient: HttpClient,
    private sharedService: SharedService,
    private msalService: MsalService
  ) {
    this.userDetails = new BehaviorSubject<User>(new User());
    this.isLogged = false;
    this._destroying$ = new Subject<void>();

  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private refreshAuthUser() {
    console.log('refreshAuthUser');
    this.userLogin(
      this.username,
      this.userPassword,
      this.screenKey
    );
  }


  // Helper property to resolve the service dependency.
  private get router() {
    return this._injector.get(Router);
  }
  private get http() {
    return this._injector.get(HttpClient);
  }

  loadScreens(): Observable<any[]>  {
    let url = `${environment.baseUrlAuth}/Account/loadScreens`;
    return this.http.get<any[]>(url);
  }

  forgotPassword(reqData: any) {
    let url = `${environment.baseUrlAuth}/Account/forgot-password?email=${reqData.email}`;
    return this.http.get(url)
      .toPromise()
      .then((data: any) => {
        return data;
      });
  }

  resetPassword(reqData: any) {
    let url = `${environment.baseUrlAuth}/Account/reset-password`;
    return this.http.post(url, reqData)
      .toPromise()
      .then((data: any) => {
        return data;
      });
  }

  changePassword(reqData: any) {
    const userDto = this.storageService.getJsonData(FIELDS.USER_DETAILS);
    reqData = {...reqData, Email : userDto.Username};
    console.log("reqData", reqData)
    let url = `${environment.baseUrlAuth}/Account/change-password`;
    return this.http.post(url, reqData)
      .toPromise()
      .then((data: any) => {
        return data;
      });
  }

  userLogout() {
    let url = `${environment.baseUrlAuth}/Account/logout`;
    return this.http.get(url)
      .toPromise()
      .then((data: any) => {
        return data;
      });
  }

  userLogin(
    username: any,
    userPassword: any = null,
    screenKey: any = '',
    sso: boolean = false
  ) {
    let url = `${environment.baseUrlAuth}/Account/login`;
    return this.http.post(url, {
      Username: username,
      Password: userPassword,
      RememberLogin: true,
      ReturnUrl: '',
      ScreenKey: screenKey,
      SSO: sso
    })
      .toPromise()
      .then((resData: any) => {
        if (resData.status == 'Success') {
          if (sso) {
            this.storageService.saveData('loginType', 'sso');
          } else {
            this.storageService.saveData('loginType', 'password');
          }
          this.storageService.saveJsonData(FIELDS.USER_DETAILS, {
            Username: username,
            ViewingPage: resData.viewingPage
          });
          this.setLocalStorage({ AccessToken: resData.response.accessToken, RefreshToken: resData.response.refreshToken });
          this.isLogged = true;
          this.startTokenTimer();
        }
        return resData;
      });

  }


  async refreshToken() {
    try {
    const refreshToken = this.storageService.getData('refresh_token');
    const accessToken = this.storageService.getData(FIELDS.ACCESS_TOKEN);
    const userDto = this.storageService.getJsonData(FIELDS.USER_DETAILS);
    let userName = '';
    if (userDto) {
      userName = userDto.Username;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const postdata = {
      refreshToken: refreshToken,
      accessToken: accessToken,
      userName: userName,
    };
    if (!refreshToken) {
      // this.storageService.clearAllExceptRememberMe();
      // return of(null);
      throw new Error('No refresh token found.');
    }
      const response = await this.http
        .post(`${environment.baseUrlAuth}/Account/refresh-token`, postdata, httpOptions)
        .pipe(
          map((x: any) => {
            this.setLocalStorage({ AccessToken: x.response.accessToken, RefreshToken: x.response.refreshToken });
            this.startTokenTimer();
            this.isLogged = true;
            return x;
          }),
          catchError((error) => {
            // Handle the error
            return this.handleRefreshTokenError(error); 
          })
        )
        .toPromise();
  
      return response; // Return the response for the await
    } catch (error) {
      // Handle the error if needed
      return this.handleRefreshTokenError(error); 
    }
  }

  private handleRefreshTokenError(error: any): Observable<any> {
    // Log the error for debugging
    console.error('Refresh Token Error:', error);

    // Clear local storage for authentication failures
    this.storageService.clearAllExceptRememberMe();
    this.isLogged = false;

    // Redirect to login page on errors
    this.router.navigate(['/auth/login']);

    // Return an observable with a null result or some default value
    return of(null);
  }

  setLocalStorage(x: any) {
    localStorage.setItem(FIELDS.ACCESS_TOKEN, x.AccessToken);
    localStorage.setItem('refresh_token', x.RefreshToken);
  }

  public getTokenRemainingTime() {
    const accessToken = localStorage.getItem(FIELDS.ACCESS_TOKEN);
    if (!accessToken) {
        return 0; // No token found
    }

    // Split the token and check if it has 3 parts
    const tokenParts = accessToken.split('.');
    if (tokenParts.length !== 3) {
        console.error('Invalid JWT format:', accessToken);
        return 0; // Invalid token format
    }

    try {
        const jwtToken = JSON.parse(atob(tokenParts[1]));
        const expires = new Date(jwtToken.exp * 1000);
        return expires.getTime() - Date.now();
    } catch (error) {
        console.error('Error parsing token:', error);
        return 0; // Handle parsing error
    }
}

  private startTokenTimer() {
    const timeout = this.getTokenRemainingTime();
    this.timer = of(true)
      .pipe(
        delay(timeout),
        tap(async () => {
          try {
            await this.refreshToken(); // Await the refresh token call
          } catch (error) {
            console.error('Error refreshing token:', error);
            this.stopTokenTimer(); // Stop the timer on error
          }
        })
      )
      .subscribe();
  }

  private stopTokenTimer() {
    if (this.timer) {
      this.timer.unsubscribe();
    }
  }

  isAuthenticated() {

    this.isLogged = !!this.msalService.getAccount();
    return this.isLogged;
  }


  getUserEmail() {
    const account = this.msalService.getAccount();

    if (account) {
      return account.userName;
    }
    return null;
  }




  isValidUser(user: User): boolean {
    return !(user === null || user === undefined);
  }

  getUserDetails(): any {
    return this.httpClient
      .get(this.sharedService + "/user")
      .subscribe((userDetails: User) => {
        this.userDetails.next(userDetails);
      });
  }

  setUserDetails(userDetail: any) {
    this.userDetails.next(userDetail);
  }

  setProfilePicture(base64Image: string) {
    if (this.profilePicture) {
      this.profilePicture.next(base64Image);
    }
  }

  getProfilePicture(): any {
    return this.profilePicture;
  }

  getUserRoleId(): number {
    const user = localStorage.getItem('userInfo');
    if (user) {
      const userObj = JSON.parse(user);
      return +userObj.roleId;
    }

    return undefined;
  }

  logout() {
    const loginType = this.storageService.getData('loginType');
    if (loginType == 'sso') {
      this.storageService.clearAllExceptRememberMe();
      this.router.navigate(['/auth/login']);
    } else {
      try {
        this.userLogout()
          .then((response) => {
            if (response && response.status == "Success") {
              this.storageService.clearAllExceptRememberMe();
              this.router.navigate(['/auth/login']);
            }
          });
      } catch (e) {
        console.error('forgotPassword Error: ', e.errorMessage);
        this.storageService.clearAllExceptRememberMe();
        this.router.navigate(['/auth/login']);
      }
    }

  }
}
