import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import * as Rx from "rxjs";
import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root'
})
export class RelationshipFilterService {
  [x: string]: any;

  private serviceUrl = this.sharedService.configuration.baseUrl;

  filterObj = {
    "StartDate": "",
    "EndDate": "",
    "PeriodType": "",
    "BACode": "",
    "DivCode": "",
    "FAMCode": "",
    "BrandName": "",
    "Country": "",
    "OperationalCountry": "",
    "Zone": "",
    "Branch": "",
    "Influencer": "",
    "Continent": "",
    "DistributorSegment": "",
    "Distributor": "",
    "PinnedField": "",
    "SubCategory": "",
    "SurveyID": "",
    "SurveyInstanceID": "",
    "DistributorManagerSales": "",
    "DistributorManagerService": ""

  }

  selectedFilters = {
    baCount: [],
    divCount: [],
    famCount: [],
    countryCount: [],
    operationalCountryCount: [],
    brandCount: [],
    zoneCount: [],
    branchCount: [],
    influencerCount: [],
    continentCount: [],
    distributorTypeCount: [],
    distributorCount: [],
    distributorSegmentCount: [],
    industrySegmentCount: [],
    distributorManagerSalesCount: [],
    distributorManagerServiceCount: [],
  }

  globalFilterBehaviousSubject = new Rx.BehaviorSubject<any>(this.filterObj);
  surveyListSubject = new Rx.BehaviorSubject<any | null>(null);
  advancedSearchDateFilterSubject = new Rx.BehaviorSubject<any>(null);
  globalFilterSelectedFilterSubject = new Rx.BehaviorSubject(this.selectedFilters);
  constructor(private http: HttpClient, private sharedService: SharedService) {
    //this.filterService.globalFilterBehaviousSubject.next(this.filterObj);
  }

  getAllData(surveySubCategory: string) {
    return this.http.get(this.serviceUrl + "api/Dashboard/GetMasterFilterData/" + "Distributor" + "?" + Math.random())
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  saveUserFilter(filterObject, isDate: boolean, surveyType) {
    return this.http.post(this.serviceUrl + "api/Dashboard/AddUpdateUserFilter/" + surveyType + "?IsDateParam=" + isDate, filterObject)
      .toPromise()
      .then((res: any) => { return res });
  }

  getUserFilter(surveyType, feedbackListFlag = false, nameChnages =  false): Promise<any> {

    return new Promise((resolve, reject) => {
      let filterData = JSON.parse(window.sessionStorage.getItem("globalFilterObjectRelationship"));
      console.log(filterData,'filterData')
      console.log(nameChnages,'nameChnages')
      if (!filterData ||  nameChnages) {
        this.http.get(this.serviceUrl + "api/Dashboard/GetUserFilter/" + surveyType + "?" + Math.random())
          .toPromise()
          .then((res: any) => {
            console.log(res,'resresresres')
            if (res != null && res.Status == "Success" && res.Response != null) {
              // var filterObjectString = sessionStorage.getItem("globalFilterObjectRelationship");
              // if (filterObjectString == null) {
              /* if (!feedbackListFlag && !res.Response.IsSavedDate) {
                res.Response.StartDate = null;
                res.Response.EndDate = null;
              } */
              this.mapFilterObject(res.Response, true);
              sessionStorage.setItem("globalFilterObjectRelationship", JSON.stringify(res.Response));
              // }              
            }

            return resolve(res);
          }).catch(e => {
            return reject({
            })
          });
      }
      else {
        var filterObjectStringDate = sessionStorage.getItem("periodFilterObjectRelationship");
        if (filterObjectStringDate != null) {
          var dateFilters = JSON.parse(filterObjectStringDate)
          this.filterObj.StartDate = dateFilters.StartDate;
          this.filterObj.EndDate = dateFilters.EndDate;
          this.filterObj.PeriodType = dateFilters.PeriodType;
        }
        this.mapFilterObject(filterData, false);
        return resolve(this.filterObj);
      }
    })
  }

  clearUserFilter(surveyType) {
    return this.http.post(this.serviceUrl + "api/Dashboard/ClearUserFilter/" + surveyType, null)
      .toPromise()
      .then((res: any) => { return res });
  }

  dataOriginal = [];
  zoneBranchOriginal = [];
  countryContinentOriginal = [];
  brandOriginal = [];
  //   engineerAndTechnicianOriginal = [];

  operationalCountryContinentOriginal = [];
  touchPointsOriginal = [];
  influencersOriginal = [];
  distributorSegmentOriginal = [];
  distributorOriginal = [];
  industrySegmentOriginal = [];
  distributorManagerSalesOriginal = [];
  distributorManagerServiceOriginal = [];
  mapFilterObject(obj, includeDateFields) {
    if (includeDateFields) {
      this.filterObj.StartDate = obj.StartDate == null ? "" : obj.StartDate;
      this.filterObj.EndDate = obj.EndDate == null ? "" : obj.EndDate;
      this.filterObj.PeriodType = obj.PeriodType == null ? "" : obj.PeriodType;

      var dateObject = {
        "StartDate": obj.StartDate == null ? "" : obj.StartDate,
        "EndDate": obj.EndDate == null ? "" : obj.EndDate,
        "PeriodType": obj.PeriodType == null ? "" : obj.PeriodType,
      }



      sessionStorage.setItem("periodFilterObjectRelationship", JSON.stringify(dateObject));
    }

    this.filterObj.BACode = obj.BACode == null ? "" : obj.BACode;
    this.filterObj.FAMCode = obj.FAMCode == null ? "" : obj.FAMCode;
    this.filterObj.DivCode = obj.DivCode == null ? "" : obj.DivCode;
    this.filterObj.Country = obj.Country == null ? "" : obj.Country;
    this.filterObj.OperationalCountry = obj.OperationalCountry == null ? "" : obj.OperationalCountry;
    this.filterObj.BrandName = obj.BrandName == null ? "" : obj.BrandName;
    this.filterObj.Zone = obj.Zone == null ? "" : obj.Zone;
    this.filterObj.Branch = obj.Branch == null ? "" : obj.Branch;
    this.filterObj.Influencer = obj.Influencer == null ? "" : obj.Influencer;
    this.filterObj.Continent = obj.Continent == null ? "" : obj.Continent;
    this.filterObj.PinnedField = obj.PinnedField == null ? "" : obj.PinnedField;
    this.filterObj.Distributor = obj.Distributor == null ? "" : obj.Distributor;
    this.filterObj.DistributorSegment = obj.DistributorSegment == null ? "" : obj.DistributorSegment;
    this.filterObj.DistributorManagerSales = obj.DistributorManagerSales == null ? "" : obj.DistributorManagerSales;
    this.filterObj.DistributorManagerService = obj.DistributorManagerService == null ? "" : obj.DistributorManagerService;
    this.filterObj.SurveyID = obj.SurveyID == null ? "" : obj.SurveyID;
    this.filterObj.SubCategory = obj.SubCategory == null ? "" : obj.SubCategory;
    this.filterObj.SurveyInstanceID = obj.SurveyInstanceID == null ? "" : obj.SurveyInstanceID;
    //console.log('this.filterObj.service',this.filterObj);
  }

  public updateSelectedFilter = (selectedFilters: any) => {
    this.globalFilterSelectedFilterSubject.next(JSON.parse(JSON.stringify(selectedFilters)));
  }

  updateFilterObject(): any {
    var filterObjectString = sessionStorage.getItem("globalFilterObjectRelationship");
    var periodFilterString = sessionStorage.getItem("periodFilterObjectRelationship");

    if (filterObjectString != null) {
      var obj = JSON.parse(filterObjectString);
      this.mapFilterObject(obj, false);
    }
    if (periodFilterString != null) {
      var obj = JSON.parse(periodFilterString);
      this.filterObj.StartDate = obj.StartDate;
      this.filterObj.EndDate = obj.EndDate;
      this.filterObj.PeriodType = obj.PeriodType;
    }
    if(window.location.href.includes("Distributor_Survey/dashboard/distributor")) {
      this.globalFilterBehaviousSubject.next(this.filterObj);
    } 
   
  }
}
