export class ChartBindingsModel {
  xAxis: string[];
  yAxis: { name: string, color?: string, data: any[], type?: string, placeholder: string, stack?: number, yAxis?: number, 
    tooltip?: object, dataLabels?: object, lineWidth?: number, marker?: object, events?: any, showInLegend?: boolean,
    clickedCount?: number, deliveredCount?: number, openedCount?: number, processedCount?: number,
    currentDaysCount?: number, previousDaysCount?: number, actualBounceBackCount?: number, 
    correctedBounceBackCount?: number, completedCount?: number, completedCBBCount?: number, 
    ClickedCount?: number, OpenedCount?: number, DeliveredCount?: number, ProcessedCount?: number,
    NoResposeCntCbb?: number, NoResposeCnt?: number, RTICount?: number, RTICBBCount?: number,
    CurrentDaysCount?: number, PreviousDaysCount?: number, CurrentCompletedCount_CBB?: number, PreviousCompletedCount_CBB?: number,
    toolTipData?: string
  }[];
  // for cc charts
  currentChartView?: string;
  chartRawData?: any;

  constructor() {
    this.xAxis = [];
    this.yAxis = [];
  }
}

export class CountryDrillDownModel {
  label: string;
  value: string;
  hasFurtherDrillDown: boolean;
  // drillDownOptions: CountryDrillDownModel[];
  showFor: CONTEXT_MENUES;
  // constructor(label:string = '', value: string = '', hasFurtherDrillDown: boolean = false, ddOptions: CountryDrillDownModel[] = []) {
  constructor(label: string = '', value: string = '', hasFurtherDrillDown: boolean = false, showFor: CONTEXT_MENUES = CONTEXT_MENUES.UNKNOWN) {
    this.label = label.toLowerCase();
    this.value = value.toLowerCase();
    this.hasFurtherDrillDown = hasFurtherDrillDown;
    // this.drillDownOptions = hasFurtherDrillDown ? ddOptions : [];
    this.showFor = showFor;
  }
}

export enum DD_STATE {
  L1 = 1,
  L2 = 2,
  L3 = 3,
  COMMON = 4
}
export enum CONTEXT_MENUES {
  SE = 'engineer',
  ST = 'technician',
  BRANCHES = 'branch',
  ZONE = 'zone',
  OPERATIONAL_COUNTRY = 'operationalcountry',
  FAM_COMPARISON = 'fam',
  COUNTRY_COMPARISON = 'country',
  BRAND_COMPARISON = 'brand',
  UNKNOWN = 'unknown',
  ProjectManager = 'ProjectManager',
}

export const CommonDDOptions: CountryDrillDownModel[] = [
  new CountryDrillDownModel('SE', CONTEXT_MENUES.SE.toLowerCase(), false),
  new CountryDrillDownModel('ST', CONTEXT_MENUES.ST.toLowerCase(), false)
];
export const L3DDOptions: CountryDrillDownModel[] = [
  // new CountryDrillDownModel('Branches', CONTEXT_MENUES.BRANCHES.toLowerCase(), true, CommonDDOptions),
  // ...CommonDDOptions

  new CountryDrillDownModel('SE', CONTEXT_MENUES.SE.toLowerCase(), true, CONTEXT_MENUES.BRANCHES),
  new CountryDrillDownModel('ST', CONTEXT_MENUES.ST.toLowerCase(), true, CONTEXT_MENUES.BRANCHES)
];
export const L2DDOptions: CountryDrillDownModel[] = [
  // new CountryDrillDownModel('Country Comparison', CONTEXT_MENUES.COUNTRY_COMPARISON.toLowerCase(), false),
  // new CountryDrillDownModel('Brand Comparison', CONTEXT_MENUES.BRAND_COMPARISON.toLowerCase(), false),
  // new CountryDrillDownModel('Zone', CONTEXT_MENUES.ZONE.toLowerCase(), true, L3DDOptions),
  // ...L3DDOptions

  new CountryDrillDownModel('Branch', CONTEXT_MENUES.BRANCHES.toLowerCase(), true, CONTEXT_MENUES.ZONE),
  new CountryDrillDownModel('SE', CONTEXT_MENUES.SE.toLowerCase(), false, CONTEXT_MENUES.ZONE),
  new CountryDrillDownModel('ST', CONTEXT_MENUES.ST.toLowerCase(), false, CONTEXT_MENUES.ZONE)
];
export const L1DDOptions: CountryDrillDownModel[] = [
  // new CountryDrillDownModel('FAM Comparison', CONTEXT_MENUES.FAM_COMPARISON.toLowerCase(), true, L2DDOptions),
  new CountryDrillDownModel('FAM Comparison', CONTEXT_MENUES.FAM_COMPARISON.toLowerCase(), true),
  new CountryDrillDownModel('Country Comparison', CONTEXT_MENUES.COUNTRY_COMPARISON.toLowerCase(), false),
  new CountryDrillDownModel('Brand Comparison', CONTEXT_MENUES.BRAND_COMPARISON.toLowerCase(), false),

  new CountryDrillDownModel('Zone', CONTEXT_MENUES.ZONE.toLowerCase(), false, CONTEXT_MENUES.FAM_COMPARISON),
  new CountryDrillDownModel('Branch', CONTEXT_MENUES.BRANCHES.toLowerCase(), false, CONTEXT_MENUES.FAM_COMPARISON),
  new CountryDrillDownModel('SE', CONTEXT_MENUES.SE.toLowerCase(), false, CONTEXT_MENUES.FAM_COMPARISON),
  new CountryDrillDownModel('ST', CONTEXT_MENUES.ST.toLowerCase(), false, CONTEXT_MENUES.FAM_COMPARISON),
  new CountryDrillDownModel('ProjectManager', CONTEXT_MENUES.ProjectManager.toLowerCase(), false, CONTEXT_MENUES.FAM_COMPARISON),
  new CountryDrillDownModel('Operational Country', CONTEXT_MENUES.OPERATIONAL_COUNTRY.toLowerCase(), false, CONTEXT_MENUES.FAM_COMPARISON)
];
export class DDState {
  private _dropDownViewState: DD_STATE;
  public dropDownOptionsVisible: CountryDrillDownModel[];

  public getDropDownViewState(): DD_STATE {
    return this._dropDownViewState;
  }
  public setDropDownViewState(v: DD_STATE) {
    this._dropDownViewState = v;
    switch (v) {
      case DD_STATE.COMMON:
        this.dropDownOptionsVisible = CommonDDOptions;
        break;
      case DD_STATE.L1:
        this.dropDownOptionsVisible = L1DDOptions;
        break;
      case DD_STATE.L2:
        this.dropDownOptionsVisible = L2DDOptions;
        break;
      case DD_STATE.L3:
        this.dropDownOptionsVisible = L3DDOptions;
        break;

      default:
        this.dropDownOptionsVisible = L1DDOptions;
        break;
    }
  }

  constructor() {
    this.setDropDownViewState(DD_STATE.L1);
  }
}
