import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CtlsVocListingService } from '../../ctls-services/ctls-voc-listing.service';
import { Subject } from 'rxjs';
import WaveSurfer from 'wavesurfer.js/dist/wavesurfer.esm.js';
import { CtlsVocEditScreenService } from '../../ctls-services/ctls-voc-edit-screen.service';
import { CtlsSpinnerService } from '../ctls-spinner/ctls-spinner.service';
import { CtlsTaskListingService } from '../../ctls-services';

@Component({
  selector: 'app-ctls-voc-attachment',
  templateUrl: './ctls-voc-attachment.component.html',
  styleUrls: ['./ctls-voc-attachment.component.scss']
})
export class CtlsVocAttachmentComponent implements OnInit {
  @Input() idToProcess: any;
  @Input() fromPage: any;
  private imagepath = environment.imagepath;
  loader = false;
  attachments: any;
  audioAttachmentArray: any[];
  attachmentArray: any[];
  TotalAttachmentArray: any[];
  destroy$ = new Subject();
  outsideFlag=false;
  attachmentLabelshowId: number|null;
// 
// @Input() audioAttachmentArray: any;
@Output()
eventFromAttachmentScreen: EventEmitter<any> = new EventEmitter<any>();
  constructor(private ctlsVocListingService: CtlsVocListingService,
    public ctlsTaskEditScreenService: CtlsVocEditScreenService,
    private spinnerService: CtlsSpinnerService,
    private ctlsTaskListingService:  CtlsTaskListingService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.spinnerService.show();
    this.loader = true;
    if(this.idToProcess && this.fromPage==='voc') {
      this.ctlsVocListingService.getVOCAttachments(this.idToProcess).pipe(takeUntil(this.destroy$)).subscribe(
        (res) => {
          this.attachments = res.Response;
          if(this.attachments && this.attachments.length > 0) {
            this.seperateFileType();
             this.spinnerService.hide();
          }
        }
      )
    }else if(this.idToProcess && this.fromPage==='taskListing'){
      this.ctlsTaskListingService.getCTLSAttachments(this.idToProcess).pipe(takeUntil(this.destroy$)).subscribe(
        (res) => {
          this.attachments = res.Response;
          if(this.attachments && this.attachments.length > 0) {
            this.seperateFileType();
             this.spinnerService.hide();
          }
        }
      )
    } else if(this.idToProcess && this.fromPage==='360'){
      this.ctlsVocListingService.getVOCAttachments(this.idToProcess).pipe(takeUntil(this.destroy$)).subscribe(
        (res) => {
          this.attachments = res.Response;
          if(this.attachments && this.attachments.length > 0) {
            this.seperateFileType();
             this.spinnerService.hide();
          }
        }
      )
    }
    this.spinnerService.hide();
  }

  seperateFileType(){
    this.audioAttachmentArray = [];
    this.attachmentArray = [];
    this.TotalAttachmentArray = [];
    const supportedAudioExtensions = new Set(['ogg', 'wav', 'mp3', 'webm', 'aac', 'm4a']);

    this.attachments.forEach(fileEn => {
      const fileParts = fileEn.FileName.split('.');
      const extension = fileParts.length > 1 ? fileParts[fileParts.length - 1].toLowerCase() : '';

      let audioType = '';
      if (extension === 'm4a') {
        audioType = 'audio/x-m4a';
      } else if (supportedAudioExtensions.has(extension)) {
        audioType = `audio/${extension}`;
      }

      fileEn.extension = extension;
      fileEn.audioType = audioType;

      this.TotalAttachmentArray.push(fileEn);
      if (audioType) {
        this.audioAttachmentArray.push(fileEn);
      } else {
        this.attachmentArray.push(fileEn);
      }
    });

    this.audioAttachmentArray = this.audioAttachmentArray.map((audio) => ({
      ...audio,
      isPlaying: false,
      duration: '00:00',
      wavesurfer: null,
    }));
    this.loader = false;
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.initializeWaveSurfers();
    }, 0);
}

initializeWaveSurfers() {
  this.audioAttachmentArray.forEach((audio, index) => {
    const waveContainer = document.getElementById(
      `initial-attachment-audio-list-audioPlayer-${index}Wave`
    );
    if (waveContainer) {
      const wavesurfer = WaveSurfer.create({
        container: waveContainer,
        waveColor: '#b1b1b1',
        progressColor: '#c6c6c6',
        height: 28,
        barWidth: 2,
        barHeight:0.5,
        minPxPerSec: 1,
        width: "100",
        url: audio.URL,
      });
      wavesurfer.on('ready', () => {
        audio.duration = this.formatTime(wavesurfer.getDuration()*1000)
      });
      wavesurfer.on('play', () => (audio.isPlaying = true));
      wavesurfer.on('pause', () => (audio.isPlaying = false));
      audio.wavesurfer = wavesurfer;
      this.changeDetectorRef.detectChanges();
    }
  });
}

togglePlay(index: number, event: MouseEvent) {
  event.stopPropagation();
  const audio = this.audioAttachmentArray[index];
  if (audio.wavesurfer) {
    audio.wavesurfer.playPause();
  }
}

  waveClick(event: MouseEvent){
    event.stopPropagation();
  }

  downloadAudio(audio: any, event: MouseEvent){
    event.stopPropagation();
    this.downloadImg(audio);
  }

  downloadImg(img) {
    if(this.fromPage==='voc' || (this.fromPage==='360' && img.VOCTaskFilesListID)){
      this.ctlsTaskEditScreenService
        .GetFileUrl(img.VOCTaskFilesListID)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.Response) {
            const downloadLink = document.createElement('a');
            downloadLink.href = res.Response;
            downloadLink.download = img.FileName;
            downloadLink.click();
          }
        });
    }else if(this.fromPage==='taskListing' || (this.fromPage==='360' && img.CTLSTaskFilesListID)){
      this.ctlsTaskEditScreenService
        .GetImgUrl(img.CTLSTaskFilesListID)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.Response) {
            const downloadLink = document.createElement('a');
            downloadLink.href = res.Response;
            downloadLink.download = img.FileName;
            downloadLink.click();
          }
        });
    }
  }

  formatTime = (milliseconds) => {
    const seconds = Math.ceil(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    const formattedTime =
      minutes % 60 + ':' +
      this.pad(seconds % 60);

    return formattedTime;
  }
  pad = (number) => {
    return ('0' + number).slice(-2);
  }

  openAttachement(attachment: any, event: MouseEvent): void {
    event.stopPropagation();
    let extensions = ['jpg','jpeg','png','tiff','bmp', 'xlsx', 'xls', 'doc', 'docx', 'pdf', 'ppt', 'pptx'];
      if(extensions.includes(attachment.extension))
        this.eventFromAttachmentScreen.emit({
          operation: 'SHOW_ATTACHMENT',
          attachment: attachment,
        });
      else this.downloadImg(attachment);
  }
  
  hideFileName = (event: MouseEvent)=>{
    event.stopPropagation();
    this.attachmentLabelshowId = null;
   };

  showFilename = (index: number, event: MouseEvent)=>{
    event.stopPropagation();
    this.attachmentLabelshowId = index
   };

  togglePopup(){
    this.eventFromAttachmentScreen.emit({
      operation: 'CLOSE_POPUP',
      attachment: [],
    }); 
  }

  
  mouseEnter(){
    this.outsideFlag = false;
  }
  mouseLeave(){
    this.outsideFlag = true;
  }

  @HostListener('document:click', ['$event']) clickedOutside($event){
    if (this.outsideFlag) {
        this.eventFromAttachmentScreen.emit({
        operation: 'CLOSE_POPUP',
        attachment: [],
      }); 
    }
  }
}
