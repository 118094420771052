import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ExportChartModel } from 'src/app/shared/models/export-chart.model';
// import { ExportChartsService } from 'src/app/shared/services/export-charts.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ExportConfigModel, WidgetExportModel } from './model/ppt-exportmodel.model';
import * as cloneDeep from 'lodash/cloneDeep';
import { DistDashbaordService } from '../../Services/dist-dashbaord.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FilterService } from 'src/app/shared/services/filter.service';
import { environment } from 'src/environments/environment';
import { Subject } from '@microsoft/signalr';
import { RelationshipDashboardSharedEventService } from 'src/app/shared/services/relationship-dashboard-shared-event.service';
import { ChartSharedService } from '../../Services/chart-shared.service';
import { IMAGE_EXTRACTION } from 'src/app/shared/enums/export-state-enum';
import { ExportDistChartsService } from 'src/app/shared/services/export-dist-charts-service';
import { RelationshipFilterService } from 'src/app/shared/services/filter-relationship.service';

@Component({
  selector: 'app-ppt-download',
  templateUrl: './ppt-download.component.html',
  styleUrls: ['./ppt-download.component.scss']
})
export class PptDownloadComponent implements OnInit {
  
  private imagepath = environment.imagepath;
  @Input() blockUi: boolean = false;
  @Output() widgetToDownload: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() seletedCategory :any;
  exportconfiguration: ExportConfigModel = new ExportConfigModel();
  showDownloadPanel: boolean = false;
  readyCharts: { value: string, label: string }[] = [];
  exportWidgetList: string [] = [];
  boolViewSelectorComponentLoader: boolean = false;
  msgs: any = [];
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  isNPSExpandeble: boolean;
  isCenterChecked: boolean = true;
  isDDChecked: boolean = false;
  successMsgForDownload: any = false;

  private globFilterSub: Subscription;
  private widgetLeftLocationSubscription: Subscription;
  private widgetNpsLocationSubscription: Subscription;
  private widgetCenterLocationSubscription: Subscription;
  private widgetDDLocationSubscription: Subscription;
  loading: boolean;
  receivedCount: number;
  results: any[];
  selectedChartsForExport: any[];
  pptExportBlockUI: boolean;
  globalFilterData: { StartDate: string; EndDate: string; PeriodType: string; BACode: string; FAMCode: string; DivCode: string; Country: string; OperationalCountry: string; BrandName: string; Continent: string; Region: string; Zone: string; Branch: string; PinnedField: string; Influencer: string, DistributorSegment: string,Distributor: string,SubCategory: string,SurveyID: string,SurveyInstanceID: string,DistributorManagerSales: string,DistributorManagerService: string};
  globFilterSelectedSub: Subscription;
  globFilterSelectedData: any;

  constructor(
    private sharedService: SharedService,
    private chartSharedService:ChartSharedService,
    private exportChartService: ExportDistChartsService,
    private distDashboardService: DistDashbaordService,
    private globalFilter: RelationshipFilterService,
    private rdSharedEventService : RelationshipDashboardSharedEventService
  ) {
    this.exportconfiguration.createFields(this.exportChartService.getChartsToExport());
    /*  Events to update catagory-wise lists, based on shared service. */
    this.widgetLeftLocationSubscription = this.chartSharedService.widgetLeftLocationSubject.pipe(filter((x) => Array.isArray(x))).subscribe(widgetOrderlistSubject => {
      this.onViewUpdateActions(cloneDeep(widgetOrderlistSubject));
    });
    this.widgetCenterLocationSubscription = this.chartSharedService.widgetCenterLocationSubject.pipe(filter((x) => Array.isArray(x))).subscribe(widgetOrderlistSubject => {
      this.onViewUpdateActions(cloneDeep(widgetOrderlistSubject));
      this.setDefaultview(widgetOrderlistSubject);
      this.exportWidgetList = [];  
    });
    this.widgetDDLocationSubscription = this.chartSharedService.widgetDDLocationSubject.pipe(filter((x) => Array.isArray(x))).subscribe(widgetOrderlistSubject => {
      this.onViewUpdateActions(cloneDeep(widgetOrderlistSubject));
      this.exportWidgetList = [];
    });
    // this.globFilterSub = this.globalFilter.globalFilterBehaviousSubject.subscribe(val => {
    //   this.globalFilterData = val; 
    //   if(!this.globalFilterData.StartDate && !this.globalFilterData.EndDate && !this.globalFilterData.PeriodType) {
    //     let dataFilter = JSON.parse(sessionStorage.getItem("globalFilterObjectRelationship"));
    //     this.globalFilterData.StartDate = dataFilter.StartDate;
    //     this.globalFilterData.EndDate = dataFilter.EndDate;
    //     this.globalFilterData.PeriodType = dataFilter.PeriodType;
    //   }
    //   this.dropDownPopulation();
    //   this.clearCheckBoxSelection();
    // });

    this.globFilterSelectedSub = this.globalFilter.globalFilterSelectedFilterSubject.subscribe(val => {
      this.globFilterSelectedData = val;
      // this.seletedCategory = this.globFilterSelectedData.SubCategory;
    });

    this.sharedService.imgExtractStatusSubject.pipe(filter(update => update === IMAGE_EXTRACTION.READY || update === IMAGE_EXTRACTION.FAILED)).subscribe(update => {
      if (update === IMAGE_EXTRACTION.READY) {
        this.exportChartsToReports('ppt');
      } else if (update === IMAGE_EXTRACTION.FAILED) {
        // this.exportFailed();
        this.successMsgForDownload = true;
        this.callErrorMsg('error', '', 'An error occurred, please contact customerloyalty@atlascopco.com');
      }
    });
  }

  exportChartsToReports(exportFormat: string) {
    // let payload = ChartDataParsing.assembleExportPayload(this.globalFilterData['StartDate'], this.globalFilterData['EndDate'], exportFormat, this.selectedChartsForExport);
    let dataFilter = JSON.parse(sessionStorage.getItem("globalFilterObjectRelationship"));
    this.globalFilterData.StartDate = dataFilter.StartDate;
    this.globalFilterData.EndDate = dataFilter.EndDate;
    this.globalFilterData.PeriodType = dataFilter.PeriodType;
    let payload = this.exportChartService.assembleBlobUploadPayload(this.globFilterSelectedData, this.globalFilterData, exportFormat);
    let result = [];
    let context = this;
    this.loading = true;

    this.receivedCount = 0;
    this.results = [];
    // check if widget without image to export is being ExportSlideData, if yes this.assembleFinalExportPayload
    if (Object.keys(payload).length === 0 && payload.constructor === Object) {
      this.assembleFinalExportPayload([]);
    } else {
      for (const key in payload) {
        const element = payload[key];
        this.receivedCount = this.receivedCount + element.length;
      }

      for (const key in payload) {
        // if (payload.hasOwnProperty(key)) {
        const element = payload[key];
        this.exportChartService.saveImagesForExport(element).subscribe(i => {
          this.assembleFinalExportPayload(i['Response']);
        });
        // }
      }
    }


    
    // for (const key in payload) {
    //   const element = payload[key];
    //   this.receivedCount = this.receivedCount + element.length;
    // }

    // for (const key in payload) {
    //   // if (payload.hasOwnProperty(key)) {
    //   const element = payload[key];
    //   this.headerService.saveImagesForExport(element).subscribe(i => {
    //     this.assembleFinalExportPayload(i['Response']);
    //   });
    //   // }
    // }
    this.selectedChartsForExport = [];
    this.loading = false;
  }

  assembleFinalExportPayload(response: any[]) {
    this.results = [...this.results, ...response];
    if (this.receivedCount === this.results.length) {
      // send image blob links
      let payload = this.exportChartService.assembleFinalExportPayload(this.results);
      if (payload.length > 0) {
        // this.pptExportBlockUI = true;
        this.pptExport(payload);
      }
    }
  }

  private pptExport = (payload: any) => {
    this.exportChartService.createChartExports(payload).subscribe(res => {
      this.pptExportBlockUI = false;
      this.exportChartService.showLoadingWhileExportPPT(false);
      if (res['Status'] === 'Success' && res['Response'].length > 0) {
        this.downloadPptxFile(res['Response']);
      } else if (res['Status'] === 'Failure') {
        console.error('Failed PPTX export');
        this.successMsgForDownload = true;
        this.callErrorMsg('error', '', 'An error occurred, please contact customerloyalty@atlascopco.com');
      }
    });
  }

  private downloadPptxFile = (url: string) => {
    let a = document.createElement('A');
    a['href'] = url;
    a['download'] = url.substr(url.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  ngOnInit(): void {
    this.globFilterSub = this.globalFilter.globalFilterBehaviousSubject.subscribe(val => {
      this.globalFilterData = val; 
      if(!this.globalFilterData.StartDate && !this.globalFilterData.EndDate && !this.globalFilterData.PeriodType) {
        let dataFilter = JSON.parse(sessionStorage.getItem("globalFilterObjectRelationship"));
        this.globalFilterData.StartDate = dataFilter.StartDate;
        this.globalFilterData.EndDate = dataFilter.EndDate;
        this.globalFilterData.PeriodType = dataFilter.PeriodType;
      }
      this.dropDownPopulation();
      this.clearCheckBoxSelection();
    });
      // this.dropDownPopulation();
    }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.blockUi) {
      this.exportChartService.showLoadingWhileExportPPT(true);
    } else {
      this.exportChartService.showLoadingWhileExportPPT(false);
    }
  }
  ngOnDestroy(): void {
    this.globFilterSub.unsubscribe();
    this.widgetLeftLocationSubscription.unsubscribe();
    this.widgetNpsLocationSubscription.unsubscribe();
    this.widgetCenterLocationSubscription.unsubscribe();
    this.widgetDDLocationSubscription.unsubscribe();
  }

  dropDownPopulation() {
    this.downloadPanelMouseEnter()
    let loaded: ExportChartModel[] = this.exportChartService.getLoadedChartList(),
    toExport: string[] = this.exportChartService.getChartsToExport();
    this.readyCharts = [];
    if (Array.isArray(loaded) && loaded.length > 0) {
      for (let i = 0; i < loaded.length; i++) {
        this.readyCharts.push({
          label: loaded[i]['DisplayName']
          , value: loaded[i].ChartName
        });
      }
      console.log(this.readyCharts,'this.readyCharts')
      let _this =this;
      this.exportconfiguration.getPresentObjects().forEach(item => {
        if(_this.readyCharts.map(i => i.value.toLowerCase()).includes(item.widgetCode.toLowerCase()) && toExport.includes(item.widgetCode.toLowerCase())) {
          _this.exportconfiguration.getField(item.widgetCode.toLowerCase()).isDisabled = false;
        } else {
          _this.exportconfiguration.getField(item.widgetCode.toLowerCase()).isDisabled = true;
        }
      });
    }
  }
  castWidgetDetails(widgetList: any[]) {
    let widgetRep: any[] = widgetList.map(i => {
      let isDisabled: boolean = !i.IsVisible;
      // next 3 lines specific to nps by touchpoint
      // if(['l', 'p', 'r', 's', 'u'].includes(i.WidgetCode.toLowerCase())) {
      //   isDisabled = !this.sharedService.isNpstpOpen;
      //   this.isNPSExpandeble = this.sharedService.isNpstpOpen;
      // }

      // return a object
      return {
        isVisible: i.IsVisible,
        widgetCode: i.WidgetCode.toLowerCase(),
        isWidgetExportable: false,
        isApplicable: i.IsApplicable,
        isDisabled: isDisabled
      };
    })
    widgetRep.forEach(x => {
      this.exportconfiguration.createSetField(x.widgetCode.toLowerCase(), cloneDeep(x));
    });
  }
  private onViewUpdateActions = (widgetList: any[]) => {
    this.castWidgetDetails(widgetList);
    this.refreshCheckBoxSelection();
  }

  downloadPanelMouseEnter() {
    this.showDownloadPanel = true;
  }
  downloadPanelMouseLeave() {
    this.showDownloadPanel = false;
  }
  
  callErrorMsg(type, summary = '', detail = '') {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    window.scrollTo(0, 0);
    setTimeout(function () {
      document.getElementById('ClearMsg').click();
    }, this.MessageTimeOut);
  }
  checkboxCheck(event?,widget?,position?) {
    if(widget && !this.exportconfiguration.getField(widget).isDisabled && (!this[position] || this[position]==true)) {
      if(this.exportWidgetList.includes(widget) ) {
        let ind =this.exportWidgetList.indexOf(widget.toLowerCase());
        this.exportWidgetList.splice(ind,1)
      } else {
        if(widget === 'ls') {
          if(this.exportconfiguration.getField(widget).isVisible) {
            this.exportWidgetList.push(widget.toLowerCase())
          }
        } else {
         this.exportWidgetList.push(widget.toLowerCase())
        }  
      }
    }
    this.exportChartService.setExportList(this.exportWidgetList);
  }
  private refreshCheckBoxSelection = () => {
    const readyWidgets = this.exportconfiguration.getExporReadyWidgetNames();
    let removalIndices: number[] = [];
    for (let i = 0; i < this.exportWidgetList.length; i++) {
      let index: number = readyWidgets.indexOf(this.exportWidgetList[i].toLowerCase());
      if(index < 0) {
        removalIndices.push(i);
      }
    }
    let spliceOffset: number = 0;
    for (let i = 0; i < removalIndices.length; i++) {
      let item:number = -1;
      if(removalIndices[i] <= this.exportWidgetList.length-1) {
        item = removalIndices[i];
      } else {
        item = removalIndices[i] - spliceOffset;
      }
      this.exportWidgetList.splice(item, 1);
      spliceOffset++;
      this.exportWidgetList = this.exportWidgetList.map(i => i.toLowerCase());
    }
  }
  setDefaultview(widgetCenterOrderList: any[]) {

    this.isCenterChecked = widgetCenterOrderList.every(item => {
      return item.IsDefaultView == true;
    });
    this.isCenterChecked ? this.isDDChecked = false : this.isDDChecked = true;
  }
  clearCheckBoxSelection() {
    this.exportWidgetList = [];
  }
  searchRecordFromList(widgetCode, list = []) {
    if (list.length <= 0) {
      return { IsApplicable: false }
    } else {
      return list.filter(item => { return (item.WidgetCode == widgetCode) })[0];
    }
  }
  downloadPPT() {
    // do not take any action if nothing is selected. Disable download btn
    if(this.exportWidgetList.length <= 0 ) {
      return;
    }
    this.showDownloadPanel = false;

    // next X lines es sp specific
    if(this.exportWidgetList.includes('s')) {
      this.exportWidgetList.push('es');
      this.exportWidgetList.push('sp');
    }

    this.widgetToDownload.emit(JSON.parse(JSON.stringify(this.exportWidgetList)));
    this.exportWidgetList = [];
  }
  isExportWidgetListIncludes(widget) {
    if(this.exportWidgetList.includes(widget)) {
      return true;
    } else {
      return false;
    }
  }
}
