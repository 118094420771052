import { WidgetExportComponent } from './components/widget-export/widget-export.component';
import { DownloadExcelComponent } from './../Modules/Distributor_Survey/components/download-excel/download-excel.component';
//import library modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular'; //high chart modules
import HighchartsWordCloud from 'highcharts/modules/wordcloud';
import { PrimengModule } from 'src/app/primeng.module'; // prime ng modules
import { TooltipModule } from 'primeng/tooltip';
import { DragulaModule } from 'ng2-dragula'; //drag drop widgets
import { DragDropModule } from '@angular/cdk/drag-drop';
//import statement of components
import { ResponseRateDonutChartComponent } from '../components/dashboard/response-rate-donut-chart/response-rate-donut-chart.component';
import { NpsDashboardComponent } from '../components/dashboard/nps-dashboard/nps-dashboard.component';
import { NssDashboardComponent } from '../components/dashboard/nss-dashboard/nss-dashboard.component';
import { AverageRatingComponent } from '../components/dashboard/average-rating/average-rating.component';
import { InvitationsSentComponent } from '../components/dashboard/invitations-sent/invitations-sent.component';
import { ResponseRateComponent } from '../components/dashboard/response-rate/response-rate.component';
import { LoyaltyShiftComponent } from '../components/dashboard/loyalty-shift/loyalty-shift.component';
import { LoyaltyShiftChartComponent } from '../components/dashboard/loyalty-shift-chart/loyalty-shift-chart.component';
import { LoyaltyShiftGridComponent } from '../components/dashboard/loyalty-shift-grid/loyalty-shift-grid.component';
import { NpsByTouchpointComponent } from '../components/dashboard/nps-by-touchpoint/nps-by-touchpoint.component';
import { NpsByTouchPointWidgetComponent } from '../components/dashboard/nps-by-touch-point-widget/nps-by-touch-point-widget.component';
import { CustomerFeedbackComponent } from '../components/dashboard/customer-feedback/customer-feedback.component';
import { StoryBoardComponent } from '../components/dashboard/story-board/story-board.component';
import { Top5CustomersComponent } from '../components/dashboard/top5-customers/top5-customers.component';
import { InfluencerAnalysisComponent } from '../components/dashboard/influencer-analysis/influencer-analysis.component';
import { NpsResponselistComponent } from '../components/dashboard/nps-responselist/nps-responselist.component';
import { TotalResponseComponent } from '../components/dashboard/total-response/total-response.component';
import { TotalResponseChartComponent } from '../components/dashboard/total-response-chart/total-response-chart.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';

import { LostbusinessCustomerComponent } from '../components/dashboard/lostbusiness-customer/lostbusiness-customer.component';
import { LostbusinessLeftWidgetComponent } from '../components/dashboard/lostbusiness-left-widget/lostbusiness-left-widget.component';
import { LostBusinessFilterComponent } from '../components/dashboard/lost-business-filter/lost-business-filter.component';

// import { LBCLostcountDrilldownComponent } from '../components/dashboard/lbc-lostcount-drilldown/lbc-lostcount-drilldown.component';
//import { LbcTotalcountListDrilldownComponent } from '../components/dashboard/lbc-totalcount-list-drilldown/lbc-totalcount-list-drilldown.component';
import { BusyIndicatorComponent } from './components/busy-indicator/busy-indicator.component';
// import { FilterComponent } from './components/filter/filter.component';
import { GlobalFilterDistributorComponent } from 'src/app/shared/components/global-filter-distributor/global-filter-distributor';
import { ChartListingComponent } from 'src/app/components/dashboard/chartlisting.component/chartlisting.component';
import { StackBarChartComponent } from 'src/app/components/dashboard/chartlisting.component/stack-bar-chart/stack-bar-chart.component';
import { BubbleChartComponent } from '../components/dashboard/chartlisting.component/bubble-chart/bubble-chart.component';
import { GaugeChartComponent } from '../components/dashboard/chartlisting.component/gauge-chart/gauge-chart.component';
import { SpiderwebChartComponent } from '../components/dashboard/chartlisting.component/spiderweb-chart/spiderweb-chart.component';
import { WordcloudComponent } from '../components/dashboard/chartlisting.component/wordcloud/wordcloud.component';
import { ViewSelectorDistributorComponent } from '../Modules/Distributor_Survey/components/view-selector-distributor/view-selector-distributor.component';
//import pipes
import {NumberFormatPipe} from './pipe/number-format.pipe';
import {TimeAgoPipe } from './pipe/timeAgo.pipe'
import {HighlightCategoryReasonPipe} from './pipe/highlight-category-reason.pipe'
import { FilterDatePipe } from 'src/app/shared/components/filter/filter-Date.pipe';
import { DynamicLeftPositionPinnedFilter } from 'src/app/directives/dynamic.left.pos';
import { GetAssetsPathPipe } from './pipe/get-assets-path.pipe';
import { DynamicTitleDirective } from '../directives/dynamic-title';
import { DynamicDivTitleDirective } from '../directives/dynamic-div-title.directive';
import { CommonFilterComponent } from 'src/app/shared/components/common-filter/common-filter.component';
import { DynamicTooltipDirective } from '../dynamic-tooltip/dynamic.tooltip';

////// It is added for Relationship Survey Questionaree Setup //////
import { NpsComponent } from '../components/surveys/questions/nps/nps.component';
import { StarRangeComponent } from '../components/surveys/questions/star-range/star-range.component';
import { FreeFormComponent } from '../components/surveys/questions/free-form/free-form.component';
import { MatrixSingleSelectComponent } from '../components/surveys/questions/matrix-single-select/matrix-single-select.component';
import { RevenueFilterComponent } from './components/revenue-filter/revenue-filter.component';
import { ChartComponent } from '../components/dashboard/lostbusiness-customer/charts/chart/chart.component';
import { ChartHolderComponent } from '../components/dashboard/lostbusiness-customer/charts/chart-holder/chart-holder.component';
import { ClickOutsideDirective } from './directives/clickOutside.directive';
import { McqComponent } from '../components/surveys/questions/mcq/mcq.component';
import { MultiScaleComponent } from '../components/surveys/questions/multi-scale/multi-scale.component';
import { SingleScaleComponent } from '../components/surveys/questions/single-scale/single-scale.component';
import { RadioScaleComponent } from '../components/surveys/questions/radio-scale/radio-scale.component';
import { MultiChoiceComponent } from '../components/surveys/questions/multi-choice/multi-choice.component'; 

import { LostBusinessPopupComponent } from 'src/app/components/dashboard/lost-business-popup/lost-business-popup.component';
import { LbcTotalcntTransactionDrilldownComponent } from 'src/app/components/dashboard/lbc-totalcnt-transaction-drilldown/lbc-totalcnt-transaction-drilldown.component';
import { LbcTotalcountListDrilldownComponent } from 'src/app/components/dashboard/lbc-totalcount-list-drilldown/lbc-totalcount-list-drilldown.component';
import { LostbusiTotalcountDispositionlistComponent } from 'src/app/components/dashboard/lostbusi-totalcount-dispositionlist/lostbusi-totalcount-dispositionlist.component';
import { LbcLostDrilldownPopUpComponent } from 'src/app/components/dashboard/lbc-lost-drilldown-pop-up/lbc-lost-drilldown-pop-up.component';
import { LBCLostcountDrilldownComponent } from 'src/app/components/dashboard/lbc-lostcount-drilldown/lbc-lostcount-drilldown.component';
import { LbcLostcountCustdetailListComponent } from 'src/app/components/dashboard/lbc-lostcount-custdetail-list/lbc-lostcount-custdetail-list.component';
import { LbcWinCountCustDetailListComponent } from 'src/app/components/dashboard/lbc-win-count-cust-detail-list/lbc-win-count-cust-detail-list.component';
import { LbcWinCntDrilldownPopupComponent } from 'src/app/components/dashboard/lbc-win-cnt-drilldown-popup/lbc-win-cnt-drilldown-popup.component';
import { LbcWincountDrilldownComponent } from 'src/app/components/dashboard/lbc-wincount-drilldown/lbc-wincount-drilldown.component';
import { LbcTotalContactDrilldownComponent } from 'src/app/components/dashboard/lbc-total-contact-drilldown/lbc-total-contact-drilldown.component';
import { LbcLostCntContactDrilldownComponent } from 'src/app/components/dashboard/lbc-lost-cnt-contact-drilldown/lbc-lost-cnt-contact-drilldown.component';
import { LbcWinCntContactDrilldownComponent } from 'src/app/components/dashboard/lbc-win-cnt-contact-drilldown/lbc-win-cnt-contact-drilldown.component';
import { ChartDdHolderComponent } from '../components/dashboard/lostbusiness-customer/charts/chart-dd-holder/chart-dd-holder.component';
import { ResponseRateGridComponent } from 'src/app/components/dashboard/response-rate-grid/response-rate-grid.component';
import { FieldPipe } from './pipe/field.pipe';
import { FilterComponent } from './components/filter/filter.component';
import { CommaSeparatedNumberPipe } from './pipe/comma-separated-number.pipe';


import { PendingInfoSurveyComponent } from '../components/surveys/survey-library/pending-info-survey/pending-info-survey.component';
import { GenericGridComponent } from './generic-grid/generic-grid.component';
import { PptDownloadComponent } from '../Modules/Distributor_Survey/components/ppt-download/ppt-download.component';
const componentArray = [
  NpsDashboardComponent,
  NssDashboardComponent,
  ResponseRateDonutChartComponent,
  ResponseRateComponent,
  AverageRatingComponent,
  InvitationsSentComponent,
  LoyaltyShiftComponent,
  LoyaltyShiftChartComponent,
  LoyaltyShiftGridComponent,
  // NpsByTouchpointComponent,
  // NpsByTouchPointWidgetComponent,
  CustomerFeedbackComponent,
  StoryBoardComponent,
  Top5CustomersComponent,
  InfluencerAnalysisComponent,
  NpsResponselistComponent,
  TotalResponseComponent,
  TotalResponseChartComponent,
  ChartListingComponent,
  StackBarChartComponent,
  BubbleChartComponent,
  GaugeChartComponent,
  SpiderwebChartComponent,
  WordcloudComponent,
  ProgressBarComponent,
  BusyIndicatorComponent,
  NumberFormatPipe,
  TimeAgoPipe,
  HighlightCategoryReasonPipe,
  CommaSeparatedNumberPipe,
  GetAssetsPathPipe,
  // FilterComponent,
  GlobalFilterDistributorComponent,
  CommonFilterComponent,
  ViewSelectorDistributorComponent,
  FilterDatePipe,
  DynamicLeftPositionPinnedFilter,
  DynamicTitleDirective,
  DynamicDivTitleDirective,
  DynamicTooltipDirective,
  DownloadExcelComponent,
  WidgetExportComponent,
  LostbusinessCustomerComponent,
  /** Begin: Lost business Trend Charts */
  ChartComponent,
  ChartHolderComponent,
  ChartDdHolderComponent,
  /** End:   Lost business Trend Charts */
  LostbusinessLeftWidgetComponent,
  LostBusinessFilterComponent,
  ////// It is added for Relationship Survey Questionaree Setup //////
  NpsComponent,
  StarRangeComponent,
  FreeFormComponent,
  MatrixSingleSelectComponent,
    RevenueFilterComponent,
    /** Lost Business drilldown begin here */

    LostBusinessPopupComponent,
    LbcTotalcntTransactionDrilldownComponent,
    LbcTotalcountListDrilldownComponent,
    LostbusiTotalcountDispositionlistComponent,
    LbcLostDrilldownPopUpComponent,
    LbcLostcountCustdetailListComponent,
    LBCLostcountDrilldownComponent,
    LbcWinCountCustDetailListComponent,
    LbcWinCntDrilldownPopupComponent,
    LbcWincountDrilldownComponent,
    LbcTotalContactDrilldownComponent,
    LbcLostCntContactDrilldownComponent,
    LbcWinCntContactDrilldownComponent,
    ResponseRateGridComponent,
    /** Lost Business drilldown end here */
  //ComparisonViewSelectorComponent
  ClickOutsideDirective,
  McqComponent,
  MultiScaleComponent,
  SingleScaleComponent,
  RadioScaleComponent,
  MultiChoiceComponent, 
  FilterComponent,
  FieldPipe,
  PendingInfoSurveyComponent,
  GenericGridComponent,
  PptDownloadComponent
]

const exportModulesArray = [
  HighchartsChartModule,
  PrimengModule,
  DragulaModule,
  TooltipModule,
]

@NgModule({
  imports: [
    CommonModule,
    HighchartsChartModule,
    PrimengModule,
    TooltipModule,
    DragDropModule,
    DragulaModule.forRoot(),
  ],
  declarations: componentArray,
  exports: [...componentArray, ...exportModulesArray]
})
export class SharedModule { }
