import {
  Component, Input, OnInit, Output, EventEmitter,
  SimpleChange,
  SimpleChanges,
  ElementRef,
  ViewChild,
  Renderer2,
  HostListener
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { HeatMapDetailsPopupComponent } from '../heat-map-details-popup/heat-map-details-popup.component';
import { DynamicComponentService } from 'src/app/shared/services/dynamic-component.service';
import { CtlsDashboardCustomerService } from '../ctls-services';
import { Subject } from 'rxjs';
import { Dialog } from 'primeng/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'app-heat-map',
  templateUrl: './heat-map.component.html',
  styleUrls: ['./heat-map.component.scss']
})
export class HeatMapComponent implements OnInit {
  private imagepath = 'assets/images/';
  map: mapboxgl.Map | undefined;
  mapboxgl: any = {};
  style = 'mapbox://styles/mapbox/streets-v11';
  lat: number = 0;
  lng: number = 0;
  zoom: number = 0;
  highlightCountryData = [];
  globalAccountLocationList = [];
  stdAccountLocationList = [];
  companyAccountLocationList = [];
  dataObj: any = {};
  descPopup = null;
  destroy$ = new Subject();
  loader = false;
  header = null;
  popup = null;
  dialogData = '';
  onresize = Dialog.prototype.onResizeEnd;
  resizeIndex = 0;
  resizedDialog = false;
  globalPopup = false;
  lastZoom = 0;
  @Input() mapData: any;
  @Input('filterObj') filterObj;
  @Input('accountLocationList') accountLocationList;
  @Input('lstAccountLocationDetailsStatus') lstAccountLocationDetailsStatus;
  @Input('selAccountNo') selAccountNo;
  @Input('selAccountName') selAccountName;
  @ViewChild('mapContainer') mapContainer: ElementRef;
  @ViewChild('heatmapDialog') heatmapDialog: Dialog;
  @Output() heatMapChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public dynamicComponentService: DynamicComponentService,
    private ctlsDashboardCustomerService: CtlsDashboardCustomerService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.mapboxgl.accessToken = environment.mapbox.accessToken;
  (mapboxgl as mapboxgl).accessToken = environment.mapbox.accessToken;
  let mapObj = {
    container: 'map',
    style: this.style,
    center: [this.lng, this.lat],
    zoom: this.zoom,
    projection: 'mercator',
    show360Map: true,
    minZoom: -1
    // dragPan: false,
    /* maxZoom: -1,
    minZoom: -1, */
  };
  let _this = this;
  

  this.map = new mapboxgl.Map(mapObj);
  this.loader = true;
  this.loadMap(this.map);
  }

  loadMap(map) {
  let _this = this;
  
    map.on('load', () => {
      map.resize();
      _this.map.zoomTo(-1, {
        duration: 0,
        offset: [0, 0]
      });
      map.addSource('countries', {
        type: 'vector',
        url: 'mapbox://mapbox.country-boundaries-v1?optimize=true'
      });

      // Build a GL match expression that defines the color for every vector tile feature
      // Use the ISO 3166-1 alpha 3 code as the lookup key for the country shape
      // const matchExpression = ['match', ['get', 'iso_3166_1_alpha_3']];

      let obj: any = {};
      if (this.accountLocationList && this.lstAccountLocationDetailsStatus !== 'loading') {
        let featureCollection = [],
          highlightCountryData = [],
          matchExpression = [];
        _this.dataObj = { ..._this.filterMarkerFeatures(this.accountLocationList) };
        featureCollection = _this.dataObj.featureCollection;
        highlightCountryData = _this.dataObj.highlightCountryData;
        matchExpression = _this.dataObj.matchExpression;

        // Add an image to use as a custom marker
        map.loadImage(
          this.imagepath +'mapbox-marker-icon-20px-green.png',
          (error, image) => {
            if (error) throw error;
            map.addImage('custom-marker-green', image);
          }
        );

        // Add an image to use as a custom marker
        map.loadImage(
          this.imagepath +'mapbox-marker-icon-20px-red.png',
          (error, image) => {
            if (error) throw error;
            map.addImage('custom-marker-red', image);
          }
        );
        // Add an image to use as a custom marker
        map.loadImage(
          this.imagepath +'mapbox-marker-icon-20px-yellow.png',
          (error, image) => {
            if (error) throw error;
            map.addImage('custom-marker-yellow', image);
          }
        );
        // Add an image to use as a custom marker
        map.loadImage(
          this.imagepath +'mapbox-marker-icon-20px-gray.png',
          (error, image) => {
            if (error) throw error;
            map.addImage('custom-marker-gray', image);
            map.addSource('points-source', {
              'type': 'geojson',
              'data': {
                'type': 'FeatureCollection',
                'features': featureCollection
              }
            });

            // Add a symbol layer
            map.addLayer({
              'id': 'points',
              'type': 'symbol',
              'source': 'points-source',
              'layout': {
                'icon-image': [
                  'match',
                  ['get', 'PNDStatus'], // type corresponds to the field name you are keying off of
                  ['P'],
                  'custom-marker-green',
                  ['D'],
                  'custom-marker-red',
                  ['N'],
                  'custom-marker-yellow',
                  'custom-marker-gray' // fallback icon
                ],
                'symbol-sort-key': [
                  'match',
                  ['get', 'PNDStatus'], // type corresponds to the field name you are keying off of
                  ['P'],
                  2,
                  ['D'],
                  4,
                  ['N'],
                  3,
                  1
                ],
                'icon-size': 0.3,
                'icon-allow-overlap': true,
                "text-allow-overlap": true,
                // get the title name from the source's "title" property
                'text-field': ['get', 'title'],
                'text-font': [
                  'Open Sans Semibold',
                  'Arial Unicode MS Bold'
                ],
                'text-offset': [0, 1.25],
                'text-anchor': 'top'
              }
            },
              'admin-1-boundary-bg'
            );
          }
        );
        // Add layer from the vector tile source to create the choropleth
        // Insert it below the 'admin-1-boundary-bg' layer in the style
        map.addLayer(
          {
            'id': 'countries-layer',
            'type': 'fill',
            'source': 'countries',
            'source-layer': 'country_boundaries',
            'paint': {
              'fill-color': matchExpression,
              "fill-opacity": ['interpolate',
            // Set the exponential rate of change to 0.5
            ['exponential', 0.5],
            ['zoom'],
            2,
            1,
            22,
            0.025],
            },
            'filter': ["all"]
          },
          'admin-1-boundary-bg'
        );

        map.addLayer({
          "id": "cfh",  // country-fills-hover",
          "type": "fill",
          "source": "countries",
          "source-layer": "country_boundaries",
          "layout": {},
          "paint": {
            "fill-color": "#A9D18E",
            "fill-opacity": ['interpolate',
          // Set the exponential rate of change to 0.5
          ['exponential', 0.5],
          ['zoom'],
          2,
          1,
          22,
          0.025],
            "fill-outline-color": "#000000",
          },
          "filter": ["==", "name", ""]
        },
        'admin-1-boundary-bg'
        );
        map.setPaintProperty('water', 'fill-color', '#455768');
        setTimeout(() => {
          this.loader = false;
        }, 1000);
      }
    });
    _this.lastZoom = _this.map.getZoom();
    _this.map.on('zoom', (e) => {
      if (this.renderer && this.mapContainer.nativeElement && this.popupContent) {
        this.renderer.removeChild(this.mapContainer.nativeElement, this.popupContent);
        this.descPopup = false;
      }
      let currentZoom = _this.map.getZoom();
      if (((currentZoom === 0 && currentZoom < _this.lastZoom) || currentZoom === 0 && (_this.lastZoom <= 0))) {
        _this.map.zoomTo(-1, {
          duration: 0,
          offset: [0, 0]
        });
        _this.lastZoom = -1;
      } else if (!(currentZoom === 0 && _this.lastZoom < 0)) {
        _this.lastZoom = currentZoom;
      }
    });
    map.on('mousemove', 'countries-layer', function (e) {
      var features = map.queryRenderedFeatures(e.point, { layers: ['countries-layer'] });
      let hilightCountry = null;
      if (features.length > 0 && _this.dataObj.highlightCountryData) {
        _this.dataObj.highlightCountryData.forEach((item) => {
          if (item.code === features[0].properties.iso_3166_1_alpha_3) {
            hilightCountry = features[0].properties.name
          }
        });
        if (hilightCountry) {
          map.getCanvas().style.cursor = 'pointer';
          map.setFilter("cfh", ["==", "name", hilightCountry]);
        } else {
          map.setFilter("cfh", ["==", "name", ""]);
          map.getCanvas().style.cursor = '';
        }
      } else {
        map.setFilter("cfh", ["==", "name", ""]);
        map.getCanvas().style.cursor = '';
      }
    });

    map.on('mouseleave', 'countries-layer', function (e) {
      map.setFilter("cfh", ["==", "name", ""]);
      map.getCanvas().style.cursor = '';
    });

   
    map.setRenderWorldCopies(false);
    Dialog.prototype.onResizeEnd = function () {
      if (this.resizing) {
        _this.resizeIndex += 1;
        _this.resizedDialog = true;
        let fn = _this.onresize.bind(this);
        fn();
      }
  }
   /*  map.scrollZoom.disable();
    map.dragRotate.disable();
    map.touchZoomRotate.disableRotation(); */
  }

  ngOnChanges(changes: any) {
    if (changes.accountLocationList && changes.accountLocationList.currentValue && changes.accountLocationList.currentValue.length) {
      this.accountLocationList = changes.accountLocationList.currentValue;
      if (!changes.accountLocationList.firstChange) {
        this.map = null;
        
        (mapboxgl as mapboxgl).accessToken = environment.mapbox.accessToken;
        let mapObj = {
          container: 'map',
          style: this.style,
          center: [this.lng, this.lat],
          zoom: this.zoom,
          projection: 'mercator',
          show360Map: true,
          // dragPan: false,
          /* maxZoom: -1,
          minZoom: -1, */
        };
      
        this.map = new mapboxgl.Map(mapObj);
    
    
        this.loadMap(this.map);
      }
    }

  }
  popupContent : any;
  showPopup() {
    if (!this.descPopup) {
      if (this.accountLocationList && this.accountLocationList.length && this.accountLocationList[0].GlobalEntityId) {
        var coordinates = [0, 0];
        this.popupContent = this.dynamicComponentService.injectComponent(
          HeatMapDetailsPopupComponent,
          x => {
            x.entity = { ...this.accountLocationList[0], EntityId: null, StandardEntityId: null };
            x.filterObj = { ...this.filterObj, EntityId: null, StandardEntityId: null };
          });
        this.renderer.appendChild(this.mapContainer.nativeElement, this.popupContent);
        this.descPopup = true;
      }
    }
  }

  str(el) {
    if (!el) return "null";
    return el.className || el.tagName;
  }

  hidePopup(event) {
    let relatedTarget = this.str(event.relatedTarget);
    if (this.descPopup && relatedTarget && !(relatedTarget.includes('ui-tooltip-text') || relatedTarget.includes('ui-tooltip'))) {
      this.renderer.removeChild(this.mapContainer.nativeElement, this.popupContent);
      this.descPopup = false;
    }
  }

  displayHeatmapDialog: boolean;
  hideHeatmapDialog() {
    this.displayHeatmapDialog = false;
  }

  showHeatmapDialog() {
    this.header = (this.selAccountNo ? this.selAccountNo + ' - ': '') + this.selAccountName;
    this.resizedDialog = false;
    this.displayHeatmapDialog = true;
    this.heatMapChange.emit({
      openDetailsView: true
    });
  }
  
  onHeatMapViewChange(event) {
    this.globalPopup = false;
    this.renderer.removeClass(document.body, 'show-expand-map-view');
    if (event) {
      this.heatMapChange.emit({
        openDetailsView: !event.backTo360
      });
      if (event.backTo360) {
        this.hideHeatmapDialog();
      }
    }
  }

  filterMarkerFeatures(dataRes) {
    let featureCollection = [],
      highlightCountryData = [],
      processedEntities = [],
      matchExpression = ['match', ['get', 'iso_3166_1_alpha_3']];
    if (dataRes && dataRes.length) {
      dataRes.sort((a, b) => {
        if (a.PNDStatus === null) {
          return 1;
        }

        if (b.PNDStatus === null) {
          return -1;
        }

        if (a.PNDStatus === b.PNDStatus) {
          return 0;
        }

        return a.PNDStatus < b.PNDStatus ? -1 : 1;
      });
      for (let dat of dataRes) {
        if (dat.CountryCode) {
          if (!!dat.StandardEntityId && highlightCountryData.findIndex(x => x.CountryCode.toUpperCase() === dat.CountryCode.toUpperCase()) < 0) {
            let row = { code: this.ctlsDashboardCustomerService.getIso3DigitCountryCode(dat.CountryCode), ...dat, EntityId: null };
            const color = `#C5E0B4`;
            if (row['code'] && matchExpression.indexOf(row['code']) < 0) {
              matchExpression.push(row['code'], color);
            }
            highlightCountryData.push(row);
          }
          if (dat.Latitude && dat.Longitude && !processedEntities.includes(dat.EntityId)) {          
            let jsonObj = {
              'type': 'Feature',
              "properties": {
                'pointTitle': dat.Name,
                ...dat,
                'title': null
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [dat.Longitude, dat.Latitude]
              }
            };
            featureCollection.push(jsonObj);
            processedEntities.push(dat.EntityId);
          }
        }
      }
    }
    if (!(highlightCountryData && highlightCountryData.length)) {
      matchExpression.push('IND', '#FFFFFF')
    }
    matchExpression.push('#FFFFFF');
    return {
      featureCollection,
      matchExpression,
      highlightCountryData
    };
  }

  @HostListener('document:click', ['$event']) 
  documentClickEvent(evt: any) {
    const mapContainer = document.getElementById('map-container');
    const mapDetailsContainer = document.getElementsByClassName('country-popup');
    const footerContents = document.getElementsByClassName('footer-content');

    let targetEl = evt.target; // clicked element 
    do {
      if (mapContainer == targetEl ||
        mapDetailsContainer && mapDetailsContainer.length && targetEl == mapDetailsContainer[0] ||
        footerContents && footerContents.length && targetEl.className == footerContents[0].className) {
        return;
      }
      // Go up the DOM
      targetEl = targetEl.parentNode;
    } while (targetEl);

    if (this.renderer && this.mapContainer.nativeElement && this.popupContent) {
      this.renderer.removeChild(this.mapContainer.nativeElement, this.popupContent);
      this.descPopup = false;
    }
    
  }
  expandMapview(){
    this.showHeatmapDialog();
    this.renderer.addClass(document.body, 'show-expand-map-view');
  }
  resizemapContainer(){
    setTimeout(function () {
      this.dialogData = 'resize-dialog-box';
    }, 100); 
  }
  expandpopoverview(){
    this.globalPopup = true;
    this.showPopup();
  }
}