import { Injectable } from '@angular/core';
import { ExportChartModel, SlideTextData, ExportOrchestrationData, ExportSlideData } from '../models/export-chart.model';

// import * as data from '../json-config/export-to-pptx/dash-export.config';

@Injectable()
export class ExportChartDataService {
    // master list of available, exportable widgets
    readonly chartsToExport: string[] = ['npst', 'lsdd', 'rrt', 'ssrr', 'cc', 'rr', 'l', 'p', 'r', 's', 'es', 'sp', 'u', 'err', 'nps', 'ar', 'top5', 'sb', 'ia', 'sis', 'npstp','rsc','nss','nnt','sc','swc','poj','w','f','ru'];
    // global filter master list
    readonly filterExportValues: any[] = [
        {
            value: 'baCount',
            label: 'Business Area'
        }
        , {
            value: 'divCount',
            label: 'Business Division'
        }
        , {
            value: 'famCount',
            label: 'FAM'
        }
        , {
            value: 'blCount',
            label: 'Business Line'
        }
        , {
            value: 'countryCount',
            label: 'Country'
        }
        , {
            value: 'brandCount',
            label: 'Brand'
        }
        , {
            value: 'zoneCount',
            label: 'Zone'
        }
        , {
            value: 'branchCount',
            label: 'Branch'
        }
        , {
            value: 'salesCount',
            label: 'Sales Engineer'
        }
        , {
            value: 'serviceCount',
            label: 'Service Technician'
        }
        , {
            value: 'touchpointCount',
            label: 'Touchpoint'
        }
        , {
            value: 'influencerCount',
            label: 'Influencer'
        }
        , {
            value: 'continentCount',
            label: 'Continent'
        }
        , {
            value: 'regionCount',
            label: 'Region'
        }
    ];

    // if text to be replaced by ellipsis in PPTX global filter table cell
    readonly filterValEllipsisThreshold: number = 33;
    readonly filterValEllipsisCheckEnabled: boolean = true;

    // to save widget snips, send base64 of image in groups
    readonly chartExportGroup = {
        D: 'npst|rrt|ssrr|lsdd|cc',
        B: 'nps|rr|l|p|r|s|u|ar|err|sis',
        C: 'top5|sb|ia'
    };

    // declaration and configuration of slides & it's permissable widget snippet images
    readonly slideConfig = [
        {
            slideId: 'filter',
            chartImgIds: [],
            slideTitle: 'Date range & filter details',
            hasTextContent: true,
            position: 0
        },
        {
            slideId: 'npsnresponse',
            chartImgIds: ['nps', 'ar', 'sis', 'rr'],
            slideTitle: 'Net Promoter Score',
            hasTextContent: false,
            position: 1
        },
        {
            slideId: 'npstp',
            chartImgIds: [],
            // chartImgIds: ['l', 'p', 'r', 's', 'u'],
            // slideTitle: 'NPS by Touchpoint',
            slideTitle: 'Net Promoter Score – by touchpoints',
            hasTextContent: false,
            position: 2
        },
        {
            slideId: 'ia',
            chartImgIds: ['ia'],
            slideTitle: 'Influencer Analysis',
            hasTextContent: false,
            position: 3
        },
        {
            slideId: 'lsdd',
            chartImgIds: ['lsdd'],
            slideTitle: 'Loyalty Shift',
            hasTextContent: false,
            position: 4
        },
        {
            slideId: 'top5',
            chartImgIds: ['top5'],
            slideTitle: 'Top 5 Customers',
            hasTextContent: false,
            position: 5
        },
        {
            slideId: 'sb',
            chartImgIds: [],
            slideTitle: 'Convenience to do business',
            hasTextContent: true,
            position: 6
        },
        // {
        //     slideId: 'sbctdb',
        //     chartImgIds: ['sb', 'sbctdb0', 'sbctdb1', 'sbctdb2', 'sbctdb3'],
        //     slideTitle: 'Story Board (Convenience to do business)',
        //     hasTextContent: false
        // },
        // {
        //     slideId: 'sbc',
        //     chartImgIds: ['sb', 'sbc0', 'sbc1', 'sbc2', 'sbc3'],
        //     slideTitle: 'Story Board (Communication)',
        //     hasTextContent: false
        // },
        // {
        //     slideId: 'sblt',
        //     chartImgIds: ['sb', 'sblt0', 'sblt1', 'sblt2', 'sblt3'],
        //     slideTitle: 'Story Board (Lead Time)',
        //     hasTextContent: false
        // },
        // {
        //     slideId: 'sbqss',
        //     chartImgIds: ['sb', 'sbqss0', 'sbqss1', 'sbqss2', 'sbqss3'],
        //     slideTitle: 'Story Board (Quality of Services & Solutions)',
        //     hasTextContent: false
        // },
        {
            slideId: 'npst',
            chartImgIds: ['npst'],
            slideTitle: 'NPS Trend',
            hasTextContent: false,
            position: 7
        },
        {
            slideId: 'rrt',
            chartImgIds: ['rrt'],
            slideTitle: 'Response Rate Trend',
            hasTextContent: false,
            position: 8
        },
        {
            slideId: 'ssrr',
            chartImgIds: ['ssrr'],
            slideTitle: 'Survey Sent vs. Responses Received Trend',
            hasTextContent: false,
            position: 9
        }
        ,
        {
            slideId: 'cc',
            chartImgIds: ['cc'],
            slideTitle: 'Country Comparison',
            hasTextContent: false,
            position: 10
        },
        {
            slideId: 'rsc',
            chartImgIds: ['rsc'],
            slideTitle: 'Country Comparison',
            hasTextContent: false,
            position: 10
        },
        {
            slideId: 'nss',
            chartImgIds: ['nss'],
            slideTitle: 'Revenue wise Sentiment Comparison',
            hasTextContent: false,
            position: 10
        },
        {
            slideId: 'nnt',
            chartImgIds: ['nnt'],
            slideTitle: 'NPS NSS Trend',
            hasTextContent: false,
            position: 10
        },
        {
            slideId: 'sc',
            chartImgIds: ['sc'],
            slideTitle: 'Sentiment Categories',
            hasTextContent: false,
            position: 10
        },
        {
            slideId: 'swc',
            chartImgIds: ['swc'],
            slideTitle: 'Word Cloud',
            hasTextContent: false,
            position: 10
        },
        // {
        //     slideId: 'poj',
        //     chartImgIds: ['poj'],
        //     slideTitle: 'Project',
        //     hasTextContent: false,
        //     position: 10
        // },
        // {
        //     slideId: 'w',
        //     chartImgIds: ['w'],
        //     slideTitle: 'Workshop Service',
        //     hasTextContent: false,
        //     position: 10
        // },
        // {
        //     slideId: 'f',
        //     chartImgIds: ['f'],
        //     slideTitle: 'Workshop Service',
        //     hasTextContent: false,
        //     position: 10
        // }
    ];
    chartNamesMapping: {};
    chartsCurrentlyVisible: any[];
    selectedExportList: string[];
    exportParamData: ExportOrchestrationData;
    slideListConfig: ExportSlideData[];

    constructor() {
        this.chartNamesMapping = {};
        this.chartsCurrentlyVisible = [];
        this.selectedExportList = [];
        this.exportParamData = new ExportOrchestrationData();
        this.slideListConfig = [];
    }
}

