import { widgetCodes } from './../../../shared/constants/shared.constants';
import { RelationshipDashboardSharedEventService } from './../../../shared/services/relationship-dashboard-shared-event.service';
import { Component, OnChanges, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { surveyType } from 'src/app/shared/constants/shared.constants';
import * as _html2canvas from "html2canvas";
const html2canvas:any = _html2canvas;
import { HideOtherOpenDrilldownsService } from '../../../Modules/Distributor_Survey/Services/hide-other-open-drilldowns.service';
import { ChartDataService } from '../service/chart-data-service.service';
import { environment } from 'src/environments/environment';
import { ExportChartModel } from 'src/app/shared/models/export-chart.model';
import { WIDGET_CODES } from 'src/app/shared/enums/export-state-enum';
import { CHART_STATE_OPTIONS } from 'src/app/shared/enums/chart-state-options.enum';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
const WIDGET_CODE ='ls';
@Component({
  selector: 'app-loyalty-shift',
  templateUrl: './loyalty-shift.component.html',
  styleUrls: ['./loyalty-shift.component.scss']
})
export class LoyaltyShiftComponent implements OnInit, OnChanges {
  private imagepath = environment.imagepath;
  private chartState: CHART_STATE_OPTIONS;
  private exportSubscription: Subscription;
  @Input() response: any;
  @Input() lsHeight: any;
  widgetCodesConst:any;
  loyltyShift = null;
  loyaltyShiftWidth: boolean = false;
  @Output() showChartEvent = new EventEmitter<boolean>();
  @Input('boolLoyaltyShiftLoader') boolLoyaltyShiftLoader;
  @Input() errorData: boolean;
  overlayDisplay : string = "none";
  @Input() surveyTypeInput: string = surveyType.Transactional;
  @Input() bookmarkBaseUrl:string = "/dashboard/home"
  constructor(private sharedService: SharedService,private rdSharedEventService:RelationshipDashboardSharedEventService, private hideOtherOpenDrilldownsService : HideOtherOpenDrilldownsService,
    private chartDataService: ChartDataService ) { 
    this.widgetCodesConst = widgetCodes;

    this.exportSubscription = this.sharedService.chartExportSubject.pipe(filter(val => val !== null && val.includes(WIDGET_CODE.toLowerCase()))).subscribe(val => {
      // this.slideTextToShow();
      setTimeout(() => {
        this.toCanvas();
      }, 200);  
    });
  }
  dragDropHover: boolean = true;

  ngOnChanges() {
    this.getLoyaltyShift();
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.exportSubscription.unsubscribe();
  }

   toCanvas() {
    let chartUpdate: ExportChartModel = new ExportChartModel();
    try {
      if (this.pictureOf && this.pictureOf.nativeElement) {
        html2canvas(this.pictureOf.nativeElement, { scrollX:0, scrollY:-window.scrollY, allowTaint: false, useCORS: false, backgroundColor: null, logging: false, removeContainer: true }).then(canvas => {
          chartUpdate.UpdateTime = new Date();
          chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
          chartUpdate.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
          chartUpdate.CurrentState = CHART_STATE_OPTIONS.SUCCESS;
          chartUpdate.ChartImgBase64 = canvas.toDataURL('image/png');
          this.sharedService.updateChartStatusSubject(chartUpdate);
        },
          err => {
            console.log('error occurred during image export, details as below \n', err);
            chartUpdate = this.exportErrorUpdate();
            this.sharedService.updateChartStatusSubject(chartUpdate);
          });
      }
      else if (this.response.length == 0 ) {
        chartUpdate.UpdateTime = new Date();
        chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
        chartUpdate.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
        chartUpdate.CurrentState = CHART_STATE_OPTIONS.NO_DATA;
        chartUpdate.ChartImgBase64 = 'no_image_data_available_for_this_state';
        chartUpdate.HasNoData = true;
        this.sharedService.updateChartStatusSubject(chartUpdate);
      }
    } catch (error) {
      console.log('html2canvas error as', error);
      chartUpdate = this.exportErrorUpdate();
      this.sharedService.updateChartStatusSubject(chartUpdate);
    }
  }

  exportErrorUpdate = (): ExportChartModel => {
    let err: ExportChartModel = new ExportChartModel();
    err.HasErrorInExport = true;
    err.UpdateTime = new Date();
    err.ChartName = WIDGET_CODE.toLowerCase();
    err.ChartImgId = WIDGET_CODE.toLowerCase() + '_' + (new Date().toDateString().split(' ').join('_') + '_' + new Date().toLocaleTimeString().split(' ').join('_') + '.png').split(':').join('_');
    err.CurrentState = CHART_STATE_OPTIONS.ERROR;
    return err;
  }

  getLoyaltyShift() {

    if (this.response != null && this.response.Response != null) {
      var maxCount = 0;
      this.loyltyShift = null;

      this.response.Response.PromoterLoyaltyShiftModel.forEach(element => {
        if (maxCount < element.LoyaltyShiftVal) {
          maxCount = element.LoyaltyShiftVal;
          this.loyltyShift = element;
        }
      });
      this.response.Response.NeutralLoyaltyShiftModel.forEach(element => {
        if (maxCount < element.LoyaltyShiftVal) {
          maxCount = element.LoyaltyShiftVal;
          this.loyltyShift = element;
        }
      });
      this.response.Response.DetractorLoyaltyShiftModel.forEach(element => {
        if (maxCount < element.LoyaltyShiftVal) {
          maxCount = element.LoyaltyShiftVal;
          this.loyltyShift = element;
        }
      });
      if (this.loyltyShift != null && this.loyltyShift.hasOwnProperty('LoyaltyShiftVal')) {
        if (this.loyltyShift.LoyaltyShiftVal > 99) {
          this.loyaltyShiftWidth = true;
        }
        else{
          this.loyaltyShiftWidth = false;
        }
      }

    }
    this.updateExportDetails();
  }

  private updateExportDetails = (isDestroyCall: boolean = false) => {
    this.determineWidgetState();
    let chartUpdate: ExportChartModel = new ExportChartModel();
    chartUpdate.UpdateTime = new Date();
    chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
    if (isDestroyCall) {
      this.chartState = CHART_STATE_OPTIONS.UNKNOWN;
    }
    chartUpdate.CurrentState = this.chartState;// ? this.chartState.toLowerCase() : CHART_STATE_OPTIONS.LOADING;
    this.sharedService.updateChartStatusSubject(chartUpdate);
  }

  private determineWidgetState = () => {
    if (this.chartState == null && !this.errorData) {
      this.chartState = CHART_STATE_OPTIONS.NO_DATA;
    }
    if ((this.response == null || this.response.length === 0) && this.errorData) {
      this.chartState = CHART_STATE_OPTIONS.ERROR;
    }
    this.chartState = this.boolLoyaltyShiftLoader ? CHART_STATE_OPTIONS.LOADING : CHART_STATE_OPTIONS.SUCCESS;
  }
  


  onClickLoyaltyShiftChart() {
    this.hideOtherOpenDrilldownsService.drillDownClickEvent('')
    this.showChartEvent.emit(true);
  }
  showOverlay(){
    this.overlayDisplay = "block";
    this.dragDropHover = false;
    this.setHeightLoyaltyShift();
  }
  hideOverlay(){
    this.overlayDisplay = "none";
    this.dragDropHover = true;
  }

  closeWidget() {
    if (this.surveyTypeInput != null && (this.surveyTypeInput == surveyType.Distributor || this.surveyTypeInput == 'OEM')) {
      this.rdSharedEventService.closeWidget(this.widgetCodesConst.LoyaltyShift)
    } else {
      this.sharedService.widgetCloseSubject.next(this.widgetCodesConst.LoyaltyShift);
    }
  }

  setHeightLoyaltyShift(){
    //dynamic heigh loyalty shift widget
    var divHeight = <HTMLElement>document.getElementById("lsSce");
    this.lsHeight = 270;
    if(divHeight !=null){
      this.lsHeight = divHeight.clientHeight
    }
  }
  @ViewChild('pictureOf') pictureOf: ElementRef;
  click() {
    if(this.pictureOf && this.pictureOf.nativeElement) {
      // let e = document.getElementById('pictureOf');
      // html2canvas(e, { foreignObjectRendering: true, useCORS: true, backgroundColor: null, logging: false, removeContainer: true }).then(canvas => {
      html2canvas(this.pictureOf.nativeElement, { scrollX:0, scrollY:-window.scrollY, allowTaint: true, useCORS: false, backgroundColor: null, logging: false, removeContainer: true }).then(canvas => {
        document.body.appendChild(canvas);
        var img = canvas.toDataURL('image/png');
        const link = document.createElement("a");
        link.href = 'data:' + img;
        link.download = "test.png"
        link.click();
        document.body.removeChild(canvas);
      });
    }
  }
}
