import { Component, OnInit, Input, OnChanges, ViewChild, Output, EventEmitter, SimpleChanges, ElementRef, HostListener } from '@angular/core';
import { FilterService } from '../../../shared/services/filter.service';
import { Moment } from '../../../../../node_modules/moment';
import * as moment from 'moment/moment.js';
declare var $: any;
//import 'bootstrap';
import { DashboardWidgetService } from '../service/dashboard-widget.service';
// import {FilterService } from
import { SharedService } from 'src/app/shared/services/shared.service';
import { CHART_STATE_OPTIONS } from 'src/app/shared/enums/chart-state-options.enum';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as html2canvas from "html2canvas"
import { ExportChartModel, SlideTextData } from 'src/app/shared/models/export-chart.model';
import { ChartDataService } from '../service/chart-data-service.service';
import { environment } from 'src/environments/environment';
const WIDGET_CODE: string = 'sb';
const WIDGET_CODE_CTDB: string = 'sb_ctdb';
const WIDGET_CODE_COMM: string = 'sb_comm';
const WIDGET_CODE_LEADTIME: string = 'sb_lt';
const WIDGET_CODE_QOSS: string = 'sb_qoss';

@Component({
  selector: 'app-story-board',
  templateUrl: './story-board.component.html',
  styleUrls: ['./story-board.component.scss']
})
export class StoryBoardComponent implements OnInit, OnChanges {
  private imagepath = environment.imagepath;
  convenient: any;
  time: any;
  quality: any;
  communication: any;
  convenientOptionSelected = [];
  timeOptionSelected = [];
  qualityOptionSelected = [];
  commOptionSelected = [];
  responseData = [];
  storyByNumber: boolean = false;
  showMaxTouchpoint: boolean = false;
  showMaxTotalResponse: boolean = false;

  @Input('storyBoardData') storyBoardData: any = null;
  @Input('storyBoardDetailData') storyBoardDetailData: any = null;
  @Input('storyBoardTimeDetailData') storyBoardTimeDetailData: any = null;
  @Input('storyBoardQualityDetailData') storyBoardQualityDetailData: any = null;
  @Input('storyBoardCommunicationDetailData') storyBoardCommunicationDetailData: any = null;
  @Input('maxResponseTouchpoint') maxResponseTouchpoint: any = null;
  maxResponseTouchpointName;
  @Input('maxResponseTouchpointImage') maxResponseTouchpointImage: any = null;
  maxResponseTouchpointImageUrl;

  @Input() npsOnlyResponseCount: any = null;
  @Input() satisfactionCount: any = null;
  @Input() totalResponseCount: any = null;
  @Input() npsResponsePercentage: any = null;
  @Input() companyName: any;
  @Input('boolStoryBoardLoader') boolStoryBoardLoader;
  @ViewChild('convenientcarousel') convenientCarousel;
  @ViewChild('timecarousel') timeCarousel;
  @ViewChild('communicationcarousel') communicationCarousel;
  @ViewChild('qualitycarousel') qualityCarousel;
  fromDateObj;
  toDateObj;
  fromDay;
  toDay;
  lastDay;
  noOfDays;
  noOfYears;
  noOfYearFraction;
  detailResponseTotal;
  optionData;
  optionPercentage;
  responseTimeData;
  detailTimeResponseTotal;
  optionTimeData;
  optionTimePercentage;
  responseQualityData;
  detailQualityResponseTotal;
  optionDetailData;
  optionQualityPercentage;
  responseCommData;
  detailCommResponseTotal;
  optionCommData;
  optionCommPercentage;
  responseQstn;
  responseQstnTime;
  responseQstnComm;
  responseQstnQuality;
  selectedDurationText: any = null;
  isPeriodSelected: boolean = false;
  mainStoryInterval: any = '2000';
  timeStoryInterval: any = '2000';
  qualityStoryInterval: any = '2000';
  commStoryInterval: any = '2000';
  showVerticalDiv: boolean = false;
  showVerticalDivClass: any = '';
  satisfactionPosn: any = { 'color': '#005671' };
  percent: number = 50;
  npsWidth: any = 0;
  timeStoryIDTimer: any;
  qualityStoryIDTimer: any;
  commStoryIDTimer: any;
  @Input() userFilterData;
  @Input() errorData: boolean;
  applySmallClassForCategory: boolean = false;

  @Input('npstouchpointData') tp;

  private widgetState: CHART_STATE_OPTIONS;
  private exportSubscription: Subscription;
  @ViewChild('pictureOf') pictureOf: ElementRef;
  dashboardImageUrl = this.sharedService.configuration.DashboardImagePath;

  constructor(private filterService: FilterService, private dashboardWidgetService: DashboardWidgetService, private sharedService: SharedService,
    private chartDataService: ChartDataService) {
    //constructor(private filterService: FilterService, private dashboardWidgetService: DashboardWidgetService) {
    //  this.filterService.getUserFilter().then(res => {
    //   if (res != null && res.Status == "Success" && res.Response != null) {
    //     this.userFilterData = res.Response;

    //   }
    // }).catch(error => {

    // });
    this.exportSubscription = this.sharedService.chartExportSubject.pipe(filter(val => val !== null && val.includes(WIDGET_CODE.toLowerCase()))).subscribe(val => {
      this.slideTextToShow();
      setTimeout(() => {
        this.toCanvas();
      }, 200);
    });
  }
  ngOnInit() {

  }

  ngAfterViewInit() {
    //   var ele = this.divStory;
    //  if(ele && ele!=null)
    //   ele.nativeElement.className = ele.nativeElement.innerText.length > 5 ? 'npspercentagenumber_small': 'npspercentagenumber'
    /*$('#convenientStoryID').carousel({
      interval: 2000,
      cycle: true
    });*/

  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (this.npsOnlyResponseCount !== null && this.satisfactionCount !== null && this.totalResponseCount !== null) {
        this.percent = (this.npsOnlyResponseCount / (this.totalResponseCount <= 0 ? 1 : this.totalResponseCount)) * 100;
        if (this.percent >= 90) {
          this.satisfactionPosn = { 'color': '#005671', 'top': '-15px' };
        } else {
          this.satisfactionPosn = { 'color': '#005671' };
        }
      }
    } catch (error) {
      this.satisfactionPosn = { 'color': '#005671', 'top': '-15px' };
      console.log('An error occurred, details as below \n', error);
    }
    this.showMaxTouchpoint = false;
    this.showMaxTotalResponse = false;
    if (this.maxResponseTouchpoint != null)
      this.showMaxTouchpoint = true;

    if (this.npsResponsePercentage != null && this.npsOnlyResponseCount != null && this.satisfactionCount != null) {
      this.showMaxTotalResponse = true;
    }

    if (changes.boolStoryBoardLoader && !changes.boolStoryBoardLoader.firstChange)
      if (changes.boolStoryBoardLoader.currentValue === false) {
        this.configureCarousels();
      }
    this.removeStoryPercentage();
    this.getStoryBoard();
    this.maxResponseTouchpointName = this.maxResponseTouchpoint;

    this.maxResponseTouchpointImageUrl = this.maxResponseTouchpointImage;
    if (this.filterService.filterObj.StartDate != "" && this.filterService.filterObj.EndDate != "") {
      this.fromDateObj = new Date(this.changeDateFormat(this.filterService.filterObj.StartDate));
      this.toDateObj = new Date(this.changeDateFormat(this.filterService.filterObj.EndDate));

    }
    else if (this.userFilterData != null) {
      this.fromDateObj = new Date(this.userFilterData.StartDate);
      this.toDateObj = new Date(this.userFilterData.EndDate);

    }
    if ((this.userFilterData != null) || (this.filterService.filterObj.StartDate != "" && this.filterService.filterObj.EndDate != "")) {

      this.fromDay = (typeof (this.fromDateObj) != 'undefined') ? this.fromDateObj.getDate() : '';
      this.toDay = (typeof (this.toDateObj) != 'undefined') ? this.toDateObj.getDate() : '';
      this.lastDay = new Date(this.toDateObj.getFullYear(), this.toDateObj.getMonth() + 1, 0).getDate();

      var a = moment(this.fromDateObj);
      var b = moment(this.toDateObj);
      this.noOfDays = b.diff(a, 'days') + 1;
      // If period filter is selected
      if (this.filterService.filterObj.PeriodType && this.filterService.filterObj.PeriodType != "") {
        this.isPeriodSelected = true;
        if (this.filterService.filterObj.PeriodType == "M") {
          this.selectedDurationText = "current month";
        } else if (this.filterService.filterObj.PeriodType == "Q") {
          this.selectedDurationText = "current quarter";
        } else if (this.filterService.filterObj.PeriodType == "H") {
          this.selectedDurationText = "current half-year";
        } else if (this.filterService.filterObj.PeriodType == "Y") {
          this.selectedDurationText = "current year";
        }
      }
      else if (this.filterService.filterObj.PeriodType = "" && this.userFilterData.PeriodType != "") {
        this.isPeriodSelected = true;
        if (this.userFilterData.PeriodType == "M") {
          this.selectedDurationText = "current month";
        } else if (this.userFilterData.PeriodType == "Q") {
          this.selectedDurationText = "current quarter";
        } else if (this.userFilterData.PeriodType == "H") {
          this.selectedDurationText = "current half-year";
        } else if (this.userFilterData.PeriodType == "Y") {
          this.selectedDurationText = "current year";
        }

      }
      //if exact start date and exact end date of a month
      else if (this.fromDay == 1 && this.toDay == this.lastDay) {
        this.isPeriodSelected = false;
        this.noOfYears = Math.floor(this.noOfDays / 365);

        this.noOfYearFraction = Math.floor((this.noOfDays % 366) / 30);

        if (this.noOfYears < 1) {
          switch (Math.round(this.noOfDays / 30)) {
            case 1:
              this.selectedDurationText = "1 month";
              break;
            case 2:
              this.selectedDurationText = "2 months";
              break;
            case 3:
              this.selectedDurationText = "3 months";
              break;
            case 4:
              this.selectedDurationText = "4 months";
              break;
            case 5:
              this.selectedDurationText = "5 months";
              break;
            case 6:
              this.selectedDurationText = "6 months";
              break;
            case 7:
              this.selectedDurationText = "7 months";
              break;
            case 8:
              this.selectedDurationText = "8 months";
              break;
            case 9:
              this.selectedDurationText = "9 months";
              break;
            case 10:
              this.selectedDurationText = "10 months";
              break;
            case 11:
              this.selectedDurationText = "11 months";
              break;
          }
        } else if (this.noOfYears >= 1 && this.noOfYearFraction != 0) {
          switch (this.noOfYearFraction) {
            // case 0:
            //   this.selectedDurationText = this.noOfYears + " year";
            //   debugger;
            //   break;
            case 1:
              this.selectedDurationText = this.noOfYears + "." + "1 years";
              break;
            case 2:
              this.selectedDurationText = this.noOfYears + "." + "2 years";
              break;
            case 3:
              this.selectedDurationText = this.noOfYears + "." + "3 years";
              break;
            case 4:
              this.selectedDurationText = this.noOfYears + "." + "4 years";
              break;
            case 5:
              this.selectedDurationText = this.noOfYears + "." + "5 years";
              break;
            case 6:
              this.selectedDurationText = this.noOfYears + "." + "6 years";
              break;
            case 7:
              this.selectedDurationText = this.noOfYears + "." + "7 years";
              break;
            case 8:
              this.selectedDurationText = this.noOfYears + "." + "8 years";
              break;
            case 9:
              this.selectedDurationText = this.noOfYears + "." + "9 years";
              break;
            case 10:
              this.selectedDurationText = this.noOfYears + "." + "10 years";
              break;
            case 11:
              this.selectedDurationText = this.noOfYears + "." + "11 years";
              break;
            case 12:
              if (this.noOfYears == 1) {
                this.selectedDurationText = this.noOfYears + " year";
                break;
              } else {
                this.selectedDurationText = this.noOfYears + " years";
                break;
              }
          }
        } else if (this.noOfYears >= 1 && this.noOfYearFraction == 0) {
          if (this.noOfYears == 1) {
            this.selectedDurationText = this.noOfYears + " year";
          } else {
            this.selectedDurationText = this.noOfYears + " years";
          }
        }
      } else {
        if (this.noOfDays == 1) {
          this.selectedDurationText = this.noOfDays + " day";
        } else {
          this.selectedDurationText = this.noOfDays + " days";
        }

      }
    }
    this.storyBoardDetailData = null;
    this.storyBoardTimeDetailData = null;
    this.storyBoardQualityDetailData = null;
    this.storyBoardCommunicationDetailData = null;
    this.optionData = {};
    this.detailResponseTotal = 0;
    this.showVerticalDivClass = "";
    this.showVerticalDiv = false;
    if (this.npsResponsePercentage >= 60) {
      this.npsWidth = 60;
    } else {
      this.npsWidth = this.npsResponsePercentage;
    }
    /*
    if(this.npsResponsePercentage >= 80){
      this.showVerticalDiv = true;
      this.showVerticalDivClass = "verticaldiv";
    }else{
      this.showVerticalDivClass = "";
      this.showVerticalDiv = false;
    } */
    this.updateExportDetails();
  }

  ngOnDestroy() {
    this.exportSubscription.unsubscribe();
    this.updateExportDetails(true);
  }

  configureCarousels() {
    // setTimeout(() => {
    $('#timeStoryID').carousel('pause');
    $('#qualityStoryID').carousel('pause');
    $('#commStoryID').carousel('pause');

    this.timeStoryIDTimer = setTimeout(() => {
      $('#timeStoryID').carousel('cycle')
    }, 3330);
    this.qualityStoryIDTimer = setTimeout(() => {
      $('#qualityStoryID').carousel('cycle')
    }, 5660);
    this.commStoryIDTimer = setTimeout(() => {
      $('#commStoryID').carousel('cycle')
    }, 7990);
    // }, 250);
  }
  startAllCarousel() {
    this.timeStoryIDTimer = setTimeout(() => {
      $('#timeStoryID').carousel('cycle')
    }, 3330);
    this.qualityStoryIDTimer = setTimeout(() => {
      $('#qualityStoryID').carousel('cycle')
    }, 5660);
    this.commStoryIDTimer = setTimeout(() => {
      $('#commStoryID').carousel('cycle')
    }, 7990);
  }
  slideCarouselEvent() {
    let _this = this;
    $('#timeStoryID').on("slide.bs.carousel", function (e) {
      _this.closeStoryPercentage();
    });
    $('#commStoryID').on("slide.bs.carousel", function (e) {
      _this.closeStoryPercentage();
    });
    $('#qualityStoryID').on("slide.bs.carousel", function (e) {
      _this.closeStoryPercentage();
    });
    $('#convenientStoryID').on("slide.bs.carousel", function (e) {
      _this.closeStoryPercentage();
    });
  }
  closeStoryPercentage() {
    if (this.isVisibleTimeStoryPercentage) {
      this.onClickTimeStoryPercentage();
    }
    if (this.isVisibleCommStoryPercentage) {
      this.onClickCommStoryPercentage();
    }
    if (this.isVisibleQualityStoryPercentage) {
      this.onClickQualityStoryPercentage();
    }
    if (this.isVisibleMainStoryPercentage) {
      this.onClickMainStoryPercentage();
    }
  }
  removeStoryPercentage() {
    if (this.isVisibleTimeStoryPercentage) {
      this.isVisibleTimeStoryPercentage = false;
      this.updateTimeDrillDownView("hide");
    }
    if (this.isVisibleCommStoryPercentage) {
      this.isVisibleCommStoryPercentage = false;
      this.updateCommDrillDownView("hide");
    }
    if (this.isVisibleQualityStoryPercentage) {
      this.isVisibleQualityStoryPercentage = false;
      this.updateQualityDrillDownView("hide");
    }
    if (this.isVisibleMainStoryPercentage) {
      this.isVisibleMainStoryPercentage = false;
      this.updateDrillDownView("hide");
    }
  }
  
  getStoryBoard() {

    this.convenient = {};
    this.time = {};
    this.quality = {}
    this.communication = {}
    this.convenientOptionSelected = [];
    this.timeOptionSelected = [];
    this.qualityOptionSelected = [];
    this.commOptionSelected = [];
    this.responseData = [];
    this.detailResponseTotal = 0;
    this.optionData = {};
    this.optionPercentage = {};
    this.responseTimeData = [];
    this.detailTimeResponseTotal = 0;
    this.optionTimeData = {};
    this.optionTimePercentage = {};
    this.responseQualityData = [];
    this.detailQualityResponseTotal = 0;
    this.optionDetailData = {};
    this.optionQualityPercentage = {};
    this.responseCommData = [];
    this.detailCommResponseTotal = 0;
    this.optionCommData = {};
    this.optionCommPercentage = {};

    if (this.storyBoardData != null && this.storyBoardData.Status == "Success" && this.storyBoardData.Response != null) {

      this.convenient = this.storyBoardData.Response.Collection[0];
      this.time = this.storyBoardData.Response.Collection[1];
      this.quality = this.storyBoardData.Response.Collection[2];
      this.communication = this.storyBoardData.Response.Collection[3];

      this.convenientOptionSelected = this.convenient.Options.map(ele => {
        ele["Length"] = this.convertToABB(ele.OptionCnt, 0).length;
        return ele;
      });

      this.applySmallClassForCategory = false;

      this.timeOptionSelected = this.time.Options.map(ele => {
        if (this.convertToABB(ele.OptionCnt, 0).length > 4) {
          this.applySmallClassForCategory = true;
        };
        return ele;
      });
      if (this.timeOptionSelected.length > 0) {
        $('#timeStoryID').carousel({
          interval: 2000,
          cycle: true
        });
      }
      this.qualityOptionSelected = this.quality.Options.map(ele => {
        if (this.convertToABB(ele.OptionCnt, 0).length > 4) {
          this.applySmallClassForCategory = true;
        };
        return ele;
      });
      if (this.qualityOptionSelected.length > 0) {
        $('#qualityStoryID').carousel({
          interval: 2000,
          cycle: true
        });
      }
      this.commOptionSelected = this.communication.Options.map(ele => {
        if (this.convertToABB(ele.OptionCnt, 0).length > 4) {
          this.applySmallClassForCategory = true;
        };
        return ele;
      });
      if (this.commOptionSelected.length > 0) {
        $('#commStoryID').carousel({
          interval: 2000,
          cycle: true
        });
      }
      this.slideCarouselEvent();
    }
  }
  updateDrillDown() {
    this.responseData = [];
    this.detailResponseTotal = 0;
    this.optionData = {};
    this.optionPercentage = {};
    this.responseQstn = "";
    if (this.storyBoardDetailData != null && this.storyBoardDetailData.Status == "Success" && this.storyBoardDetailData.Response != null) {
      this.responseData = this.storyBoardDetailData.Response[0].TouchPoints;
      this.responseQstn = this.storyBoardDetailData.Response[0].QuestionText;
      for (let i = 0; i < this.storyBoardDetailData.Response[0].TouchPoints.length; i++) {
        this.detailResponseTotal = this.detailResponseTotal + this.storyBoardDetailData.Response[0].TouchPoints[i].TotalCount;
        for (let o = 0; o < this.storyBoardDetailData.Response[0].TouchPoints[i].Options.length; o++) {
          if (this.optionData[this.storyBoardDetailData.Response[0].TouchPoints[i].Options[o].Option]) {
            this.optionData[this.storyBoardDetailData.Response[0].TouchPoints[i].Options[o].Option] = this.optionData[this.storyBoardDetailData.Response[0].TouchPoints[i].Options[o].Option] + this.storyBoardDetailData.Response[0].TouchPoints[i].Options[o].Count;
          } else {
            this.optionData[this.storyBoardDetailData.Response[0].TouchPoints[i].Options[o].Option] = this.storyBoardDetailData.Response[0].TouchPoints[i].Options[o].Count;
          }
        }
      }
    }
    for (var l in this.optionData) {
      if (this.optionData[l] !== 0) {
        this.optionPercentage[l] = Math.round((this.optionData[l] / this.detailResponseTotal) * 100);
      } else {
        this.optionPercentage[l] = 0;
      }

    }
  }
  updateDrillDownView(event) {
    if (event == "hide") {
      this.mainStoryPercentageDisplay = 'none';
      this.mainStoryPercentageOverflow = 'hidden';
      this.mainStoryIndicator = '3';
      this.mainStoryInterval = '2000';
      this.ratingDisplay = 'none';
      return;
    }
    if (this.storyBoardDetailData == null || this.storyBoardDetailData.Status == "Failure") {
      if (event == "click") {
        this.showErrorMain = this.isVisibleMainStoryPercentage ? 'block' : 'none';
      } else if (event == "hover") {
        this.showErrorMainHover = this.isVisible ? 'block' : 'none';
      }
    } else if (this.storyBoardDetailData && this.storyBoardDetailData.Response == null) {
      if (event == "click") {
        this.showNoDataMain = this.isVisibleMainStoryPercentage ? 'block' : 'none';
        this.showErrorMain = 'none';
      } else if (event == "hover") {
        this.showNoDataMainHover = this.isVisible ? 'block' : 'none';
        this.showErrorMainHover = 'none';
      }
    }
    if (event == "click") {
      this.mainStoryPercentageDisplay = this.isVisibleMainStoryPercentage ? 'block' : 'none';
      this.mainStoryPercentageOverflow = this.isVisibleMainStoryPercentage ? 'visible' : 'hidden';
      this.mainStoryIndicator = this.isVisibleMainStoryPercentage ? '0' : '3';
      this.mainStoryInterval = this.isVisibleMainStoryPercentage ? 'false' : '2000';
    } else if (event == "hover") {
      this.ratingDisplay = this.isVisible ? 'block' : 'none';
      this.mainStoryPercentageOverflow = this.isVisible ? 'visible' : 'hidden';
      this.mainStoryIndicator = this.isVisible ? '0' : '3';
      this.mainStoryInterval = this.isVisible ? 'false' : '2000';
    }
    if (this.isVisibleMainStoryPercentage || this.isVisible) {
      this.pauseAllCarousels();
    } else {
      this.startAllCarousel();
    }
  }
  getStoryBoardDetails(event) {
    if (this.isVisibleCommStoryPercentage || this.isVisibleCommunication) {
      this.isVisibleCommStoryPercentage = false;
      this.isVisibleCommunication = false;
      this.updateCommDrillDownView("hide");
    }
    if (this.isVisibleQualityStoryPercentage || this.isVisibleQuality) {
      this.isVisibleQualityStoryPercentage = false;
      this.isVisibleQuality = false;
      this.updateQualityDrillDownView("hide");
    }
    if (this.isVisibleTimeStoryPercentage || this.isVisibleTime) {
      this.isVisibleTimeStoryPercentage = false;
      this.isVisibleTime = false;
      this.updateTimeDrillDownView("hide");
    }

    let id = this.convenient.CategoryID;
    if (event == "hover" || event == "click") {
      this.pauseAllCarousels();
    }
    if (this.storyBoardDetailData == null) {
      if (event == "hover") {
        this.boolInvitationHover = 'block';
        this.ratingDisplay = "block";
      } else if (event = "click") {
        this.boolInvitation = 'block';
        this.mainStoryPercentageDisplay = "block";
      }
      this.mainStoryPercentageOverflow = "visible";
      this.mainStoryIndicator = '0';
      this.dashboardWidgetService.getStoryBoardDetails(id).then(res => {
        this.storyBoardDetailData = res;
        this.boolInvitation = 'none';
        this.boolInvitationHover = 'none';
        this.updateDrillDownView(event);
        this.updateDrillDown();
      }).catch(err => {
        this.storyBoardDetailData = null;
        this.boolInvitation = 'none';
        this.boolInvitationHover = 'none';
        this.updateDrillDownView(event);
      });
    } else {
      this.updateDrillDownView(event);
    }
  }
  changeStyle($event) {
    this.isVisible = $event.type == 'mouseover' ? true : false;
    if (this.isVisible) {
      this.getStoryBoardDetails("hover");
    } else {
      this.updateDrillDownView("hover");
    }
  }
  onClickMainStoryPercentage() {
    this.isVisibleMainStoryPercentage = !this.isVisibleMainStoryPercentage;
    if (this.isVisibleMainStoryPercentage) {
      this.getStoryBoardDetails("click");
    } else {
      this.updateDrillDownView("click");
    }
  }
  updateTimeDrillDown() {
    this.responseTimeData = [];
    this.detailTimeResponseTotal = 0;
    this.optionTimeData = {};
    this.optionTimePercentage = {};
    if (this.storyBoardTimeDetailData != null && this.storyBoardTimeDetailData.Status == "Success" && this.storyBoardTimeDetailData.Response != null) {
      this.responseTimeData = this.storyBoardTimeDetailData.Response[0].TouchPoints;
      this.responseQstnTime = this.storyBoardTimeDetailData.Response[0].QuestionText;
      for (let i = 0; i < this.storyBoardTimeDetailData.Response[0].TouchPoints.length; i++) {
        this.detailTimeResponseTotal = this.detailTimeResponseTotal + this.storyBoardTimeDetailData.Response[0].TouchPoints[i].TotalCount;
        for (let o = 0; o < this.storyBoardTimeDetailData.Response[0].TouchPoints[i].Options.length; o++) {
          if (this.optionTimeData[this.storyBoardTimeDetailData.Response[0].TouchPoints[i].Options[o].Option]) {
            this.optionTimeData[this.storyBoardTimeDetailData.Response[0].TouchPoints[i].Options[o].Option] = this.optionTimeData[this.storyBoardTimeDetailData.Response[0].TouchPoints[i].Options[o].Option] + this.storyBoardTimeDetailData.Response[0].TouchPoints[i].Options[o].Count;
          } else {
            this.optionTimeData[this.storyBoardTimeDetailData.Response[0].TouchPoints[i].Options[o].Option] = this.storyBoardTimeDetailData.Response[0].TouchPoints[i].Options[o].Count;
          }
        }
      }
    }
    for (var l in this.optionTimeData) {
      // this.optionTimePercentage[l] = Math.round((this.optionTimeData[l] / this.detailTimeResponseTotal) * 100);
      var ot = Math.round((this.optionTimeData[l] / this.detailTimeResponseTotal) * 100);
      if (Number.isNaN(ot)) {
        this.optionTimePercentage[l] = 0;
      } else {
        this.optionTimePercentage[l] = ot;
      }
    }
  }
  updateTimeDrillDownView(event) {
    if (event == "hide") {
      this.timeStoryPercentageDisplay = 'none';
      this.timeStoryPercentageOverflow = 'hidden';
      this.zindexTime = 0;
      this.timeStoryInterval = '2000';
      this.ratingDisplayTime = 'none';
      return;
    }
    if (this.storyBoardTimeDetailData == null || this.storyBoardTimeDetailData.Status == "Failure") {
      if (event == "click") {
        this.showErrorTime = this.isVisibleTimeStoryPercentage ? 'block' : 'none';
      } else if (event == "hover") {
        this.showErrorTimeHover = this.isVisibleTime ? 'block' : 'none';
      }
    } else if (this.storyBoardTimeDetailData && this.storyBoardTimeDetailData.Response == null) {
      if (event == "click") {
        this.showNoDataTime = this.isVisibleTimeStoryPercentage ? 'block' : 'none';
        this.showErrorTime = 'none';
      } else if (event == "hover") {
        this.showNoDataTimeHover = this.isVisibleTime ? 'block' : 'none';
        this.showErrorTimeHover = 'none';
      }
    }
    if (event == "click") {
      this.timeStoryPercentageDisplay = this.isVisibleTimeStoryPercentage ? 'block' : 'none';
      this.timeStoryPercentageOverflow = this.isVisibleTimeStoryPercentage ? 'visible' : 'hidden';
      this.zindexTime = this.isVisibleTimeStoryPercentage ? 5 : 0;
      this.timeStoryInterval = this.isVisibleTimeStoryPercentage ? 'false' : '2000';
    } else if (event == "hover") {
      this.ratingDisplayTime = this.isVisibleTime ? 'block' : 'none';
      this.timeStoryPercentageOverflow = this.isVisibleTime ? 'visible' : 'hidden';
      this.zindexTime = this.isVisibleTime ? 5 : 0;
      this.timeStoryInterval = this.isVisibleTime ? 'false' : '2000';
    }
    if (this.isVisibleTimeStoryPercentage || this.isVisibleTime) {
      //event == "click" ? this.pauseAllCarousels() : $('#timeStoryID').carousel('pause');
      this.pauseAllCarousels();
    } else {
      this.startAllCarousel();
      /* (event == "click" || event == "hover") ? this.configureCarousels() :$('#timeStoryID').carousel({
         interval: 2000,
         cycle: true
       }); */
      // if(this.timeStoryIDTimer) clearTimeout(this.timeStoryIDTimer);
    }
  }
  getStoryBoardTimeDetails(event) {
    if (this.isVisibleCommStoryPercentage || this.isVisibleCommunication) {
      this.isVisibleCommStoryPercentage = false;
      this.isVisibleCommunication = false;
      this.updateCommDrillDownView("hide");
    }
    if (this.isVisibleQualityStoryPercentage || this.isVisibleQuality) {
      this.isVisibleQualityStoryPercentage = false;
      this.isVisibleQuality = false;
      this.updateQualityDrillDownView("hide");
    }
    if (this.isVisibleMainStoryPercentage || this.isVisible) {
      this.isVisibleMainStoryPercentage = false;
      this.isVisible = false;
      this.updateDrillDownView("hide");
    }
    let id = this.time.CategoryID;
    if (event == "hover" || event == "click") {
      this.pauseAllCarousels();
    }
    if (this.storyBoardTimeDetailData == null) {
      if (event == "hover") {
        this.boolInvitationTimeHover = 'block';
        this.ratingDisplayTime = "block";
      } else if (event = "click") {
        this.boolInvitationTime = 'block';
        this.timeStoryPercentageDisplay = "block";
      }
      this.zindexTime = 5;
      this.timeStoryPercentageOverflow = "visible";
      this.dashboardWidgetService.getStoryBoardDetails(id).then(res => {
        this.storyBoardTimeDetailData = res;
        this.boolInvitationTime = 'none';
        this.boolInvitationTimeHover = 'none';
        this.timeStoryPercentageOverflow = "hidden";
        this.updateTimeDrillDownView(event);
        this.updateTimeDrillDown();
      }).catch(err => {
        this.storyBoardTimeDetailData = null;
        this.boolInvitationTime = 'none';
        this.boolInvitationTimeHover = 'none';
        this.updateTimeDrillDownView(event);
      });
    } else {
      this.updateTimeDrillDownView(event);
    }
  }
  changeStyleTime($event) {
    this.isVisibleTime = $event.type == 'mouseover' ? true : false;
    if (this.isVisibleTime) {
      this.getStoryBoardTimeDetails("hover");
    } else {
      this.updateTimeDrillDownView("hover");
    }
  }
  onClickTimeStoryPercentage() {
    this.isVisibleTimeStoryPercentage = !this.isVisibleTimeStoryPercentage;
    if (this.isVisibleTimeStoryPercentage) {
      this.getStoryBoardTimeDetails("click");
    } else {
      this.updateTimeDrillDownView("click");
    }
  }
  updateQualityDrillDown() {
    this.responseQualityData = [];
    this.detailQualityResponseTotal = 0;
    this.optionDetailData = {};
    this.optionQualityPercentage = {};
    if (this.storyBoardQualityDetailData != null && this.storyBoardQualityDetailData.Status == "Success" && this.storyBoardQualityDetailData.Response != null) {
      this.responseQualityData = this.storyBoardQualityDetailData.Response[0].TouchPoints;
      this.responseQstnQuality = this.storyBoardQualityDetailData.Response[0].QuestionText;
      for (let i = 0; i < this.storyBoardQualityDetailData.Response[0].TouchPoints.length; i++) {
        this.detailQualityResponseTotal = this.detailQualityResponseTotal + this.storyBoardQualityDetailData.Response[0].TouchPoints[i].TotalCount;
        for (let o = 0; o < this.storyBoardQualityDetailData.Response[0].TouchPoints[i].Options.length; o++) {
          if (this.optionDetailData[this.storyBoardQualityDetailData.Response[0].TouchPoints[i].Options[o].Option]) {
            this.optionDetailData[this.storyBoardQualityDetailData.Response[0].TouchPoints[i].Options[o].Option] = this.optionDetailData[this.storyBoardQualityDetailData.Response[0].TouchPoints[i].Options[o].Option] + this.storyBoardQualityDetailData.Response[0].TouchPoints[i].Options[o].Count;
          } else {
            this.optionDetailData[this.storyBoardQualityDetailData.Response[0].TouchPoints[i].Options[o].Option] = this.storyBoardQualityDetailData.Response[0].TouchPoints[i].Options[o].Count;
          }
        }
      }
    }
    for (var l in this.optionDetailData) {
      // this.optionQualityPercentage[l] = Math.round((this.optionDetailData[l] / this.detailQualityResponseTotal) * 100);
      var od = Math.round((this.optionDetailData[l] / this.detailQualityResponseTotal) * 100);
      if (Number.isNaN(od)) {
        this.optionQualityPercentage[l] = 0;
      } else {
        this.optionQualityPercentage[l] = od;
      }
    }
  }
  getStoryBoardQualityDetails(event) {
    if (this.isVisibleCommStoryPercentage || this.isVisibleCommunication) {
      this.isVisibleCommStoryPercentage = false;
      this.isVisibleCommunication = false;
      this.updateCommDrillDownView("hide");
    }
    if (this.isVisibleTimeStoryPercentage || this.isVisibleTime) {
      this.isVisibleTimeStoryPercentage = false;
      this.isVisibleTime = false;
      this.updateTimeDrillDownView("hide");
    }
    if (this.isVisibleMainStoryPercentage || this.isVisible) {
      this.isVisibleMainStoryPercentage = false;
      this.isVisible = false;
      this.updateDrillDownView("hide");
    }
    let id = this.quality.CategoryID;
    if (event == "hover" || event == "click") {
      this.pauseAllCarousels();
    }
    if (this.storyBoardQualityDetailData == null) {
      if (event == "hover") {
        this.boolInvitationQualityHover = 'block';
        this.ratingDisplayQuality = 'block';
      } else if (event = "click") {
        this.boolInvitationQuality = 'block';
        this.qualityStoryPercentageDisplay = 'block';
      }
      this.zindexQuality = 5;
      this.qualityStoryPercentageOverflow = "visible";
      this.dashboardWidgetService.getStoryBoardDetails(id).then(res => {
        this.storyBoardQualityDetailData = res;
        /*  this.storyBoardQualityDetailData = {
              "Status": "Failure",
              "Response": null,
              "Errors": []
          } */
        this.boolInvitationQuality = 'none';
        this.boolInvitationQualityHover = 'none';
        this.updateQualityDrillDownView(event);
        this.updateQualityDrillDown();
      }).catch(err => {
        this.storyBoardQualityDetailData = null;
        this.boolInvitationQuality = 'none';
        this.boolInvitationQualityHover = 'none';
        this.updateQualityDrillDownView(event);
      });
    } else {
      this.updateQualityDrillDownView(event);
    }
  }
  updateQualityDrillDownView(event) {
    if (event == "hide") {
      this.qualityStoryPercentageDisplay = 'none';
      this.qualityStoryPercentageOverflow = 'hidden';
      this.zindexQuality = 0;
      this.qualityStoryInterval = '2000';
      this.ratingDisplayQuality = 'none';
      return;
    }
    if (this.storyBoardQualityDetailData == null || this.storyBoardQualityDetailData.Status == "Failure") {
      if (event == "click") {
        this.showErrorQuality = this.isVisibleQualityStoryPercentage ? 'block' : 'none';
      } else if (event == "hover") {
        this.showErrorQualityHover = this.isVisibleQuality ? 'block' : 'none';
      }
    } else if (this.storyBoardQualityDetailData && this.storyBoardQualityDetailData.Response == null) {
      if (event == "click") {
        this.showNoDataQuality = this.isVisibleQualityStoryPercentage ? 'block' : 'none';
        this.showErrorQuality = 'none';
      } else if (event == "hover") {
        this.showNoDataQualityHover = this.isVisibleQuality ? 'block' : 'none';
        this.showErrorQualityHover = 'none';
      }
    }
    if (event == "click") {
      this.qualityStoryPercentageDisplay = this.isVisibleQualityStoryPercentage ? 'block' : 'none';
      this.qualityStoryPercentageOverflow = this.isVisibleQualityStoryPercentage ? 'visible' : 'hidden';
      this.zindexQuality = this.isVisibleQualityStoryPercentage ? 5 : 0;
      this.qualityStoryInterval = this.isVisibleQualityStoryPercentage ? 'false' : '2000';
    } else if (event == "hover") {
      this.ratingDisplayQuality = this.isVisibleQuality ? 'block' : 'none';
      this.qualityStoryPercentageOverflow = this.isVisibleQuality ? 'visible' : 'hidden';
      this.zindexQuality = this.isVisibleQuality ? 5 : 0;
      this.qualityStoryInterval = this.isVisibleQuality ? 'false' : '2000';
    }
    if (this.isVisibleQualityStoryPercentage || this.isVisibleQuality) {
      //  event == "click" ? this.pauseAllCarousels() :$('#qualityStoryID').carousel('pause');
      this.pauseAllCarousels();
    } else {
      this.startAllCarousel();
      /*   event == "click" ? this.configureCarousels() : $('#qualityStoryID').carousel({
           interval: 2000,
           cycle: true
         }); */
      // if(this.qualityStoryIDTimer) clearTimeout(this.qualityStoryIDTimer);
    }
  }
  changeStyleQuality($event) {
    this.isVisibleQuality = $event.type == 'mouseover' ? true : false;
    if (this.isVisibleQuality) {
      this.getStoryBoardQualityDetails("hover");
    } else {
      this.updateQualityDrillDownView("hover");
    }

  }
  onClickQualityStoryPercentage() {
    this.isVisibleQualityStoryPercentage = !this.isVisibleQualityStoryPercentage;
    if (this.isVisibleQualityStoryPercentage) {
      this.getStoryBoardQualityDetails("click");
    } else {
      this.updateQualityDrillDownView("click");
    }
  }
  updateCommDrillDownView(event) {
    if (event == "hide") {
      this.commStoryPercentageDisplay = 'none';
      this.commStoryPercentageOverflow = 'hidden';
      this.zindexComm = 0;
      this.commStoryInterval = '2000';
      this.ratingDisplayCommunication = 'none';
      return;
    }
    if (this.storyBoardCommunicationDetailData == null || this.storyBoardCommunicationDetailData.Status == "Failure") {
      if (event == "click") {
        this.showErrorComm = this.isVisibleCommStoryPercentage ? 'block' : 'none';
        //this.errorPop = this.isVisibleCommStoryPercentage ? 'errorpop' : '';
      } else if (event == "hover") {
        this.showErrorCommHover = this.isVisibleCommunication ? 'block' : 'none';
      }
    } else if (this.storyBoardCommunicationDetailData && this.storyBoardCommunicationDetailData.Response == null) {
      if (event == "click") {
        this.showNoDataComm = this.isVisibleCommStoryPercentage ? 'block' : 'none';
        this.errorPop = this.isVisibleCommStoryPercentage ? 'errorpop' : '';
        this.showErrorComm = 'none';
      } else if (event == "hover") {
        this.showNoDataCommHover = this.isVisibleCommunication ? 'block' : 'none';
        this.showErrorCommHover = 'none';
      }
    }
    if (event == "click") {
      this.commStoryPercentageDisplay = this.isVisibleCommStoryPercentage ? 'block' : 'none';
      this.commStoryPercentageOverflow = this.isVisibleCommStoryPercentage ? 'visible' : 'hidden';
      this.zindexComm = this.isVisibleCommStoryPercentage ? '5' : '0';
      this.commStoryInterval = this.isVisibleCommStoryPercentage ? 'false' : '2000';
    } else if (event == "hover") {
      this.ratingDisplayCommunication = this.isVisibleCommunication ? 'block' : 'none';
      this.commStoryPercentageOverflow = this.isVisibleCommunication ? 'visible' : 'hidden';
      this.zindexComm = this.isVisibleCommunication ? '5' : '0';
      this.commStoryInterval = this.isVisibleCommunication ? 'false' : '2000';
    }
    if (this.isVisibleCommStoryPercentage || this.isVisibleCommunication) {
      //  event == "click" ? this.pauseAllCarousels() : $('#commStoryID').carousel('pause');
      this.pauseAllCarousels();
    } else {
      this.startAllCarousel();
      /*    event == "click" ? this.configureCarousels() : $('#commStoryID').carousel({
            interval: 2000,
            cycle: true
          }); */
      // if(this.commStoryIDTimer) clearTimeout(this.commStoryIDTimer);
    }
  }
  updateCommDrillDown() {
    this.responseCommData = [];
    this.detailCommResponseTotal = 0;
    this.optionCommData = {};
    this.optionCommPercentage = {};

    if (this.storyBoardCommunicationDetailData != null && this.storyBoardCommunicationDetailData.Status == "Success" && this.storyBoardCommunicationDetailData.Response != null) {
      this.responseCommData = this.storyBoardCommunicationDetailData.Response[0].TouchPoints;
      this.responseQstnComm = this.storyBoardCommunicationDetailData.Response[0].QuestionText;
      for (let i = 0; i < this.storyBoardCommunicationDetailData.Response[0].TouchPoints.length; i++) {
        this.detailCommResponseTotal = this.detailCommResponseTotal + this.storyBoardCommunicationDetailData.Response[0].TouchPoints[i].TotalCount;
        for (let o = 0; o < this.storyBoardCommunicationDetailData.Response[0].TouchPoints[i].Options.length; o++) {
          if (this.optionCommData[this.storyBoardCommunicationDetailData.Response[0].TouchPoints[i].Options[o].Option]) {
            this.optionCommData[this.storyBoardCommunicationDetailData.Response[0].TouchPoints[i].Options[o].Option] = this.optionCommData[this.storyBoardCommunicationDetailData.Response[0].TouchPoints[i].Options[o].Option] + this.storyBoardCommunicationDetailData.Response[0].TouchPoints[i].Options[o].Count;
          } else {
            this.optionCommData[this.storyBoardCommunicationDetailData.Response[0].TouchPoints[i].Options[o].Option] = this.storyBoardCommunicationDetailData.Response[0].TouchPoints[i].Options[o].Count;
          }
        }
      }
    }
    for (var l in this.optionCommData) {
      // this.optionCommPercentage[l] = Math.round((this.optionCommData[l] / this.detailCommResponseTotal) * 100);
      var oc = Math.round((this.optionCommData[l] / this.detailCommResponseTotal) * 100);
      if (Number.isNaN(oc)) {
        this.optionCommPercentage[l] = 0;
      } else {
        this.optionCommPercentage[l] = oc;
      }
    }
  }
  getStoryBoardCommunicationDetails(event) {
    if (this.isVisibleQualityStoryPercentage || this.isVisibleQuality) {
      this.isVisibleQualityStoryPercentage = false;
      this.isVisibleQuality = false;
      this.updateQualityDrillDownView("hide");
    }
    if (this.isVisibleTimeStoryPercentage || this.isVisibleTime) {
      this.isVisibleTimeStoryPercentage = false;
      this.isVisibleTime = false;
      this.updateTimeDrillDownView("hide");
    }
    if (this.isVisibleMainStoryPercentage || this.isVisible) {
      this.isVisibleMainStoryPercentage = false;
      this.isVisible = false;
      this.updateDrillDownView("hide");
    }

    let id = this.communication.CategoryID;
    if (event == "hover" || event == "click") {
      this.pauseAllCarousels();
    }
    if (this.storyBoardCommunicationDetailData == null) {
      if (event == "hover") {
        this.boolInvitationCommHover = 'block';
        this.ratingDisplayCommunication = 'block';
      } else if (event = "click") {
        this.boolInvitationComm = 'block';
        this.commStoryPercentageDisplay = 'block';
      }
      this.commStoryPercentageOverflow = "visible";
      this.zindexComm = '5';
      this.dashboardWidgetService.getStoryBoardDetails(id).then(res => {
        this.storyBoardCommunicationDetailData = res;
        /*  this.storyBoardCommunicationDetailData = {
            "Status": "Failure",
            "Response": null,
            "Errors": []
          } */
        this.commStoryPercentageOverflow = "hidden";
        this.boolInvitationComm = 'none';
        this.boolInvitationCommHover = 'none';
        this.updateCommDrillDownView(event);
        this.updateCommDrillDown();
      }).catch(err => {
        this.storyBoardCommunicationDetailData = null;
        this.boolInvitationComm = 'none';
        this.boolInvitationCommHover = 'none';
        this.updateCommDrillDownView(event);
      });
    } else {
      this.updateCommDrillDownView(event);
    }
  }
  changeStyleCommuication($event) {
    this.isVisibleCommunication = $event.type == 'mouseover' ? true : false;
    if (this.isVisibleCommunication) {
      this.getStoryBoardCommunicationDetails("hover");
    } else {
      this.updateCommDrillDownView("hover");
    }
  }
  onClickCommStoryPercentage() {
    this.isVisibleCommStoryPercentage = !this.isVisibleCommStoryPercentage;
    if (this.isVisibleCommStoryPercentage) {
      this.getStoryBoardCommunicationDetails("click");
    } else {
      this.updateCommDrillDownView("click");
    }
  }
  toggleByPercentage() {
    if (this.storyByNumber == true) {
      this.storyByNumber = false;
    }
  }

  toggleByNumber() {
    if (this.storyByNumber == false) {
      this.storyByNumber = true;
    }
  }
  isVisible: boolean = false;
  isVisibleTime: boolean = false;
  isVisibleQuality: boolean = false;
  isVisibleCommunication: boolean = false;
  ratingDisplay: string;
  ratingVisible: string;
  ratingDisplayTime: string;
  ratingVisibleTime: string;
  ratingDisplayQuality: string;
  ratingVisibleQuality: string;
  ratingDisplayCommunication: string;
  ratingVisibleCommunication: string;
  zindexTime: any;
  zindexQuality: any;
  zindexComm: any;
  isVisibleMainStoryPercentage: boolean = false;
  mainStoryPercentageDisplay: string = 'none';
  mainStoryPercentageOverflow: string = 'hidden';
  isVisibleTimeStoryPercentage: boolean = false;
  timeStoryPercentageDisplay: string = 'none';
  timeStoryPercentageOverflow: string = 'hidden';
  isVisibleQualityStoryPercentage: boolean = false;
  qualityStoryPercentageDisplay: string = 'none';
  qualityStoryPercentageOverflow: string = 'hidden';
  isVisibleCommStoryPercentage: boolean = false;
  commStoryPercentageDisplay: string = 'none';
  commStoryPercentageOverflow: string = 'hidden';
  boolInvitationSentLoader: boolean = false;
  boolInvitation: string = 'none';
  boolInvitationHover: string = 'none';
  boolInvitationTime: string = 'none';
  boolInvitationTimeHover: string = 'none';
  boolInvitationQuality: string = 'none';
  boolInvitationQualityHover: string = 'none';
  boolInvitationComm: string = 'none';
  boolInvitationCommHover: string = 'none';
  mainStoryIndicator: any = '3';
  showErrorMain: string = 'none';
  showErrorMainHover: string = 'none';
  showNoDataMainHover: string = 'none';
  showNoDataMain: string = 'none';
  showNoDataTimeHover: string = 'none';
  showNoDataTime: string = 'none';
  showErrorTime: string = 'none';
  showErrorTimeHover: string = 'none';
  showErrorQuality: string = 'none';
  showErrorQualityHover: string = 'none';
  showErrorComm: string = 'none';
  showErrorCommHover: string = 'none';
  showNoDataCommHover: string = 'none';
  showNoDataComm: string = 'none';
  showNoDataQualityHover: string = 'none';
  showNoDataQuality: string = 'none';
  errorPop: string = 'any';
  showTotalResponse: boolean = false;

  Divshow = false;
  openTotalResponseData(event) {
    event.stopPropagation();
    this.showTotalResponse = false;
    this.Divshow = !this.Divshow;

    this.updateCommDrillDownView('hide');
    this.updateTimeDrillDownView('hide');
    this.updateQualityDrillDownView('hide');
    this.updateDrillDownView('hide');
  }
  openTotalResponseOverlay() {
    this.Divshow = false;
    this.showTotalResponse = !this.showTotalResponse;

    this.updateCommDrillDownView('hide');
    this.updateTimeDrillDownView('hide');
    this.updateQualityDrillDownView('hide');
    this.updateDrillDownView('hide');
  }
  hideArrow(event) {
    if (event.hideArrow) {
      this.Divshow = false;
    }
    else {
      this.Divshow = true;
    }

  }
  hideTotalArrow(event) {
    if (event.hideTotalArrow) {
      this.showTotalResponse = false;
    } else {
      this.showTotalResponse = true;
    }

  }
  convertToABB(num, fixed) {
    num = parseInt(num);
    fixed = parseInt(fixed);
    if (num === null) { return null; }
    if (num === 0) { return '0'; }
    fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
    var b = (num).toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
    return e;
  }

  private changeDateFormat(data) {
    var splitDate = data.split('-');
    var month_names = ["Jan", "Feb", "Mar",
      "Apr", "May", "Jun",
      "Jul", "Aug", "Sep",
      "Oct", "Nov", "Dec"];
    var indexMonth = month_names.indexOf(splitDate[1]);
    return (indexMonth + 1) +
      "/" + splitDate[0] +
      "/" + splitDate[2];
  }
  private pauseAllCarousels() {
    clearTimeout(this.timeStoryIDTimer);
    clearTimeout(this.qualityStoryIDTimer);
    clearTimeout(this.commStoryIDTimer);
    $('#timeStoryID').carousel('pause');
    $('#qualityStoryID').carousel('pause');
    $('#commStoryID').carousel('pause');
  }

  private toCanvas = () => {
    try {
      let chartUpdate: ExportChartModel = new ExportChartModel();
      chartUpdate.UpdateTime = new Date();
      chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
      chartUpdate.ChartImgId = null;
      chartUpdate.CurrentState = this.widgetState;
      chartUpdate.ChartImgBase64 = 'this_does_not_have_to_be_uploaded_to_blob';
      this.sharedService.updateChartStatusSubject(chartUpdate);

    } catch (error) {
      console.log('html2canvas error as', error);
    }
  }
  private updateExportDetails = (isDestroy: boolean = false) => {
    this.determineWidgetState();
    isDestroy ? this.widgetState = CHART_STATE_OPTIONS.UNKNOWN : null;

    let chartUpdate: ExportChartModel = new ExportChartModel();
    chartUpdate.UpdateTime = new Date();
    chartUpdate.ChartName = WIDGET_CODE.toLowerCase();
    chartUpdate.CurrentState = this.widgetState;
    this.sharedService.updateChartStatusSubject(chartUpdate);
  }
  private determineWidgetState = (): void => {
    if (this.boolStoryBoardLoader) this.widgetState = CHART_STATE_OPTIONS.LOADING;
    else if (this.showErrorMain.toLowerCase() === 'block') this.widgetState = CHART_STATE_OPTIONS.ERROR;
    else if (this.showNoDataMain.toLowerCase() === 'block') this.widgetState = CHART_STATE_OPTIONS.NO_DATA;
    else if (this.showNoDataMain !== 'block' && this.showErrorMain !== 'block' && !this.boolStoryBoardLoader) this.widgetState = CHART_STATE_OPTIONS.SUCCESS;
  }
  private slideTextToShow = () => {
    this.sharedService.updatechartTextSubject(this.slideTextCTDB(this.convenientOptionSelected, WIDGET_CODE_CTDB, "Convenience to do Business"));
    this.sharedService.updatechartTextSubject(this.slideTextCTDB(this.qualityOptionSelected, WIDGET_CODE_QOSS, "Quality of Services and Solutions Offered"));
    this.sharedService.updatechartTextSubject(this.slideTextCTDB(this.commOptionSelected, WIDGET_CODE_COMM, "Communication"));
    this.sharedService.updatechartTextSubject(this.slideTextCTDB(this.timeOptionSelected, WIDGET_CODE_LEADTIME, "Lead Time"));
  }

  private slideTextCTDB = (iterateOver: any[], slideTextId: string, slideTitle: string ): SlideTextData => {
    let text = '', listString: string[] = [], slideRows: string[] = [], row: string = '';
    let textConfig: SlideTextData = new SlideTextData();

    if (this.widgetState === CHART_STATE_OPTIONS.NO_DATA) {
      textConfig.SlideText = slideTitle;
      textConfig.SlideRows = [];
      textConfig.SlideId = WIDGET_CODE;
      textConfig.SlideTextId = slideTextId;
      textConfig.HasNoData = true;
    } else if (this.widgetState === CHART_STATE_OPTIONS.SUCCESS) {
      for (let iteIdx = 0; iteIdx < iterateOver.length; iteIdx++) {
        const element = iterateOver[iteIdx];
        if (element.OptionText.split(' ').join(' -').toLowerCase() && element.Percentage !== null) {
          slideRows.push(element.OptionText.split(' ').join('_').toLowerCase() + '||' + element.Percentage);
        }
      }
      textConfig.SlideText = slideTitle;
      textConfig.SlideRows = slideRows;
      textConfig.SlideId = WIDGET_CODE;
      textConfig.SlideTextId = slideTextId;
    }

    return textConfig;
  }
    
  refreshWidget(widgetKey : string) {
    let refreshData = {
      refresh: true
    };
    if (widgetKey) {
      refreshData[widgetKey] = true;
    }
    this.chartDataService.setRefreshWidgetData(refreshData);
  }
  @HostListener('slide.bs.carousel', ['$event']) documentCarousel($event: any) {
    console.log('inside carousel', $event);
    
  }
}
