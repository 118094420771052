import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CHART_STATE_OPTIONS } from '../ctls-enums/ctls-chart-state-options.enum';
import { CtlsSpinnerService } from '../ctls-components/ctls-spinner/ctls-spinner.service';
import { CtlsDashboardCustomerService } from '../ctls-services/ctls-dashboard-customer.service';
import { takeUntil } from 'rxjs/operators';
import { CONSTANTS } from '../ctls-constants/ctls-common-constants';

@Component({
  selector: 'app-heat-map-details-popup',
  templateUrl: './heat-map-details-popup.component.html',
  styleUrls: ['./heat-map-details-popup.component.scss']
})
export class HeatMapDetailsPopupComponent implements OnInit {

  public showOthers: boolean;
  public showMore: boolean;
  public lastShowOthers: boolean;
  @Input() entity: any;
  @Input('filterObj') filterObj;
  destroy$ = new Subject();
  taskType = CONSTANTS.taskType;
  entityType = CONSTANTS.entityType;
  objEntityData: any = {};
  accountDetails: any = {};
  taskDetails = [];
  surveyDetails = [];
  selectedTaskType = null;
  showTaskCountPopup = false;
  showAccountCountPopup = false;
  clickEventData:any = {};
  positionClass = '';
  spinner = false;
  constructor(
    private spinnerService: CtlsSpinnerService,
    private ctlsDashboardCustomerService: CtlsDashboardCustomerService,
  ) {

  }

  ngOnInit() {
    this.filterObj.EntityID = this.entity.EntityID ? this.entity.EntityID : this.entity.EntityId;
    this.filterObj.PostCode = this.entity.EntityID ? this.entity.PostCode : null;
    this.filterObj.StandardEntityID = this.entity.StandardEntityID ? this.entity.StandardEntityID : this.entity.StandardEntityId;
    this.filterObj.GlobalEntityID = this.entity.GlobalEntityID ? this.entity.GlobalEntityID : this.entity.GlobalEntityId;
    this.filterObj.CustomerSitePostCode = this.filterObj.EntityID && this.entity.CustomerSitePostCode ? this.entity.CustomerSitePostCode : null;
    let heatMapDetails = this.ctlsDashboardCustomerService.getHMDetails(this.filterObj);
    this.ctlsDashboardCustomerService.setFilterObj(this.filterObj);
    this.showOthers = false;
    this.showMore = false;
    if (!heatMapDetails) {
      this.getCountDetailsHeatMap(this.filterObj);
    } else {
      this.objEntityData = heatMapDetails.CountDetails;
      this.accountDetails = heatMapDetails.AccountDetails;
      this.surveyDetails = heatMapDetails.SurveyResponseDetails;
      this.taskDetails = heatMapDetails.VocTaskDetails;
    }
    // this.setDummyData();
  }

  onClickOthers() {
    this.showOthers = true;
    this.showMore = false;
  }

  onClickMore() {
    this.lastShowOthers = this.showOthers;
    this.showOthers = false;
    this.showMore = true;
  }

  onClickBack() {
    this.showOthers = false;
    this.showMore = false;
  }

  onClickBackToOthers() {
    this.showOthers = true;
    this.showMore = false;
  }

  getCountDetailsHeatMap(filterObj) {
    this.spinnerService.show();
    this.spinner = true;
    this.ctlsDashboardCustomerService.getCountDetailsHeatMap(filterObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        let res: any = data;

        if (res.Response && res.Status == 'Success') {
          this.objEntityData = res.Response;

        }
        this.getAccountDetails(this.filterObj);
        this.ctlsDashboardCustomerService.setHMCountDetails(this.objEntityData);
        this.spinnerService.hide();
        this.spinner = false;
      }, (err) => {
        this.spinnerService.hide();
        this.spinner = false;
      }, () => {
        this.spinnerService.hide();
        this.spinner = false;
      });
  }

  getAccountDetails(filterObj) {
    this.ctlsDashboardCustomerService.getAccountDetails(filterObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        let res: any = data;

        if (res.Response && res.Status == 'Success') {
          this.getHeatMapSurveyResponseDetails(this.filterObj);
          this.getVOCTaskDetailsHeatmap(this.filterObj);
          this.accountDetails = res.Response;
          if (this.accountDetails) {
            if (filterObj && filterObj.filterFields) {
              this.accountDetails.BAList = filterObj.filterFields.bAIDs &&
                filterObj.filterFields.bAIDs.length ?
                this.accountDetails.BAList.filter(y => filterObj.filterFields.bAIDs.includes(y.BAID)) :
                this.accountDetails.BAList;
                this.accountDetails.FAMList = filterObj.filterFields.fAMIDs &&
                filterObj.filterFields.fAMIDs.length ?
                this.accountDetails.FAMList.filter(y => filterObj.filterFields.fAMIDs.includes(y.FAMID)) :
                this.accountDetails.FAMList;
                this.accountDetails.DIVList = filterObj.filterFields.dIVIDs &&
                filterObj.filterFields.dIVIDs.length ?
                this.accountDetails.DIVList.filter(y => filterObj.filterFields.dIVIDs.includes(y.DivisionID)) :
                this.accountDetails.DIVList;
            }
          }
        }
        this.ctlsDashboardCustomerService.setHMAccountdetails(this.accountDetails);
      }, (err) => {
        this.accountDetails = [];

      }, () => {

      });
  }

  getHeatMapSurveyResponseDetails(filterObj) {
    // this.spinnerService.hide();
    this.ctlsDashboardCustomerService.getHeatMapSurveyResponseDetails(filterObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        let revRes: any = data;
        if (revRes !== null && revRes.Status !== 'Success') {
          this.surveyDetails = [];
        }

        if ((revRes === null || revRes.Response === null || (revRes.Response.length === 0))) {
          this.surveyDetails = [];

        }

        if (revRes.Response && revRes.Status == 'Success') {
          this.surveyDetails = data.Response.Items;
        }
        this.ctlsDashboardCustomerService.setHMSurveyResponseDetails(this.surveyDetails);


      }, (err) => {
        this.surveyDetails = [];
      }, () => {
      });
  }

  getVOCTaskDetailsHeatmap(filterObj) {
    this.taskDetails = [];
    // this.spinnerService.hide();
    this.ctlsDashboardCustomerService.getVOCTaskDetailsHeatmap(filterObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((revRes) => {

        if (revRes !== null && revRes.Status !== 'Success') {
          this.taskDetails = [];
        }

        if ((revRes === null || revRes.Response === null || (revRes.Response.length === 0))) {
          this.taskDetails = [];

        }

        if (revRes.Response && revRes.Status == 'Success') {
          this.taskDetails = revRes.Response.Items;
        }
        this.ctlsDashboardCustomerService.setHMVOCTaskDetails(this.taskDetails);

      }, (err) => {
        this.taskDetails = [];
      }, () => {

      })
  }
  onTaskCountClick(type = '', event = {}) {
    this.showTaskCountPopup = false;
    this.selectedTaskType = type;
    this.clickEventData = event;
    let taskTp = this.selectedTaskType === this.taskType.OTHERS ? this.taskType.OTHERS + 'Cnt' :
      (this.selectedTaskType === this.taskType.ENQUIRY ? 'EnquiryCnt' : this.selectedTaskType + 'sCnt');
    if (this.objEntityData[taskTp] || type === this.taskType.ACCOUNTSLIST) {
      if (type === this.taskType.ACCOUNTSLIST) {
        this.showAccountCountPopup = !this.showAccountCountPopup;
      } else {
        this.showTaskCountPopup = true;
      }
      let screenX = event['clientX'];
      let windowWidth = window.innerWidth;
      let center = windowWidth / 2;
      if (screenX > center) {
        this.positionClass = 'rightContent'
      }
      else {
        this.positionClass = 'leftContent'
      }
    }
  }
  viewAssocDetails(data, entityType) {
    this.ctlsDashboardCustomerService.setViewAccountDetails({ data: {...data}, type: entityType });
  }
  checkIfCntNotEmpty() {
    let taskTp = this.selectedTaskType === this.taskType.OTHERS ? 'Other' : this.selectedTaskType;
    if (this.objEntityData[taskTp + 'sCnt']) {
      return true;
    }
    return false;
  }
  
  setDummyData() {
    this.taskDetails = [
      {
        "TaskID": "CV000120230907",
        "SurveyID": 0,
        "Task": "",
        "TaskPriority": "",
        "ContactName": "Arya",
        "AssigneeName": "Andrea Gagna",
        "BA": "CT",
        "FAM": "ESJ",
        "DIV": "AIA,CTS,CTV,GAP,IAT,OFA,QAD,PAD",
        "CreatedDate": "07-Sep-2023",
        "ResolvedDate": null,
        "ClosedDate": null,
        "Category": "",
        "NPS": "0",
        "SurveyType": "COMPLIMENT",
        "SubType": "CLOSED",
        "CTLSTaskListID": 208,
        "Status": "Closed",
        "RecievedBy": "Stefaan Vertriest",
        "RecievedDate": "07-Sep-2023",
        "VOCtype": "Compliment",
        "RecievedMode": "Email",
        "Description": "comment"
      },
      {
        "TaskID": "CV000220230904",
        "SurveyID": 0,
        "Task": "",
        "TaskPriority": "",
        "ContactName": "Amritha AP",
        "AssigneeName": null,
        "BA": "CT",
        "FAM": "ESJ",
        "DIV": "CTS",
        "CreatedDate": "04-Sep-2023",
        "ResolvedDate": null,
        "ClosedDate": null,
        "Category": "",
        "NPS": "0",
        "SurveyType": "ENQUIRY",
        "SubType": "CLOSED",
        "CTLSTaskListID": 206,
        "Status": "Closed",
        "RecievedBy": "me 2",
        "RecievedDate": "04-Sep-2023",
        "VOCtype": "Enquiry",
        "RecievedMode": "Email",
        "Description": "un assigned date check"
      },
      {
        "TaskID": "CV000120230904",
        "SurveyID": 0,
        "Task": "",
        "TaskPriority": "",
        "ContactName": "Amritha AP",
        "AssigneeName": "Adam Ann",
        "BA": "CT",
        "FAM": "ESJ",
        "DIV": "CTS",
        "CreatedDate": "04-Sep-2023",
        "ResolvedDate": null,
        "ClosedDate": null,
        "Category": "",
        "NPS": "0",
        "SurveyType": "ENQUIRY",
        "SubType": "CREATED",
        "CTLSTaskListID": 205,
        "Status": "Open",
        "RecievedBy": "James Skinner",
        "RecievedDate": "02-Sep-2023",
        "VOCtype": "Enquiry",
        "RecievedMode": "Email",
        "Description": "date check"
      },
      {
        "TaskID": "CV000320230823",
        "SurveyID": 0,
        "Task": "",
        "TaskPriority": "",
        "ContactName": "Amritha AP",
        "AssigneeName": null,
        "BA": "CT",
        "FAM": "ESJ",
        "DIV": "CTS",
        "CreatedDate": "23-Aug-2023",
        "ResolvedDate": null,
        "ClosedDate": null,
        "Category": "",
        "NPS": "0",
        "SurveyType": "COMPLAINT",
        "SubType": "CREATED",
        "CTLSTaskListID": 201,
        "Status": "Open",
        "RecievedBy": "Arya KS Menon",
        "RecievedDate": "23-Aug-2023",
        "VOCtype": "Complaint",
        "RecievedMode": "Email",
        "Description": "jyvThe Basic Format of a 250-Word Essay – All essays consist of the same three parts: an introduction with a thesis, a body paragraph or body paragraphs that support the thesis, and a concluding paragraph that summarizes the overall essay. I..\nThe Basic Format of a 250-Word Essay – All essays consist of the same three parts: an introduction with a thesis, a body paragraph or body paragraphs that support the thesis, and a concluding paragraph that summarizes the overall essay. I..The Basic Format of a 250-Word Essay – All essays consist of the same three parts: an introduction with a thesis, a body paragraph or body paragraphs that support the thesis, and a concluding paragraph that summarizes the overall essay. I..The Basic Format of a 250-Word Essay – All essays consist of the same three parts: an introduction with a thesis, a bThe Basic Format of a 250-Word Essay – All essays consist of the same three parts: an introduction with a thesis, a body paragraph or body paragraphs that support the thesis, and a concluding paragraph that summarizes the overall essay. I..ody paragraph or body paragraphs that support the thesis, and a concluding paragraph that summarizes the overall essay. I.."
      },
      {
        "TaskID": "CV000220230823",
        "SurveyID": 0,
        "Task": "",
        "TaskPriority": "",
        "ContactName": "Amritha AP",
        "AssigneeName": "Adam Ann",
        "BA": "CT",
        "FAM": "ESJ",
        "DIV": "CTS",
        "CreatedDate": "23-Aug-2023",
        "ResolvedDate": null,
        "ClosedDate": null,
        "Category": "",
        "NPS": "0",
        "SurveyType": "ENQUIRY",
        "SubType": "CREATED",
        "CTLSTaskListID": 200,
        "Status": "Open",
        "RecievedBy": "Brian Barnes",
        "RecievedDate": "23-Aug-2023",
        "VOCtype": "Enquiry",
        "RecievedMode": "Email",
        "Description": "comment"
      },
      {
        "TaskID": "CV000120230823",
        "SurveyID": 0,
        "Task": "",
        "TaskPriority": "",
        "ContactName": "Amritha AP",
        "AssigneeName": "Arya Champion",
        "BA": "CT",
        "FAM": "ESJ",
        "DIV": "CTS",
        "CreatedDate": "23-Aug-2023",
        "ResolvedDate": null,
        "ClosedDate": null,
        "Category": "",
        "NPS": "0",
        "SurveyType": "COMPLIMENT",
        "SubType": "CREATED",
        "CTLSTaskListID": 199,
        "Status": "Open",
        "RecievedBy": "comment added",
        "RecievedDate": "17-Aug-2023",
        "VOCtype": "Compliment",
        "RecievedMode": "Email",
        "Description": "comment added"
      },
      {
        "TaskID": "CV000520230810",
        "SurveyID": 0,
        "Task": "",
        "TaskPriority": "",
        "ContactName": "Amritha AP",
        "AssigneeName": "Amritha P",
        "BA": "CT",
        "FAM": "ESJ",
        "DIV": "CTS",
        "CreatedDate": "10-Aug-2023",
        "ResolvedDate": null,
        "ClosedDate": null,
        "Category": "",
        "NPS": "0",
        "SurveyType": "OTHERS",
        "SubType": "CREATED",
        "CTLSTaskListID": 186,
        "Status": "Open",
        "RecievedBy": "jftvu",
        "RecievedDate": "08-Aug-2023",
        "VOCtype": "Update",
        "RecievedMode": "Phone",
        "Description": "fukuv"
      },
      {
        "TaskID": "CV000420230810",
        "SurveyID": 0,
        "Task": "",
        "TaskPriority": "",
        "ContactName": "Amritha AP",
        "AssigneeName": "Amritha P",
        "BA": "CT",
        "FAM": "ESJ",
        "DIV": "CTS",
        "CreatedDate": "10-Aug-2023",
        "ResolvedDate": null,
        "ClosedDate": "24-Aug-2023",
        "Category": "",
        "NPS": "0",
        "SurveyType": "ENQUIRY",
        "SubType": "CLOSED",
        "CTLSTaskListID": 185,
        "Status": "Closed",
        "RecievedBy": "wdweerf",
        "RecievedDate": "10-Aug-2023",
        "VOCtype": "Enquiry",
        "RecievedMode": "In person",
        "Description": "ffrerfer"
      },
      {
        "TaskID": "CV000320230810",
        "SurveyID": 0,
        "Task": "",
        "TaskPriority": "",
        "ContactName": "Amritha AP",
        "AssigneeName": "Alain Lefranc",
        "BA": "CT",
        "FAM": "ESJ",
        "DIV": "CTS",
        "CreatedDate": "10-Aug-2023",
        "ResolvedDate": null,
        "ClosedDate": null,
        "Category": "",
        "NPS": "0",
        "SurveyType": "OTHERS",
        "SubType": "CLOSED",
        "CTLSTaskListID": 184,
        "Status": "Closed",
        "RecievedBy": "avdvd",
        "RecievedDate": "10-Aug-2023",
        "VOCtype": "Others",
        "RecievedMode": "Others (acvamhfajcvasc)",
        "Description": "ZXCddc"
      },
      {
        "TaskID": "CV000220230810",
        "SurveyID": 0,
        "Task": "",
        "TaskPriority": "",
        "ContactName": "Amritha AP",
        "AssigneeName": null,
        "BA": "CT",
        "FAM": "ESJ",
        "DIV": "CTS",
        "CreatedDate": "10-Aug-2023",
        "ResolvedDate": null,
        "ClosedDate": null,
        "Category": "",
        "NPS": "0",
        "SurveyType": "OTHERS",
        "SubType": "CREATED",
        "CTLSTaskListID": 183,
        "Status": "Open",
        "RecievedBy": "ewd",
        "RecievedDate": "02-Aug-2023",
        "VOCtype": "Others",
        "RecievedMode": "Email",
        "Description": "fsfs"
      },
      {
        "TaskID": "CV000120230810",
        "SurveyID": 0,
        "Task": "",
        "TaskPriority": "",
        "ContactName": "Amritha AP",
        "AssigneeName": "Adam Ann",
        "BA": "CT",
        "FAM": "ESJ",
        "DIV": "CTS",
        "CreatedDate": "10-Aug-2023",
        "ResolvedDate": null,
        "ClosedDate": "13-Sep-2023",
        "Category": "",
        "NPS": "0",
        "SurveyType": "COMPLAINT",
        "SubType": "CLOSED",
        "CTLSTaskListID": 182,
        "Status": "Closed",
        "RecievedBy": "yyy",
        "RecievedDate": "03-Aug-2023",
        "VOCtype": "Complaint",
        "RecievedMode": "In person",
        "Description": "t"
      },
      {
        "TaskID": "CV000220230803",
        "SurveyID": 0,
        "Task": "",
        "TaskPriority": "",
        "ContactName": "Amritha AP",
        "AssigneeName": null,
        "BA": "CT",
        "FAM": "ESJ",
        "DIV": "CTS",
        "CreatedDate": "03-Aug-2023",
        "ResolvedDate": null,
        "ClosedDate": null,
        "Category": "",
        "NPS": "0",
        "SurveyType": "COMPLIMENT",
        "SubType": "CREATED",
        "CTLSTaskListID": 177,
        "Status": "Open",
        "RecievedBy": "Amritha P",
        "RecievedDate": "01-Aug-2023",
        "VOCtype": "Compliment",
        "RecievedMode": "In person",
        "Description": "Desc"
      }
    ];
    this.surveyDetails = [
      {
        "SurveyResponseID": 0,
        "SurveyID": 20842776,
        "SurveyName": "Transactional - P",
        "ContactName": "François",
        "EmailID": "Thinkpalm_francois.breniere@a-f.fr",
        "BA": "CT",
        "FAM": "FRB",
        "DIV": "CTS",
        "Brand": null,
        "OrderNo": "404652124",
        "Disposition": "No Response",
        "SurveyType": "TRANS",
        "SubType": "SENT",
        "SentOn": "07-Oct-2022",
        "Instance": null,
        "FeedbackSubmitDate": null,
        "NPS": null
      },
      {
        "SurveyResponseID": 0,
        "SurveyID": 20847823,
        "SurveyName": "Transactional - S",
        "ContactName": "Waldo",
        "EmailID": "Thinkpalm_waldo@obsideo-consulting.com",
        "BA": "CT",
        "FAM": "ZAA",
        "DIV": "OFA",
        "Brand": null,
        "OrderNo": "15778027",
        "Disposition": "Bounce Back",
        "SurveyType": "TRANS",
        "SubType": "SENT",
        "SentOn": "14-Oct-2022",
        "Instance": null,
        "FeedbackSubmitDate": null,
        "NPS": null
      }
    ]
    this.objEntityData = {
        "RL": 1,
        "PromotersCnt": 1,
        "NeutralsCnt": 1,
        "DetractorsCnt": 1,
        "ComplaintsCnt": 1,
        "ComplimentsCnt": 2,
        "EnquiryCnt": 0,
        "OthersCnt": 0,
        "TotalCnt": 10,
        "PromotersPerc": 0,
        "NeutralsPerc": 0,
        "DetractorsPerc": 20,
        "ComplaintsPerc": 20,
        "ComplimentsPerc": 10,
        "OthersPerc": 50,
        "PromotersComplimentsCnt": 1,
        "DetractorComplaintsCnt": 2,
        "NeutralsOthersCnt": 4,
        "PromotersComplimentPerc": 10,
        "NeutralsOtherPerc": 50,
        "DetractorComplaintsPerc": 40,
        "City": "Gelnhausen",
        "Address": "Am Galgenfeld 11"
    };
    
  }
}