import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeatMapComponent } from '../../../../Modules/ctls/ctls-shared/heat-map/heat-map.component';
import { HeatMapDetailsComponent } from '../../../../Modules/ctls/ctls-shared/heat-map-details/heat-map-details.component';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { CtlsSpinnerModule } from '../../ctls-shared/ctls-components/ctls-spinner/ctls-spinner.module';
import { CtlsRulePopoverModule } from '../../ctls-pages/ctls-ruleset/presentation/ctls-rule-popover/ctls-rule-popover.module';
import { CtlsSpinnerService } from '../../ctls-shared/ctls-components/ctls-spinner/ctls-spinner.service';
import { HeatMapDetailsPopupComponent } from '../heat-map-details-popup/heat-map-details-popup.component';
import { HeatMapDetailsListComponent } from '../heat-map-details-list/heat-map-details-list.component';
import { CtlsViewSurveyDetailsComponent } from '../../ctls-pages/ctls-task-listing/presentation/ctls-view-survey-details/ctls-view-survey-details.component';
import { CtlsDashboardCustomerService } from '../ctls-services';
import { ReactiveFormsModule } from '@angular/forms';
import { CtlsSharedModule } from '../ctls-shared.module';
import { CtlsTaskListingModule } from '../../ctls-pages/ctls-task-listing/ctls-task-listing.module';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    CtlsSpinnerModule,
    CtlsRulePopoverModule,
    TableModule,
    TooltipModule,
    CtlsSharedModule,
    CtlsTaskListingModule
  ],
  providers: [CtlsSpinnerService, CtlsDashboardCustomerService],
  declarations: [
    HeatMapComponent,
    HeatMapDetailsComponent,
    HeatMapDetailsPopupComponent,
    HeatMapDetailsListComponent
  ],
  exports: [
    HeatMapComponent,
    HeatMapDetailsComponent,
    HeatMapDetailsPopupComponent,
    HeatMapDetailsListComponent
  ],
  entryComponents: [
    HeatMapDetailsPopupComponent,
  ]
})
export class HeatMapModule { }
