// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseUrl: 'https://apiuat.cemfalcon.com/',
  baseUrlNode: 'https://apiuat.cemfalcon.com:3000/',
  dragDropTimeOut: 5000,
  CustomerSupportEmailID: 'ACFalcon-admin-prod@thinkpalm.com',
  AccessDeniedUrl: 'error/accessdenied',
  Field:{
    //OpCountryId:"CountryID"
    OpCountryId:"FAMOperationalCountryID"

  },
  websocket:{
    hostURL:'wss://apiuat.cemfalcon.com:3000'
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZmFsY29udWF0IiwiYSI6ImNseGN3MnZ2cjBtMTMyanExZG9sb3h5cncifQ.h42eYcli6C3ZdCSA7KDOpw' // UAT/QA access token goes here
    // accessToken: 'pk.eyJ1IjoiZmFsY29ucHJvZGFkbWluIiwiYSI6ImNscDgxMm56NzIyb3oycHF1NXZvbWJ6Y3IifQ.OZ_fMHnZXV7FTVU8GFxlkg' // Prod access token goes here
  },
 // baseimageurl:'http://localhost:4200/assets/FalconImages',
  imagepath:'https://uatcemfalcon.azureedge.net/assets/images/',
  assetpath:'https://uatcemfalcon.azureedge.net/assets/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
