import { Component, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DialogService } from 'primeng/api';
import * as cloneDeep from 'lodash/cloneDeep';
import { ExportConfigModel, WidgetExportModel } from './model/widget-exportmodel.model';
import { ExportChartsService } from '../../services/export-charts.service';
import { filter } from 'rxjs/operators';
import { ExportChartModel } from '../../models/export-chart.model';
import { WIDGET_CODES } from '../../enums/export-state-enum';
import { Subscription } from 'rxjs';
import { FilterService } from '../../services/filter.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-widget-export',
  templateUrl: './widget-export.component.html',
  styleUrls: ['./widget-export.component.scss']
})
export class WidgetExportComponent implements OnInit, OnChanges, OnDestroy {
  private imagepath = environment.imagepath;
  @Output() widgetToDownload: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() blockUi: boolean = false;

  exportconfiguration: ExportConfigModel = new ExportConfigModel();
  boolViewSelectorComponentLoader: boolean = false;
  msgs: any = [];
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  // showRightMenuDiv: boolean = false;
  isCenterChecked: boolean = true;
  isDDChecked: boolean = false;
  selectedValue: string = 'val1';

  showDownloadPanel: boolean = false;
  exportWidgetList: string [] = [];
  readyCharts: { value: string, label: string }[] = [];

  private globFilterSub: Subscription;
  private widgetLeftLocationSubscription: Subscription;
  private widgetNpsLocationSubscription: Subscription;
  private widgetCenterLocationSubscription: Subscription;
  private widgetDDLocationSubscription: Subscription;

  isNPSExpandeble: boolean;
  isShowLoading = false;

  constructor(
    public router: Router,
    public dialogService: DialogService,
    private sharedService: SharedService,
    private exportChartService: ExportChartsService,
    private globalFilter: FilterService
  ) {
    this.exportconfiguration.createFields(this.exportChartService.getChartsToExport());
    /*  Events to update catagory-wise lists, based on shared service. */
    this.widgetLeftLocationSubscription = this.sharedService.widgetLeftLocationSubject.pipe(filter((x) => Array.isArray(x))).subscribe(widgetOrderlistSubject => {
      this.onViewUpdateActions(cloneDeep(widgetOrderlistSubject));
      // this.castWidgetDetails(cloneDeep(widgetOrderlistSubject));
      // this.clearCheckBoxSelection();
    });
    this.widgetNpsLocationSubscription = this.sharedService.widgetNpsLocationSubject.pipe(filter((x) => Array.isArray(x))).subscribe(widgetOrderlistSubject => {
      this.onViewUpdateActions(cloneDeep(widgetOrderlistSubject));
      // this.castWidgetDetails(cloneDeep(widgetOrderlistSubject));
      // this.clearCheckBoxSelection();
    });
    this.widgetCenterLocationSubscription = this.sharedService.widgetCenterLocationSubject.pipe(filter((x) => Array.isArray(x))).subscribe(widgetOrderlistSubject => {
      this.onViewUpdateActions(cloneDeep(widgetOrderlistSubject));
      // this.castWidgetDetails(cloneDeep(widgetOrderlistSubject));
      if(widgetOrderlistSubject && widgetOrderlistSubject.length > 0)
        this.setDefaultview(widgetOrderlistSubject,'center');
      // this.clearCheckBoxSelection();
    });
    this.widgetDDLocationSubscription = this.sharedService.widgetDDLocationSubject.pipe(filter((x) => Array.isArray(x))).subscribe(widgetOrderlistSubject => {
      this.onViewUpdateActions(cloneDeep(widgetOrderlistSubject));
      if(widgetOrderlistSubject && widgetOrderlistSubject.length > 0)
        this.setDefaultview(widgetOrderlistSubject,'trend');
      // this.castWidgetDetails(cloneDeep(widgetOrderlistSubject));
      // this.exportconfiguration.getExportableObjectNames();
      // this.clearCheckBoxSelection();
    });
    this.globFilterSub = this.globalFilter.globalFilterBehaviousSubject.subscribe(val => {
      // this.exportWidgetList = [];
      this.dropDownPopulation();
      this.clearCheckBoxSelection();
      // this.refreshCheckBoxSelection();
    });
  }

  ngOnInit(): void {
    this.dropDownPopulation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.blockUi) {
      this.exportChartService.showLoadingWhileExportPPT(true);
    } else {
      this.exportChartService.showLoadingWhileExportPPT(false);
    }
  }
  ngOnDestroy(): void {
    this.globFilterSub.unsubscribe();
    this.widgetLeftLocationSubscription.unsubscribe();
    this.widgetNpsLocationSubscription.unsubscribe();
    this.widgetCenterLocationSubscription.unsubscribe();
    this.widgetDDLocationSubscription.unsubscribe();
  }

  /**
   * Enable/Disable checkboxes for widget export selection
   */
  dropDownPopulation() {
    //this.dataArray = this.exportconfiguration.getExportableObjectNames();
    this.downloadPanelMouseEnter()
    let loaded: ExportChartModel[] = this.exportChartService.getLoadedChartList(),
    toExport: string[] = this.exportChartService.getChartsToExport();
    this.readyCharts = [];
    if (Array.isArray(loaded) && loaded.length > 0) {
      for (let i = 0; i < loaded.length; i++) {
        this.readyCharts.push({
          label: loaded[i]['DisplayName']
          , value: loaded[i].ChartName
        });
      }
      console.log(this.readyCharts,'this.readyCharts')
      this.exportconfiguration.getPresentObjects().forEach(item => {
        if(this.readyCharts.map(i => i.value.toLowerCase()).includes(item.widgetCode.toLowerCase()) && toExport.includes(item.widgetCode.toLowerCase())) {
          this.exportconfiguration.getField(item.widgetCode.toLowerCase()).isDisabled = false;
        } else {
          this.exportconfiguration.getField(item.widgetCode.toLowerCase()).isDisabled = true;
        }
      });
    }
  }

  downloadPanelMouseEnter() {
    this.showDownloadPanel = true;
  }
  downloadPanelMouseLeave() {
    this.showDownloadPanel = false;
  }


  /**
   * Method call for everytime view update behaviour subject is refreshed/updated.
   * Place all method calls inside this hook
   */
  private onViewUpdateActions = (widgetList: any[]) => {
    this.castWidgetDetails(widgetList);
    // this.clearCheckBoxSelection();
    this.refreshCheckBoxSelection();
  }

  /**
   * Utility function which search for visible component
   * @param widgetCode widget code
   * @param list input list
   */
  searchRecordFromList(widgetCode, list = []) {
    if (list.length <= 0) {
      return { IsApplicable: false }
    } else {
      return list.filter(item => { return (item.WidgetCode == widgetCode) })[0];
    }
  }
  callErrorMsg(type, summary = '', detail = '') {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    window.scrollTo(0, 0);
    setTimeout(function () {
      document.getElementById('ClearMsg').click();
    }, this.MessageTimeOut);
  }

  /**
   * Default view decision between storyboard & chart
   * @param widgetCenterOrderList Center widget list
   */
  setDefaultview(widgetCenterOrderList: any[], type) {
    
    if(type == 'center') {
      this.isCenterChecked = widgetCenterOrderList.every(item => {
        return item.IsDefaultView == true;
      });
    } else {
      this.isCenterChecked = false
    }
    if(type == 'trend') {
      this.isDDChecked = widgetCenterOrderList.every(item => {
        return item.IsDefaultView == true;
      });
    } else {
      this.isDDChecked = false
    }
    
    !this.isCenterChecked ? !this.isDDChecked ? this.isDDChecked = false : this.isDDChecked = true : this.isDDChecked = false;
  }

  /**
   * Create/Update widget property details
   * @param widgetList list of widget data from BehaviourSubject
   */
  castWidgetDetails(widgetList: any[]) {
    let widgetRep: any[] = widgetList.map(i => {
      let isDisabled: boolean = !i.IsVisible;
      // next 3 lines specific to nps by touchpoint
      if(['l', 'p', 'r', 's', 'u','ru','err'].includes(i.WidgetCode.toLowerCase())) {
        isDisabled = !this.sharedService.isNpstpOpen;
        this.isNPSExpandeble = this.sharedService.isNpstpOpen;
      }

      // return a object
      return {
        isVisible: i.IsVisible,
        widgetCode: i.WidgetCode.toLowerCase(),
        isWidgetExportable: false,
        isApplicable: i.IsApplicable,
        isDisabled: isDisabled
      };
    })
    widgetRep.forEach(x => {
      this.exportconfiguration.createSetField(x.widgetCode.toLowerCase(), cloneDeep(x));
    });
  }

  downloadPPT() {
    // do not take any action if nothing is selected. Disable download btn
    if(this.exportWidgetList.length <= 0 ) {
      return;
    }
    this.showDownloadPanel = false;

    // next X lines es sp specific
    if(this.exportWidgetList.includes('s')) {
      this.exportWidgetList.push('es');
      this.exportWidgetList.push('sp');
    }
    if(this.exportWidgetList.includes('poj')) {
      this.exportWidgetList.push('com');
      this.exportWidgetList.push('aoc');
      this.exportWidgetList.push('ams');
      this.exportWidgetList.push('rel');
      this.exportWidgetList.push('ini');
      this.exportWidgetList.push('cmc');
    }
    if(this.exportWidgetList.includes('w')) {
      this.exportWidgetList.push('wc');
      this.exportWidgetList.push('wnc');
    }
    if(this.exportWidgetList.includes('f')) {
      this.exportWidgetList.push('fc');
      this.exportWidgetList.push('fnc');
    }
    this.widgetToDownload.emit(JSON.parse(JSON.stringify(this.exportWidgetList)));
    this.exportWidgetList = [];
  }
  checkboxCheck(event?) {
    this.exportChartService.setExportList(this.exportWidgetList);
  }
  clearCheckBoxSelection() {
    this.exportWidgetList = [];
  }
  private refreshCheckBoxSelection = () => {
    const readyWidgets = this.exportconfiguration.getExporReadyWidgetNames();
    let removalIndices: number[] = [];
    for (let i = 0; i < this.exportWidgetList.length; i++) {
      let index: number = readyWidgets.indexOf(this.exportWidgetList[i].toLowerCase());
      if(index < 0) {
        removalIndices.push(i);
      }
    }
    let spliceOffset: number = 0;
    for (let i = 0; i < removalIndices.length; i++) {
      let item:number = -1;
      if(removalIndices[i] <= this.exportWidgetList.length-1) {
        item = removalIndices[i];
      } else {
        item = removalIndices[i] - spliceOffset;
      }
      this.exportWidgetList.splice(item, 1);
      spliceOffset++;
      this.exportWidgetList = this.exportWidgetList.map(i => i.toLowerCase());
    }
  }
}
