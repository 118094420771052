import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { SurveySubCategory } from 'src/app/shared/constants/survey.sub.category';
import { LostbusinessCustomerService } from '../service/lostbusiness-customer.service';
import { Observable } from 'rxjs';
import { APIResponses } from 'src/app/Modules/relationship-survey/models/classes/api-responses.class';
import { ParsedTrendDataResponse } from '../lostbusiness-customer/models/classes/chart-data-models.class';

@Injectable({
  providedIn: 'root'
})
export class DashboardWidgetService {

  private serviceUrl = this.sharedService.configuration.baseUrl;

  response: any;

  constructor(private http: HttpClient, private filterService: FilterService, private sharedService: SharedService, private lostbusinessCustomerService: LostbusinessCustomerService) { }

  // getUserFilter() {
  //   return this.http.get(this.serviceUrl + "api/Dashboard/GetUserFilter?" + Math.random())
  //     .toPromise()
  //     .then((res: any) => {
  //       if (res != null && res.Status == "Success" && res.Response != null) {
  //         var filterObjectString = localStorage.getItem("globalFilterObject");
  //         if (filterObjectString == null) {
  //           this.filterService.mapFilterObject(res.Response, true);
  //           localStorage.setItem("globalFilterObject", JSON.stringify(res.Response));
  //         }
  //         else {
  //           var filterObjectStringDate = localStorage.getItem("periodFilterObject");
  //           if(filterObjectStringDate!=null){
  //             var dateFilters = JSON.parse(filterObjectStringDate)
  //             this.filterService.filterObj.StartDate = dateFilters.StartDate;
  //             this.filterService.filterObj.EndDate = dateFilters.EndDate;
  //             this.filterService.filterObj.PeriodType = dateFilters.PeriodType;
  //           }
  //           this.filterService.mapFilterObject(JSON.parse(filterObjectString), false);
  //         }
  //       }
  //       return res
  //     });
  // }


  getLoyaltyShiftDetails(refresh = false) {
    let req: any = this.filterService.filterObj;
    if (refresh) {
      req = {...this.filterService.filterObj, IsRefresh: refresh};
    }
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetLoyaltyShiftDetails', req)
      .toPromise()
      .then((res: any) => {
        return res
      });
  }
  getNpsAndAvgRating(refresh = false) {
    let req: any = this.filterService.filterObj;
    if (refresh) {
      req = {...this.filterService.filterObj, IsRefresh: refresh};
    }
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetNPS', req)
      .toPromise()
      .then((res: any) => {
        this.response = res.Response;
        return this.response;
      }).catch(e => {

      });
  }

  getNnsAndAvgRating(refresh = false) {
    let req: any = this.filterService.filterObj;
    if (refresh) {
      req = {...this.filterService.filterObj};
    }
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetSentimentalAnalysisGaugeChart', req)
      .toPromise()
      .then((res: any) => {
        this.response = res;
        return this.response;
      }).catch(e => {

      });
  }

  getNpsRating() {
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetNPSRating', this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        this.response = res.Response;
        return this.response;
      }).catch(e => {

      });
  }

  getResponseRate(refresh = false) {
    let req: any = this.filterService.filterObj;
    if (refresh) {
      req = {...this.filterService.filterObj, IsRefresh: refresh};
    }
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetResponseRate', req)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  //Need to change with
  getInvitations() {
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetResponseRate', this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res.Response;
      });
  }

  getNpsByTouchPoint(refresh = false) {
    let req: any = this.filterService.filterObj;
    if (refresh) {
      req = {...this.filterService.filterObj, IsRefresh: refresh};
    }
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetNPSByTouchpoint', req)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  getCustomerFeedback(refresh = false) {
    let req: any = this.filterService.filterObj;
    if (refresh) {
      req = {...this.filterService.filterObj, IsRefresh: refresh};
    }
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetCustomerFeedback', req)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  getTop5customers(refresh = false) {
    let req: any = this.filterService.filterObj;
    if (refresh) {
      req = {...this.filterService.filterObj, IsRefresh: refresh};
    }
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetTopCustomerByTransaction', req)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getQuestionByCategory() {
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetTotalResponseSummaryQuestionCategory', this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getQuestionByTouchPoint() {
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetTotalResponseSummaryByTouchPoint', this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getQuestionByCatId(id) {
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetTotalRespSummaryByTPQuestionCategory?questionResponseCategoryId=' + id, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getQuestionsByTP(id, pageNo = 1, pageSize = 10) { //touchPointID=6
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetTotalRespSummaryByTPQuestions?touchPointID=' + id
      + '&pageNo=' + pageNo
      + '&pageSize=' + pageSize, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getStoryBoard(refresh = false) {
    let req: any = this.filterService.filterObj;
    if (refresh) {
      req = {...this.filterService.filterObj, IsRefresh: refresh};
    }
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetStoryBoard', req)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getStoryBoardDetails(id) {
    return this.http.post(this.serviceUrl + 'api/Dashboard/storyboard/category/' + id, this.filterService.filterObj)
    .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getCustomers(topByTransactionCount, refresh = false) {
    let req: any = this.filterService.filterObj;
    if (refresh) {
      req = {...this.filterService.filterObj, IsRefresh: refresh};
    }
    //return this.http.post(this.serviceUrl + 'api/Dashboard/GetAllCustomers?TopByTransactionCount=50', this.filterService.filterObj)
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetAllCustomers?TopByTransactionCount=' + topByTransactionCount, req)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getCustomerDetailsById(CustomerName, refresh = false) {
    let req: any = this.filterService.filterObj;
    if (refresh) {
      req = {...this.filterService.filterObj, IsRefresh: refresh};
    }
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetInfluencerDetailByCustomer?CustomerName=' + CustomerName, req)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  getInfluencerContacts(params, refresh = false) {
    let req: any = this.filterService.filterObj;
    if (refresh) {
      req = {...this.filterService.filterObj, IsRefresh: refresh};
    }
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetInfluencerContacts?CustomerName=' + params.CustomerName
      + '&InfluencerType=' + params.InfluencerType
      + '&PNDFlag=' + params.PNDFlag
      + '&PageSize=' + params.PageSize
      + '&PageIndex=' + params.PageIndex
      , req)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  getInfluenceAllCustomersSearch(params) {
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetInfluenceAllCustomersSearch?FilterSearch=' + params
    , this.filterService.filterObj);
  }

  getLoyaltyShiftContactDetails(sortField, sortType, pndFrom, pndTo, pageNo, pageSize) {
    var url = this.serviceUrl + 'api/Dashboard/GetLoyaltyShiftContactDetails?sortField=' + sortField + '&sortType=' + sortType + '&pndFrom=' + pndFrom + '&pndTo=' + pndTo + '&pageNo=' + pageNo + '&pageSize=' + pageSize;
    return this.http.post(url, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  getGetWidgetLocation() {
    return this.http.get(this.serviceUrl + 'api/Dashboard/GetWidgetLocation?' + Date.now() + Math.random())
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }

  postAddUpdateWidgetLocation(widjetorderListToSend) {
    return this.http.post(this.serviceUrl + 'api/Dashboard/AddUpdateWidgetLocation', widjetorderListToSend)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getNPSByCountries(filterObject) {
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetNPSByCountries/Distributor', filterObject)
      .toPromise()
      .then((res: any) => {
        return res;
      }).catch(e => {
        console.log('error')
        console.log(e)
      });
  }
  GetNPSByStates(filterObject) {
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetNPSByStates/Distributor', filterObject)
      .toPromise()
      .then((res: any) => {
        return res;
      }).catch(e => {
        console.log('error')
        console.log(e)
      });
  }

  getLostBusinessDetails(refresh = false) {
    var lostFilterObj: any = {
      'StartDate': "",
      'EndDate': "",
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
    if (refresh) {
      lostFilterObj.IsRefresh = refresh;
    }
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetLostCustomerDetail/Transactional', lostFilterObj)
      .toPromise()
      .then((res: any) => {
        return res
      });
  }

  getLostCustDropdown() {
    //console.log(this.globalfilterService.filterObj,"gb");
    var lostFilterObj = {
      'StartDate': "",
      'EndDate': "",
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo' : this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy' : this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetAllLostCustomer', lostFilterObj)
      .toPromise()
      .then((res: any) => {
        return res
      });
  }
  getLostCustDropdownData() {
    var lostFilterObj = {
      'StartDate': "",
      'EndDate': "",
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy
    }
    return this.http.post(this.serviceUrl + 'api/Dashboard/GetAllLostCustomer', lostFilterObj);
  }

  public getLostCustomeerTrendData(viewByYear = false): Observable<APIResponses<ParsedTrendDataResponse>> {
    return this.http.post<APIResponses<ParsedTrendDataResponse>>(`${this.serviceUrl}api/Dashboard/LostCustomer/TrendChart`, this.getLbFilterValue(viewByYear));
  }

  private getLbFilterValue(viewByYear = false): any {
    return {
      'StartDate': "",
      'EndDate': "",
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'viewByYear' : viewByYear
    };
  }
}
