import { Component } from '@angular/core';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-lockout',
  templateUrl: './lockout.component.html',
  styleUrls: ['./lockout.component.scss']
})
export class LockoutComponent {
  title: string = "Locked out";
  message: string = "This account has been locked out, please try again later.";
  constructor(
    private storageService: StorageService
  ) {
  }
  ngOnInit(): void {
    this.storageService.clearAllExceptRememberMe();
  }
}

