import { SharedService } from './shared/services/shared.service';
import { DialogService } from 'primeng/api';
import { Component, ElementRef, OnInit, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { SurveyService } from "./components/surveys/services/survey.service";
import { SessionStorageService } from "./components/surveys/services/session-storage.service";
import { HeaderService } from "src/app/shared/services/header.service";
import { Router, NavigationEnd, ActivatedRoute, Event, ActivationStart } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { UserFeedbackComponent } from './shared/components/user-feedback/user-feedback.component';
import { Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { PermissionsService } from './app.service';
import { CtlsCommonFilterService } from './Modules/ctls/ctls-shared/ctls-services/ctls-common-filter.service';
import { CtlsAddRuleSetService } from './Modules/ctls/ctls-shared/ctls-services/ctls-add-rule-set.service';
import { CtlsDashboardFilterService } from './Modules/ctls/ctls-shared/ctls-services/ctls-dashboard-filter.service';
import { CtlsFilterHeaderService } from './Modules/ctls/ctls-shared/ctls-services';
import { CtlsClosureTemplateListingService } from 'src/app/Modules/ctls/ctls-shared/ctls-services';
import { CanDeactivateState } from './Modules/ctls/ctls-shared/ctls-guards/ctls-can-deactivate.guard';
import { CommonFilterService } from 'src/app/shared/services/common-filter.service';
import { HttpCancelService } from './shared/services/http-cancel.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from './oauth2.config';

declare var initFalcon: any;
declare var changeFlag: any;
declare var checkflag: any;
declare var currentFrame: any;
// declare var initFalconBird : any ;
// declare var initFalconBirdFlyOut : any ;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  current_url: string;
  title = 'ClientApp';
  displayName = "Guest";
  currentYear;
  userId: any;
  profileimagurl: any;
  defaultViewUrl: any;
  userProfile = sessionStorage.getItem('userProfile');
  userProfileonLoad: any;
  isHasPermissions: boolean = false;
  isFlyOut: boolean = false;
  defaultViews = null;
  // for flapping wings
  isClose: boolean = false;
  public fromFeedBack: string = "false";
  private hmSlonBirdClickSubject: Subscription;
  destroy$ = new Subject();
  default_view = null;
  defaultUrlFromCookie = null;
  @ViewChild('ctlsEdit') divCtlsEdit: ElementRef;
  canAccess: boolean=false;
  constructor(private elementRef: ElementRef,
    private filterHeaderService: CtlsFilterHeaderService,
    private filterService: CtlsDashboardFilterService,
    private httpCancelService: HttpCancelService,
    private filterCommonService: CtlsCommonFilterService, private renderer: Renderer2,
    private dialogService: DialogService, private sharedService: SharedService, private surveyService: SurveyService, private sessionStorageService: SessionStorageService, private headerService: HeaderService,
    public router: Router, private activatedRoute: ActivatedRoute, private titleService: Title, private appService: PermissionsService,
    private addRuleSetService: CtlsAddRuleSetService,private oauthService: OAuthService,
  ) {
   
    // // if the user clicks the back button, ask the CanDeactivateGuard to defend against this.
    // window.onpopstate = () => CanDeactivateState.defendAgainstBrowserBackButton = true;

    // // Upon successful navigation, ensure that the CanDeactivateGuard no longer defends against back button clicks
    // router.events.pipe(
    //   filter(e => e instanceof NavigationEnd),
    //   tap(() => CanDeactivateState.defendAgainstBrowserBackButton = false)
    // ).subscribe();


    // this.router.events.subscribe((event: Event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.current_url = this.router.url;
    //     console.log("CURRENT_URL", this.current_url)
    //     this.httpCancelService.cancelPendingRequests()
    //   }

    // });
    // this.userProfileonLoad = sessionStorage.getItem('userProfileonLoad');
    // if (this.userProfileonLoad != null) {

    //   var userInfo = JSON.parse(this.userProfileonLoad);

    //   this.displayName = userInfo.displayName;
    //   this.profileimagurl = userInfo.PhotoURL;
    //   if(document.cookie.indexOf("LandingPage")!=-1) {
    //     this.defaultUrlFromCookie = this.getCookie('LandingPage')
    //   }
    //   this.defaultViewUrl =this.defaultUrlFromCookie ? this.defaultUrlFromCookie: userInfo.defaultViewUrl;
    //   console.log(this.defaultViewUrl,'this.defaultViewUrl')
    //   this.headerService.displayName.next(this.displayName);
    //   this.headerService.profileimagurl.next(this.profileimagurl);

    // }
    // else {

    //   this.displayName = this.elementRef.nativeElement.getAttribute('displayName');
    //   this.profileimagurl = this.elementRef.nativeElement.getAttribute('profileimagurl');
    //   this.defaultViewUrl = this.elementRef.nativeElement.getAttribute('defaultViewUrl');
    //   this.headerService.displayName.next(this.displayName);
    //   this.headerService.profileimagurl.next(this.profileimagurl);

    //   const userData = {
    //     'PhotoURL': this.profileimagurl,
    //     'displayName': this.displayName,
    //     'defaultViewUrl': this.defaultViewUrl
    //   };

    //   sessionStorage.setItem('userProfileonLoad', JSON.stringify(userData));
    // }
    // if (this.defaultViewUrl && this.defaultViewUrl !== '') {
    //   this.isHasPermissions = true;
    // }
    // console.log(this.defaultViewUrl,'this.defaultViewUrl')
    // // this.displayName = this.elementRef.nativeElement.getAttribute('displayName');
    // // this.profileimagurl = this.elementRef.nativeElement.getAttribute('profileimagurl');
    // // if (this.userProfile != null) {
    // //   //debugger;
    // //   const userProfileInfo = JSON.parse(this.userProfile);
    // //   this.displayName = userProfileInfo.FirstName + ' ' + userProfileInfo.LastName;
    // //   this.profileimagurl = userProfileInfo.PhotoURL;
    // // } else {
    // //   this.displayName = this.elementRef.nativeElement.getAttribute('displayName');
    // //   this.profileimagurl = this.elementRef.nativeElement.getAttribute('profileimagurl');
    // // }
    // // this.loadScript('dist/assets/OnlineHelp/js/createjs-2015.11.26.min.js');
    // // this.loadScript('dist/assets/OnlineHelp/js/Falcon_wings_flapping_HTML5Canvas.js');
    // // this.loadScript('dist/assets/OnlineHelp/js/Falcon_wings_flapping.js');

    // this.hmSlonBirdClickSubject = this.sharedService.hmSlonBirdClickSubject.subscribe(event => {
    //   if (event !== null) {
    //     this.onHeaderIconClicked();
    //   }
    // });


  

  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  UserAccessGetDetails() {
    if(this.canAccess){
    this.surveyService.UserAccessGetDetails().then(rawData => {
      if (rawData.Status == 'Success') {
        for (let i = 0; i < rawData.Response[0].Preferences.length; i++) {
          if (rawData.Response[0].Preferences[i] == 'CREATE_TRANS_TEMPLATE_INTRO=true') {
            this.sessionStorageService.setSessionData('Preferences', 'CREATE_TRANS_TEMPLATE_INTRO=true');
          }
        }
      }
      else {
        console.error('API failure');
      }
    }).catch(e => {

    });
  }
  }

  UserPermissionsDetails(rawData, urlfromserver) {
    if(this.canAccess){
      if (rawData && rawData.length) {
        let obj = {};
        rawData.map(x => {
          obj[x] = true;
        });
          this.sessionStorageService.setSessionData('Permissions', rawData);
          this.sessionStorageService.setSessionData('PermissionsObj', obj);
          this.sessionStorageService.sessionPermissionSet.next('done');

          //code to redirect based on user preference
          this.userProfileonLoad = sessionStorage.getItem('userProfileonLoad');
          var userInfo = JSON.parse(this.userProfileonLoad);

          if (this.userProfileonLoad != null) {
            if(document.cookie.indexOf("LandingPage")!=-1) {
              this.defaultUrlFromCookie = this.getCookie('LandingPage')
            }
            this.defaultViewUrl =this.defaultUrlFromCookie ? this.defaultUrlFromCookie: userInfo.defaultViewUrl;
          }
          else {
            this.defaultViewUrl = this.elementRef.nativeElement.getAttribute('defaultViewUrl');
          }
          if (!this.defaultViewUrl && this.defaultViewUrl === '') {
            this.checkingIndividualPermissions(urlfromserver, this.sessionStorageService.getSessionData('Permissions'))
          }
          //this.router.navigateByUrl(this.defaultViewUrl);
        }
        else {
        }
    }
  }

  private changeDateFormate(date) {
    var momentDate = moment(date);
    return momentDate.format('DD-MMM-YYYY');
  }

  async ngOnInit() {
    if(window.location.href.includes('logout')) {
      window.sessionStorage.clear();
      localStorage.removeItem('views')
      localStorage.clear();
      //window.location.href = '/home/logout';
      //this.router.navigate(['/logout']);
      this.oauthService.configure(authConfig);
      this.oauthService.logOut();
      console.log('auth service logout');
      localStorage.removeItem('views')
    }
    if(localStorage.getItem('access_token')){
      this.canAccess=true;
     }
     else{
      this.canAccess=false;;
     }
    this.appService.permissionSubject.subscribe((dat: any) => {
      if (dat && dat.GetDetails) {
        var currentUrl = window.location.href;
        var urlfromserver = currentUrl.substring(currentUrl.lastIndexOf("/"), currentUrl.length);
        this.UserPermissionsDetails(dat.GetDetails, urlfromserver);
      }
    });
    if(this.canAccess){
      this.current_url = window.location.pathname;
      console.log("current--",this.current_url)
      this.sharedService.loadConfig();
    this.filterHeaderService
      .getUserFilter(
        null,
        'CTLS_TREND_ANALYSIS'
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        let filterData = res.Response;
        sessionStorage.setItem(
          'CTLS_TREND_ANALYSIS',
          JSON.stringify(filterData)
        );
      });
    let date_range = sessionStorage.getItem(
      'ctlsDashboardPeriodFilterObject'
    );
    let viewsParsed = JSON.parse(localStorage.getItem('views'));
    if(viewsParsed && viewsParsed.length > 0) {
      this.defaultViews = viewsParsed;
    }else {
      await this.headerService
      .getDefaultDashboardView()
      .then((res) => { 
        this.defaultViews = res.Response
        if(this.defaultViews) {
          localStorage.setItem('views',JSON.stringify(this.defaultViews))
        }
      })
    }
    
    this.headerService.GetDefaultScreen().then((res) => {
      this.default_view = res.Response.DefaultView;
      sessionStorage.setItem('default_view',this.default_view);
    })
    if (date_range == null || date_range == undefined) {
      this.filterService.getCTLSFilter()
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          let filterData = res.Response;
          if (filterData !== []) {
            let filter_data = {
              StartDate: filterData.StartDate, EndDate: filterData.EndDate, PeriodType: filterData.PeriodType
            };
            sessionStorage.setItem(
              'ctlsDashboardPeriodFilterObject',
              JSON.stringify(filter_data)
            );
          } else {
            var today = new Date();
            var date = new Date();
            date.setMonth(date.getMonth() - 11);
            var y = date.getFullYear();
            var m = date.getMonth();
            let StartDate = this.changeDateFormate(new Date(y, m, 1));
            let EndDate = this.changeDateFormate(today);
            let date_range = { StartDate: StartDate, EndDate: EndDate, PeriodType: '' };
            sessionStorage.setItem('ctlsDashboardPeriodFilterObject', JSON.stringify(date_range));
          }
        });
    }
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        if (val instanceof NavigationEnd) {
          if (this.router.url.includes('ctls/task-listing/edit')) {
            if (this.isHasPermissions) {
              this.renderer.addClass(this.divCtlsEdit.nativeElement, 'ctls-edit-task-bg-white');
            }
          } else {
            if (this.isHasPermissions) {
              this.renderer.removeClass(this.divCtlsEdit.nativeElement, 'ctls-edit-task-bg-white');
            }
          }

          if (this.router.url.includes('ctls-notification')) {
            /* Redirect to specific notification component */
            let endPoint = this.router.url;
            console.log("Hello welcome!!!", endPoint.substr(endPoint.lastIndexOf('/')));

            //this.GetNotificationsMessage(endPoint.substr(endPoint.lastIndexOf('/') + 1));
            //this.getEmailNotificationMessages(endPoint.substr(endPoint.lastIndexOf('/') + 1));

          }
        } else if(val instanceof ActivationStart) {
          if (val && val.snapshot && val.snapshot.data && val.snapshot.data.title) {
            let screenName = val.snapshot.data.title;
            sessionStorage.setItem('currentScreenName', screenName); 
          }
        }
      });
    this.filterCommonService.setMasterData();
    this.filterCommonService.setUserData();
    this.userProfileonLoad = sessionStorage.getItem('userProfileonLoad');
    if (this.userProfileonLoad != null) {
      var userInfo = JSON.parse(this.userProfileonLoad);
      this.displayName = userInfo.displayName;
      this.profileimagurl = userInfo.PhotoURL;
      if(document.cookie.indexOf("LandingPage")!=-1) {
        this.defaultUrlFromCookie = this.getCookie('LandingPage')
      }
      this.defaultViewUrl =this.defaultUrlFromCookie ? this.defaultUrlFromCookie: userInfo.defaultViewUrl;
      this.headerService.displayName.next(this.displayName);
      this.headerService.profileimagurl.next(this.profileimagurl);
    }
    else {
      // this.displayName = this.elementRef.nativeElement.getAttribute('displayName');
      // this.profileimagurl = this.elementRef.nativeElement.getAttribute('profileimagurl');
      // this.defaultViewUrl = this.elementRef.nativeElement.getAttribute('defaultViewUrl');
      const result=await this.sharedService.getUserCookie();
    this.displayName = result.displayName;
    this.profileimagurl = result.profileImagUrl;
    this.defaultViewUrl = result.defaultViewUrl;
    this.userId = result.UserId;
      this.headerService.displayName.next(this.displayName);
      this.headerService.profileimagurl.next(this.profileimagurl);
      const userData = {
        'PhotoURL': this.profileimagurl,
        'displayName': this.displayName,
        'defaultViewUrl': this.defaultViewUrl,
        'userId': this.userId
      };
      sessionStorage.setItem('userProfileonLoad', JSON.stringify(userData));
    }

    //navigate to default landing page based on user setting.
    var currentUrl = window.location.href;
    var urlfromserver = currentUrl.substring(currentUrl.lastIndexOf("/"), currentUrl.length);
    if (sessionStorage.getItem('Permissions') === null || this.sessionStorageService.isSessionStorageNull()) {
      // this.UserPermissionsDetails(urlfromserver);
      if (!this.defaultViewUrl) {
        this.checkingIndividualPermissions(urlfromserver, this.sessionStorageService.getSessionData('Permissions'))
      }
    } else {
      this.sessionStorageService.sessionPermissionSet.next('done');
      if (!this.defaultViewUrl) {
        this.checkingIndividualPermissions(urlfromserver, this.sessionStorageService.getSessionData('Permissions'))
      }
    }
    this.currentYear = new Date().getFullYear();
    if (this.defaultViewUrl && this.defaultViewUrl !== '') {
      this.isHasPermissions = true;
      var url = "";
      this.headerService.LandingPageCode = this.defaultViewUrl ? this.defaultViewUrl: 'TD'
      console.log('here landing page ',this.headerService.LandingPageCode)
      if (this.defaultViews && this.defaultViews.length > 0) {
        this.defaultViews.forEach(element => {
          if(element.ScreenKey ===this.headerService.LandingPageCode){
            url= element.ScreenURL
            if(this.default_view && this.headerService.LandingPageCode === 'CTL') {
              let urlToRedirect = this.default_view === 'CTLS_DASHBOARD' ? 'dashboard' : 'trend-analysis';
              url = `/ctls/${urlToRedirect}`;
            }
          }
        });
        console.log(urlfromserver,'urlfromserver')
        if (urlfromserver == "" || urlfromserver == "/" || urlfromserver.includes("/signin-oidc") ) {
          console.log('url',url)
          this.router.navigateByUrl(url);
        }
      }  
    }


    const newtitle = this.titleService.getTitle();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['title']) {
            return "Falcon - " + child.snapshot.data['title'];
          } else {
            return newtitle;
          }
        }
        return newtitle;
      })
    ).subscribe((data: string) => {
      this.titleService.setTitle(data);
    }
    );
  }
  else{
    let currentUrlLength =window.location.href.split("/").length;
    if(!this.router.url.includes('start-up') && !this.router.url.includes('signin-oidc')  && currentUrlLength >4 ){
      this.router.navigate(['']);
    }
  }
 
 
    //this.loadFalconBird();
  }
  routeNotificationPage() {
    // this.GetNotificationsMessage();
  }
  getEmailNotificationMessages(emailNotifyId) {
    this.sharedService
      .getEmailNotificationMessages(emailNotifyId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("email notification", data)
        if (!!data) {
          if (data.Status.toUpperCase() == "SUCCESS") {

            this.description(data.Response)

          }
        }
      })
  }
  private GetNotificationsMessage(notification_id) {
    if(this.canAccess){
    this.sharedService
      .getNotificationMessages()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!!data) {
          let objArray = [];
          if (data.Status == "SUCCESS") {

            if (!!data.Response.Task) {
              objArray = [...objArray, ...data.Response.Task]

            }
            if (!!data.Response.Survey) {
              objArray = [...objArray, ...data.Response.Survey]
            }
            if (!!data.Response.Template) {
              objArray = [...objArray, ...data.Response.Template]
            }
            if (!!data.Response.Rule) {
              objArray = [...objArray, ...data.Response.Rule]
            }

            const found = objArray.find(element => element.NotificationId == notification_id);
            console.log("found", objArray, found)
            if (objArray.length) {
              this.description(found);
            } else {
              console.log("Notification - Data Not Found")
            }


          }
        }
      })
    }
  }

  description(item) {
    if(this.canAccess){

    console.log(item, "test");
    /* if (item.EmailType === "Survey") {
    } */
    if (item.EmailType === "Task Assignment") {
      this.router.navigate(['ctls/task-listing/edit'], {
        queryParams: {
          TaskID: item.CTLSTaskListID,
          selectedRadioOption: item.SubCategory === 'TRANS' ? 1 : 2,
        },
        //skipLocationChange: true,
      });

    }
    if (item.EmailType === "Rule") {

      if (item.CTLSRuleID) { // edit rule
        this.router.navigate(['/ctls/setup/ruleset-listing/rule-setup'], {
          queryParams: {
            SubCategory: item.SubCategory === 'TRANS' ? 1 : 2,
            Operation: 'EDIT_RULE',
            data: JSON.stringify(item)
          },
        });
      }

      if (item.CTLSRuleSetID) { // edit rule set
        const selectedData = {
          selectedRuleSetForEdit: item,
          selectedRadioOption: item.SubCategory === 'TRANS' ? 1 : 2,
          selectedTabValue: 'ManageRuleSet', //this.tabValue,
          selectedAction: 'EDIT_RULE_SET'
        }
        this.addRuleSetService.changeParamForEdit(selectedData);
        this.addRuleSetService.changeParamForAdd(null);
        this.addRuleSetService.changeParamForClone(null);
        this.router.navigate(['/ctls/setup/ruleset-listing/edit-rule-set'],
          {
            queryParams: {
              SubCategory: item.SubCategory === 'TRANS' ? 1 : 2,
              Operation: 'EDIT_RULE_SET',
            },
            //skipLocationChange: true,
          }
        );
      }


    }
    if (item.TranTypeCategory === "Templete") {
      let rowData = item;
      rowData.editTemplate = true;
      if (!!rowData.BrandID && !!rowData.Brand) {
        let objBrand = {
          BrandID: rowData.BrandID,
          BrandName: rowData.Brand
        }
        rowData.objBrand = JSON.stringify(objBrand);
      }
      rowData.SetDefault = JSON.stringify(rowData.SetDefault);
      rowData.IsGlobal = JSON.stringify(rowData.IsGlobal);
      rowData.SaveAsDraft = JSON.stringify(rowData.SaveAsDraft);
      rowData.CTLSBrandLogosAndHeaderImages = JSON.stringify(rowData.CTLSBrandLogosAndHeaderImages);
      this.router.navigate(['/ctls/setup/task-closure-listing/add-template'], {
        queryParams: rowData,
        //skipLocationChange: true,
      });
      //this.router.navigate(['ctls/setup/task-closure-listing']);

    }
  }
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkingIndividualPermissions(urlfromserver, PERMISSIONS) {
    if(this.canAccess){
    this.isHasPermissions = true;
    let url = "dashboard/home";
    if (this.appService.CheckPermission(PERMISSIONS, 'VIEW_DASHBOARD')) {
      url = "dashboard/home";
      this.headerService.LandingPageCode = 'TD';
    } else if (this.appService.CheckPermission(PERMISSIONS, 'VIEW_FILE_TRACKING_DASHBOARD')) {
      url = "dashboard/file-tracking";
      this.headerService.LandingPageCode = 'FTD';
    } else if (this.appService.CheckPermission(PERMISSIONS, 'VIEW_DATA_ERROR_TRACKING')) {
      url = "dashboard/data-tracking";
      this.headerService.LandingPageCode = 'DTD';
    } else if (this.appService.CheckPermission(PERMISSIONS, 'VIEW_DASHBOARD_DISTRIBUTOR')) {
      url = "Distributor_Survey/dashboard/distributor";
      this.headerService.LandingPageCode = 'DD';
    } else if (this.appService.CheckPermission(PERMISSIONS, 'VIEW_CUSTOMER_FEEDBACK')) {
      url = "surveys/feedback/All/All";
      this.headerService.LandingPageCode = 'FS';
    }
    /* If user has view_custmaster role, then redirect to customer-master */
    else if (this.appService.CheckPermission(PERMISSIONS, 'VIEW_CUSTMASTER')) {
      url = "/customer-master/customer-master";
      this.headerService.LandingPageCode = 'CM';
    }

    if (urlfromserver == "" || urlfromserver == "/" || urlfromserver.includes("/signin-oidc")) {
      this.router.navigateByUrl(url);
    }
  }
  }





  ngAfterContentInit(): void {
    if(this.canAccess){
    this.loadFalconBird();
    }

  }
  loadFalconBird() {
    if(this.canAccess){
    setTimeout(function () {

      this.sharedService.GetFeedbackImageStatus().then(imageStatus => {
        this.sharedService.showBirdIcon = (imageStatus == "true" || imageStatus == true) ? true : false;
        if (imageStatus == "true" || imageStatus == true) {
          //document.getElementById("div-ShowflappingWings").style.display = "block";
          try {
            initFalcon();
          } catch (error) {
            console.log(error, "error in init method");
          }
          //this.showFlappingImage();
          this.showHeaderOnlineHelpIcon(true)
          setTimeout(() => {
            this.ActivateFeedBack(true);
          }, 7000);
        } else {
          document.getElementById("div-ShowflappingWings").style.display = "none";
          //this.hideFlappingImage();
          this.ActivateFeedBack(true);
        }
      })

    }.bind(this), 4000);
  }
  }
  // Falcon Bird
  showFlappingImage() {
    if(this.canAccess){
    // if (!this.OneTimeImageshow) {
    let divElements = document.getElementById('div-ShowflappingWings');
    if (divElements !== null) {
      divElements.classList.remove('cs-hideflappingImage');
      divElements.classList.add('cs-showflappingImage');

      // this.OneTimeImageshow = true;

      // hiding image on timer
      // setTimeout(function () {
      //   divElements.classList.remove('cs-showflappingImage');
      //   divElements.classList.add('cs-hideflappingImage');
      // }, this.OnlineHelpTimer);
    }
    // }
  }
  }
  // public loadScript(url: string) {
  //   const body = <HTMLDivElement>document.body;
  //   const script = document.createElement('script');
  //   script.innerHTML = '';
  //   script.src = url;
  //   script.async = false;
  //   script.defer = true;
  //   body.appendChild(script);
  // }
  ActivateFeedBack(isLoad) {
    if(this.canAccess){
    this.sharedService.ActivateHeaderFeedBack(isLoad)
    }
  }
  showHeaderOnlineHelpIcon(isLoad) {
    if(this.canAccess){
    this.sharedService.loadOnlineHelpGrid(isLoad);
    }
  }

  onHeaderIconClicked() {
    if(this.canAccess){
    if (currentFrame == 685) {
      this.isFlyOut = false;
    }
    if (currentFrame == 0) {
      this.isFlyOut = false;
    }
    if (!this.isFlyOut) {
      this.isClose = true;
      this.fromFeedBack = "true";
      let divElements = document.getElementById('div-ShowflappingWings');
      if (divElements.classList.contains('cs-hideflappingImage')) {
        this.showFlappingImage()
      }
      if (this.sharedService.showBird) {
        if (currentFrame >= 171) {
          this.isFlyOut = true;
          document.getElementById("closeBtnWrapper").style.display = "none";
          document.getElementById("feedbackclick").style.display = "none";
          this.sharedService.FeedbackImageDisplayUpdate(false).then(imageStatus => {
            changeFlag()
            setTimeout(() => {
              this.ActivateFeedBack(true);
            }, 7000);
          })
        }
      } else {
        if (currentFrame == 0) {
          document.getElementById("div-ShowflappingWings").style.display = "block";
          this.sharedService.FeedbackImageDisplayUpdate(true).then(imageStatus => {
            initFalcon()
            setTimeout(() => {
              this.ActivateFeedBack(true);
            }, 7000);
          })

        }

      }
    } else {
      this.ActivateFeedBack(true);
    }
    // if(!this.isFlyOut){
    // if (divElements !== null) {
    //   // if (divElements.classList.contains('cs-showflappingImage')) {
    //     if(document.getElementById("animation_container").style.display == ''){
    //       this.showFlappingImage();
    //       setTimeout(() => {
    //         this.ActivateFeedBack(true);
    //       }, 12000);
    //       initFalconFly("button");
    //     }
    //     if(document.getElementById("animation_container").style.display == "block" ){
    //       document.getElementById("animation_container").style.display = "none";
    //       document.getElementById("animation_container_out_fly").style.display = "block";
    //       document.getElementById("closeBtnWrapper").style.display = "none";
    //       document.getElementById("feedbackclick").style.display = "none";
    //       this.isFlyOut = true;
    //         initFalconBirdFlyOut(this.fromFeedBack);
    //         setTimeout(() => {
    //         this.ActivateFeedBack(true);
    //         this.isFlyOut = false;
    //       }, 12000);
    //     }else{
    //       document.getElementById("animation_container_fly").style.display = "block";
    //       try {
    //         initFalconFly("button");
    //         setTimeout(() => {
    //           this.ActivateFeedBack(true);
    //         }, 12000);
    //        } catch (error) {
    //          console.log(error, "error in init method");
    //        }
    //     }
    // var closeBtnWrapperSts = document.getElementById("closeBtnWrapper").style.display ;

    // if(closeBtnWrapperSts == "block"){
    //   document.getElementById("animation_container").style.display = "none";
    // document.getElementById("animation_container_out_fly").style.display = "block";

    //   initFalconBirdFlyOut(this.fromFeedBack);
    // }else{
    //   document.getElementById("animation_container_fly").style.display = "block";
    //   try {
    //     initFalconFly("button");

    //    } catch (error) {
    //      console.log(error, "error in init method");
    //    }

    // }
    // } else {
    //   // divElements.classList.remove('cs-hideflappingImage');
    //   // divElements.classList.add('cs-showflappingImage');
    //   try {
    //    // initFalconFly();
    //     // initFalconBird();
    //    } catch (error) {
    //      console.log(error, "error in init method");
    //    }
    //  //  document.getElementById("animation_container_fly").style.display = "block";

    // }
    // }
    // }
  }
  }


  showFeedback() {
    if(this.canAccess){ 
    const ref = this.dialogService.open(UserFeedbackComponent, {
      width: '355px',
      height: '325px',
      styleClass: 'userfeedback-popup',
      transitionOptions: '0ms'
    });
    ref.onClose.subscribe();
  }
  }
  hideFlappingImage() {
    if(this.canAccess){
    let divElements = document.getElementById('div-ShowflappingWings');
    if (divElements !== null) {
      divElements.classList.remove('cs-showflappingImage');
      divElements.classList.add('cs-hideflappingImage');
    }
  }
  }
  closeFlappingImage() {
    if(this.canAccess){
    this.ActivateFeedBack(false);
    this.isFlyOut = true;
    // document.getElementById("animation_container").style.display = "none";
    // document.getElementById("animation_container_out_fly").style.display = "block";
    document.getElementById("closeBtnWrapper").style.display = "none";
    document.getElementById("feedbackclick").style.display = "none";

    setTimeout(() => {
      this.ActivateFeedBack(true);
      this.isFlyOut = false;
    }, 7000);
    this.sharedService.FeedbackImageDisplayUpdate(false).then(imageStatus => {
      // initFalconBirdFlyOut(this.fromFeedBack);
      changeFlag()
      this.isClose = false;

      // this.hideFlappingImage()
    })
  }
  }

  initialSetup(data){
    if(localStorage.getItem('access_token')){
      this.canAccess=true;
     }
     else{
      this.canAccess=false;;
     }
     this.constructorData();
     this.ngOnInit();
     

  }
 async constructorData(){
    if(this.canAccess){
  // if the user clicks the back button, ask the CanDeactivateGuard to defend against this.
  window.onpopstate = () => CanDeactivateState.defendAgainstBrowserBackButton = true;

  // Upon successful navigation, ensure that the CanDeactivateGuard no longer defends against back button clicks
  this.router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    tap(() => CanDeactivateState.defendAgainstBrowserBackButton = false)
  ).subscribe();


  this.router.events.subscribe((event: Event) => {
    if (event instanceof NavigationEnd) {
      this.current_url = this.router.url;
      console.log("CURRENT_URL", this.current_url,event, this.isHasPermissions)
      console.log("default url",this.defaultViewUrl)
      
         if(this.router.url.includes('logout')){
          this.defaultUrlFromCookie = null;
          this.defaultViewUrl = null;
          this.isHasPermissions=false;
           this.canAccess=false;
            this.router.navigate(['/start-up']);
            
          
   }
      this.httpCancelService.cancelPendingRequests()
    }

  });
  this.userProfileonLoad = sessionStorage.getItem('userProfileonLoad');
  if (this.userProfileonLoad != null) {

    var userInfo = JSON.parse(this.userProfileonLoad);

    this.displayName = userInfo.displayName;
    this.profileimagurl = userInfo.PhotoURL;
    this.userId = userInfo.userId;
    if(document.cookie.indexOf("LandingPage")!=-1) {
      this.defaultUrlFromCookie = this.getCookie('LandingPage')
    }
    this.defaultViewUrl =this.defaultUrlFromCookie ? this.defaultUrlFromCookie: userInfo.defaultViewUrl;
    console.log(this.defaultViewUrl,'this.defaultViewUrl')
    this.headerService.displayName.next(this.displayName);
    this.headerService.profileimagurl.next(this.profileimagurl);

  }
  else {

    // this.displayName = this.elementRef.nativeElement.getAttribute('displayName');
    // this.profileimagurl = this.elementRef.nativeElement.getAttribute('profileimagurl');
    // this.defaultViewUrl = this.elementRef.nativeElement.getAttribute('defaultViewUrl');
    console.log('this.elementRef.nativeElement=>',this.elementRef.nativeElement)
    const result=await this.sharedService.getUserCookie();
    this.displayName = result.displayName;
    this.profileimagurl = result.profileImagUrl;
    this.defaultViewUrl = result.defaultViewUrl;
    this.userId = result.UserId;
    console.log("RESULT--",result);
    this.headerService.displayName.next(this.displayName);
    this.headerService.profileimagurl.next(this.profileimagurl);

    const userData = {
      'PhotoURL': this.profileimagurl,
      'displayName': this.displayName,
      'defaultViewUrl': this.defaultViewUrl,
      'userId': this.userId
    };

    sessionStorage.setItem('userProfileonLoad', JSON.stringify(userData));
  }
  if (this.defaultViewUrl && this.defaultViewUrl !== '') {
    this.isHasPermissions = true;
  }
  console.log(this.defaultViewUrl,'this.defaultViewUrl')
  // this.displayName = this.elementRef.nativeElement.getAttribute('displayName');
  // this.profileimagurl = this.elementRef.nativeElement.getAttribute('profileimagurl');
  // if (this.userProfile != null) {
  //   //debugger;
  //   const userProfileInfo = JSON.parse(this.userProfile);
  //   this.displayName = userProfileInfo.FirstName + ' ' + userProfileInfo.LastName;
  //   this.profileimagurl = userProfileInfo.PhotoURL;
  // } else {
  //   this.displayName = this.elementRef.nativeElement.getAttribute('displayName');
  //   this.profileimagurl = this.elementRef.nativeElement.getAttribute('profileimagurl');
  // }
  // this.loadScript('dist/assets/OnlineHelp/js/createjs-2015.11.26.min.js');
  // this.loadScript('dist/assets/OnlineHelp/js/Falcon_wings_flapping_HTML5Canvas.js');
  // this.loadScript('dist/assets/OnlineHelp/js/Falcon_wings_flapping.js');

  this.hmSlonBirdClickSubject = this.sharedService.hmSlonBirdClickSubject.subscribe(event => {
    if (event !== null) {
      this.onHeaderIconClicked();
    }
  });
}
  }
  
}
