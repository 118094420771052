import { style } from '@angular/animations';
import { Component, ElementRef, Input, OnChanges, SimpleChanges,Renderer2, ChangeDetectorRef, HostListener, SimpleChange, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import { Subject } from 'rxjs';
import { CtlsDashboardCustomerService, CtlsTaskSurveyFeedbackService } from 'src/app/Modules/ctls/ctls-shared/ctls-services';
import { CHART_STATE_OPTIONS } from 'src/app/shared/enums/chart-state-options.enum';
import { NumberFormatPipe } from 'src/app/shared/pipe/number-format.pipe'
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { CtlsSpinnerService } from 'src/app/Modules/ctls/ctls-shared/ctls-components/ctls-spinner/ctls-spinner.service';
import { NgHeaderTemplateDirective } from '@ng-select/ng-select/ng-select/ng-templates.directive';
export type SurveyType = 'CUST';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { CtlsViewSurveyDetailsComponent } from 'src/app/Modules/ctls/ctls-pages/ctls-task-listing/presentation/ctls-view-survey-details/ctls-view-survey-details.component';
import { DialogService  } from 'primeng/api';
@Component({
  selector: 'app-spiderweb-chart',
  templateUrl: './spiderweb-chart.component.html',
  styleUrls: ['./spiderweb-chart.component.scss']
})
export class SpiderwebChartComponent implements OnChanges {
  @ViewChild('flagInput') flagInput: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef; 
  // @ViewChild('scrollableDivPos') scrollableDivPos!: ElementRef;
  // @ViewChild('scrollableDivNeu') scrollableDivNeu!: ElementRef;
  // @ViewChild('scrollableDivNeg') scrollableDivNeg!: ElementRef;
  @Input() data: any;
  @Input() chartTitle: string = '';
  @Input() isDualyAxes?: boolean = false;
  @Input() isDrilldownEnabled?: boolean = false;
  @Input() globalFilterData : any;
  @Input() chartIdentifier: string = '';
  @Input()
  public chartState: CHART_STATE_OPTIONS;
  isLeftContextMenu: boolean = false;
  chartInstance: any;
  positiveShow: boolean = true;
  negetiveShow: boolean = true;
  neutralShow: boolean = true;
  pos: any = 0;
  neg: any = 0;
  neut: any = 0;
  status: boolean = false;
  selectedType: any = '';
  prevSelectedType: any = '';
  datashown: any;
  popoverShow: boolean;
  posExpCollapse: boolean;
  negExpCollapse: boolean;
  neutExpCollapse: boolean;
  prevSelectedLegend: string;
  selectedLegend: string;
  commentStatus: any;
  commentHead: any;
  objLanguages: any;
  selectedLanguage: string = '';
  selectedTask: any = { SurveyType: 'TRANS', TaskID: '' };
  destroy$ = new Subject();
  selectedCategory: any;
  cardClickedIndex: any;
  feedbackDataFull: any;
  feedbackDataCopy: any;
  dragStarted = false;
  totalRecords: any;
 
  constructor(private el: ElementRef, private renderer: Renderer2,
    private ctlsTaskSurveyFeedbackService: CtlsTaskSurveyFeedbackService,
    private spinnerService: CtlsSpinnerService,
    private ctlsDashboardCustomerService: CtlsDashboardCustomerService, private router : Router, private dialogService: DialogService) {}

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  oneToOneFlag: boolean = true;
  private imagepath = environment.imagepath;

  isDraggable: boolean = true;
  dragging: boolean = false;
  dragStartX: number = 0;
  dragStartY: number = 0;
  popupLeft: number = 0;
  popupTop: number = 0;
  feedabackDetails: any= [] ;
  // feedbackData: any;
  categoryArray: any;
  serachValueUpdated: any;
  latestIndex: any;
  moredataToggle: any = false;
  flaggedmsgtextToggle: any;
  flaggedmsgToggle: any;
  flagData: any[] = [];
  flagDataCopy: any;
  latestIndexFlag: any;
  toggleIndex: any;
  latestIndexexpand: boolean;
  searchValueFlag: any;
  showCustomerUpdates = true;
  defaultTab: any = 'FEEDBACK';
  showDefaultTrue = true;
  haveDate = false;
  langdropdwnToggle: any;
  searchValue: any;
  objLanguagesCopy: any;
  langCode: any;
  translatedFlag: boolean;
  searchareaToggle: boolean = false;
  surveyType: SurveyType = 'CUST';
  sentimentalType = "negative|positive|neutral";
  trueFlag = true;
  latestIndexFlagView: any;
  flagReason;
  
  // filterData: any;
  filterObj = {
    pageNumber: 1,
    pageSize: 10,
    filterFields: {
        fAMIDs: [],
        bAIDs: [],
        dIVIDs: [],
        contientIDs: [],
        countryIDs: [],
        operationalCountryIDs: [],
        categoryIDs: [],
        touchPointIDs: [],
        brandIDs: [],
        branchIDs: [],
        zoneIDs: [],
        influencers: [],
        regionIDs: [],
        salesEngineers: [],
        serviceTechnicians: [],
        customerNames: [],
        customerAccountNumbers: [],
        fromDate: '',
        toDate: '',
        periodType: 'M',
        surveyNameID: null,
        surveyScheduleInstanceID: null,
        distributors: [],
        distributorSegment: [],
        distributorAccountNumber: [],
        surveyID: '',
        contactName: '',
        orderNo: '',
        nPSScore: '',
        nPSComment: '',
        influenceLevel: '',
        emailAddress: '',
        objective: '',
        event: '',
        category: '',
        distributorshipManagerSales: '',
        distributorshipManagerService: '',
        modifiedBy: '',
        comments: '',
        taskCount: '',
        cTLSEnabledDate: '',
        cTLSModifiedDate: '',
        feedbackSubmitDate: '',
        firstName: '',
        lastName: '',
        emailID: '',
        designation: '',
        Status: '',
        AccountSearch: "",
        Type: '',
        Country: '',
        SentimentalCategory:[],
        SentimentType:'',
        SentimentalSearch:"",
        SurveyInstanceID:[]
    },
    
    sortType: '',
    sortField: '',
    GlobalEntityID: 1, // "",//1,
    StandardEntityID: null,
    EntityID: null, //"31478",
    ContactID: null,
    AccountNo: '',
    CustomerName: '',
    GlobalEntityName: '',
    GcCode: '',
    CustomerType: ''

};
onScroll(event: Event) {
  const scrollPosition = (event.target as HTMLElement).scrollTop;
  console.log('Scroll Position:', scrollPosition);
  if(this.flaggedmsgToggle)
    this.flaggedmsgToggle = false;
  if (scrollPosition >= 1000 && (this.feedabackDetails && this.totalRecords > this.filterObj.pageNumber * 10 )) {
    this.loadData();
  }
  // Your scroll logic here
}
// @HostListener('window:scroll', [])
//   onWindowScroll() {
//     const scrollableElement = document.documentElement;
//     const scrollHeight = scrollableElement.scrollHeight;
//     const scrollTop = scrollableElement.scrollTop;
//     const clientHeight = scrollableElement.clientHeight;
//     if(this.flaggedmsgToggle)
//       this.flaggedmsgToggle = false;
//     if (scrollTop + clientHeight >= 1000 && (this.feedabackDetails && this.totalRecords > this.feedabackDetails.length)) {
//       this.loadData();
//     }
//   }
  @HostListener('document:click', ['$event'])
  handleClick(event: any): void {
    
      let el = document.getElementById('s-g-d-footer');

      // Check if the click target is not within the list or its descendants
      const clickedInside = this.el.nativeElement.contains(event.target);
      if(el && el.id !== 's-g-d-footer' || event.target.classList.contains('highcharts-background') || !clickedInside ){
        if (!this.commentStatus) {
        // Close the expanded category or perform any action you need
        this.status = false;
        this.selectedLegend = null;
        this.posExpCollapse = this.negExpCollapse = this.neutExpCollapse = false;
        this.datashown = false;
        this.popoverShow = false;
        }
      }     
  }
  // ngAfterViewChecked() {
  //   if (this.flaggedmsgtextToggle && this.flagInput) {
  //     this.flagInput.nativeElement.focus();
  //   }
  // }
  tooltipFormatter(categoryName: string) {
    return function () {
      this.chart.tooltip.refresh(this.chart.series[0].points.find(point => point.name === categoryName));
      return `<b><span style="font-size:10px">${categoryName}</span></b><br>` +
             `<b><span style="font-size:11px">Frequency:</span></b> <b>${this.point.value}</b><br>` +
             `<b><span style="font-size:11px">Sentiment Score:</span></b> <b>${this.point.y}</b><br>`;
    };
  };
  // ngAfterViewInit() {
    
  // }

  ngOnChanges(changes: SimpleChanges) {
    this.data = [...this.data]
    if (this.chartState === 'success') {
      this.positiveShow = true;
      this.negetiveShow = true;
      this.neutralShow = true;
      this.configureChartOptions();
   }

   this.getDropdowns();
  //  this.selectedLegend = null;
   let feedabackDetails: SimpleChange = changes.feedabackDetails;
   this.latestIndex = null;
   this.latestIndexFlag = null;
   this.latestIndexFlagView = null;
   this.toggleIndex = null;
   this.cardClickedIndex = null;
   this.langdropdwnToggle =  false;
   this.searchareaToggle =  false;
   this.serachValueUpdated = null;
   this.langCode = null;
 
  
  if(feedabackDetails && feedabackDetails.currentValue) {
    this.feedabackDetails = _.cloneDeep(feedabackDetails.currentValue);
    this.feedbackDataCopy = _.cloneDeep(feedabackDetails.currentValue);
    this.categoryArray = [];
  } 
   if(this.objLanguages && this.objLanguages.length >0){
      this.objLanguagesCopy = this.objLanguages;
      this.objLanguages = this.objLanguages;
    }

  }
  loadData(){
    // this.filterObj = this.filterData;
    this.filterObj.pageNumber = this.filterObj.pageNumber +1;
    // this.feedabackDetails = Object.assign({}, {}); //data.Response;
    this.spinnerService.show();
    this.filterObj.filterFields.SentimentalCategory = [this.selectedCategory];
    this.filterObj.filterFields.SentimentalSearch = this.serachValueUpdated;
    this.filterObj.filterFields.SurveyInstanceID = [];
    this.ctlsDashboardCustomerService.getFeedbackListDashboard(this.filterObj)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
            console.log("feedabackDetails", data)
            if (data && data.Items && data.Items.length > 0) {  
              this.totalRecords = data.TotalRecords;    
              this.feedabackDetails = [...this.feedabackDetails, ...data.Items];
              this.feedbackDataCopy= [...this.feedbackDataCopy, ...data.Items];
              // if(this.scrollableDivNeg && this.scrollableDivNeg.nativeElement && this.selectedLegend =='Negative') {
              //   this.renderer.listen(this.scrollableDivNeg.nativeElement, 'scroll', this.onScroll.bind(this));
              // }
              // if(this.scrollableDivPos && this.scrollableDivPos.nativeElement && this.selectedLegend =='Positive') {
              //   this.renderer.listen(this.scrollableDivPos.nativeElement, 'scroll', this.onScroll.bind(this));
              // }
              // if(this.scrollableDivNeu && this.scrollableDivNeu.nativeElement && this.selectedLegend =='Neutral') {
              //   this.renderer.listen(this.scrollableDivNeu.nativeElement, 'scroll', this.onScroll.bind(this));
              // }
              if(this.langCode && this.translatedFlag){
                this.translate(this.langCode,'fromLoad')
              }
               
            }
        }, (err) => {
        })
}

 
done() {
  this.feedabackDetails = Object.assign([], []); //data.Response;
  this.feedbackStatus = CHART_STATE_OPTIONS.LOADING;
      this.spinnerService.hide();
      this.filterObj.filterFields.SentimentalCategory = [this.selectedCategory];
      this.filterObj.filterFields.SentimentalSearch = this.serachValueUpdated;
      this.filterObj.filterFields.SurveyInstanceID = [];
      this.filterObj.pageNumber = 1;
      this.ctlsDashboardCustomerService.getFeedbackListDashboard(this.filterObj)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
              console.log("feedabackDetails", data)

              if (data !== null && data.Status == 'Failure') {
                this.feedbackStatus = CHART_STATE_OPTIONS.ERROR;
                this.feedabackDetails = Object.assign([], []);
            }

            if ((data === null || data.Items == null  || (data.Items.length === 0))) {
                this.feedbackStatus = CHART_STATE_OPTIONS.NO_DATA;
                this.feedabackDetails = Object.assign([], []);
                this.feedbackDataCopy= Object.assign([], []);
                this.feedbackDataCopy= Object.assign([], []);

            }

            if (data && data.Items && data.Items.length > 0) {
                this.totalRecords = data.TotalRecords;
                this.feedabackDetails = Object.assign([], data.Items);
                this.feedbackStatus = CHART_STATE_OPTIONS.SUCCESS;
                this.feedbackDataCopy = Object.assign([], data.Items);
                // if(this.scrollableDivNeg && this.scrollableDivNeg.nativeElement && this.selectedLegend =='Negative') {
                //   // this.renderer.listen(this.scrollableDivNeg.nativeElement, 'scroll', this.onScroll.bind(this));
                // }
                // if(this.scrollableDivPos && this.scrollableDivPos.nativeElement && this.selectedLegend =='Positive') {
                //   // this.renderer.listen(this.scrollableDivPos.nativeElement, 'scroll', this.onScroll.bind(this));
                // }
                // if(this.scrollableDivNeu && this.scrollableDivNeu.nativeElement && this.selectedLegend =='Neutral') {
                //   // this.renderer.listen(this.scrollableDivNeu.nativeElement, 'scroll', this.onScroll.bind(this));
                // }
            }



          }, (err) => {
              this.feedabackDetails = Object.assign([], []);
              this.feedbackDataCopy= Object.assign([], []);
          })
}
  clear() {
    if(this.categoryArray.length > 0) {
      this.categoryArray = [];
      this.latestIndex = null;
      this.latestIndexFlag = null;
      this.latestIndexFlagView = null;
      this.toggleIndex = null;
      this.done();
    }
  }

  configureChartOptions() {
    let classContext = this;
    const categories = [];
    let positive: any = 0;
    let negetive: any = 0;
    let neutral: any = 0;
    this.data.forEach((item) => {
      categories.push(item.Category);
      positive = positive + item.Positive_Frequency;
      negetive = negetive + item.Negative_Frequency;
      neutral = neutral + item.Neutral_Frequency;
      item.CategoryWithoutSpaces = item.Category.replace(/\s+/g, ''); 
    });
    this.pos = positive;
    this.neg = negetive;
    this.neut = neutral;
    const filterPipe = new NumberFormatPipe();
    const seriesData = this.data;

    this.chartOptions = {
      chart: {
        polar: true,
        type: 'line',
        width: 430,  // Adjust the width as desired
        height: 360,
        backgroundColor: "#FDFDFD" , 
        marginBottom: 50 ,        
      },
       pane: {
        size: '80%', // Increase the size value to adjust the distance between the angles
        //startAngle: 0,       // Adjust the start angle
        //endAngle: 360 
      },
      title: {
        text: '',
        verticalAlign: 'top',
        align: 'left',
        style: {
          fontFamily: 'Source Sans Pro Regular',
          fontSize: '1.3em',
          fontWeight: 'bold',
          color: '#ffffff'
        }
      },
      // Define other chart options here
      xAxis: {
        categories: categories,
        gridLineColor: '#ccc', 
        tickmarkPlacement: 'on',
        lineWidth: 0,
        labels:{
          style:{
            color: '#000000',
            fontSize:'1em',
            fontWeight:'400'
          },
          // formatter: function () {
          //   // Handle mouse over and show tooltip for categories (x-axis)
          //   const category = this.value;
          //   //this.tooltipFormatter(category).call(this);
          //   //this.chart.tooltip.refresh(this.value); // Adjust series index and point index as needed
          //   return this.value.toString(); // Ensure the return type is a string
            
          // },
          
        },
      },
      yAxis: {
        gridLineInterpolation: 'polygon',
        gridLineColor: '#ccc', 
        lineWidth: 0,
        //  min: 0,
        // max: 6,
        endOnTick: true,
        startOnTick: true,
        showLastLabel: true,
        gridLineWidth: 1,
        minorTickInterval: 1,
        tickInterval: 18,
        minorGridLineWidth: 0,
        tickmarkPlacement: 'on',
        labels: {
          enabled: true,
          style:{
            color: '#000000',
            fontWeight:'500',
          }
        },
        // tickPositions: [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5,6]
      },

// tooltip: {
      //   useHTML: true,
      //   pointFormat: '<b>{point.name}</b><br><b>Count of Net Sentiment Score:</b> <b>{point.z}</b><br>',
      // },
      tooltip: {
        useHTML: true,
        followPointer: true, // Make the tooltip follow the mouse pointer
        followTouchMove : false,
        formatter: function () {
          // Check if the point is a category (you may need to adjust the condition)
        if (this.key === this.point.category) {
          const dataPoint = seriesData.find(point => point.Category === this.point.name);
          return (
            '<b><span style="font-size:12px">' + this.point.name + '</span></b><br>' +
            (classContext.positiveShow ? '<b> <span style="font-size:11px">Positive: </span></b> <b><span style="font-size:11px">' + filterPipe.transform(dataPoint.Positive_Frequency,0) +'/' + filterPipe.transform(positive,0) + ' (' + dataPoint.PositivePercent + '</span><span style="font-size:9.5px">%</span>)' + '</b><br>' : '') +
            (classContext.negetiveShow ? '<b> <span style="font-size:11px">Negative: </span></b> <b><span style="font-size:11px">' + filterPipe.transform(dataPoint.Negative_Frequency,0) + '/' + filterPipe.transform(negetive,0)  + ' (' + dataPoint.NegativePercent + '</span><span style="font-size:9.5px">%</span>)' + '</b><br>' : '') +
            (classContext.neutralShow ? '<b> <span style="font-size:11px">Neutral: </span></b> <b><span style="font-size:11px">' +  filterPipe.transform(dataPoint.Neutral_Frequency,0) + '/' +  filterPipe.transform(neutral,0) + ' (' + dataPoint.NeutralPercent + '</span><span style="font-size:9.5px">%</span>) </b>' : '')
          );
        
        }
          return '';
        },
        hideDelay: 200,
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal' , 
        itemStyle:{          
            color: '#000000',
            fontWeight: 'normal',
            top:'10px'                 
        },             
        padding: -5,
      },
      plotOptions: {
        series: {
          //pointInterval: 45,
          lineWidth: 1,
          threshold: -34,
          marker: {
            radius: 3, // Adjust this value to set the desired marker size for data points
          },
          point: {
          events: {
            
            mouseOver: function () {
              let _this: any = this;
              this.tooltipFormatter.bind(this.category)
              // Show tooltip on mouse over
              //_this.chart.tooltip.refresh(_this.chart.series[0].points.find(point => point.name === _this.category));
            },
            mouseOut: function () {
              let _this: any = this;
              // Hide tooltip on mouse out
              _this.series.chart.tooltip.hide();
            },
          }
        },
        }
      },
      series: [
        {
          keys: ['y', 'name', 'totCount'],
          data: this.data.map((value, index) => ({
            y: value.NeutralPercent,
            name: categories[index],
            value: value.Neutral_Frequency
          })),
          name: 'Neutral',
          pointPlacement: 'on',
          type: 'area',
          color: '#FFAF00',
          //fillOpacity: 0.2,
          fillColor: '#ffaf0057',
          legendIndex: 3,
          marker: {
            symbol: 'circle',
            radius:2
          },
          events: {
            legendItemClick: function() {
              const series = this;
              const selectedLegend = this.name; // Name of the clicked legend
              classContext.handleLegendItemClick('Neutral',series.visible);
            }
          }
        },
        {
          keys: ['y', 'name', 'totCount'],
          data: this.data.map((value, index) => ({
            y: value.PositivePercent,
            name: categories[index],
            value: value.Positive_Frequency
          })),
          name: 'Positive',
          pointPlacement: 'on',
          type: 'area',
          color: '#43AF2A',
          //fillOpacity: 0.2,
          fillColor: '#43af2a57',
          legendIndex: 2,
          marker: {
            symbol: 'circle',
            radius:2
          },
          events: {
            legendItemClick: function() {
              const series = this;
              const selectedLegend = this.name; // Name of the clicked legend
              classContext.handleLegendItemClick('Positive',series.visible);
            }
          }
        }, 
        {
          keys: ['y', 'name', 'totCount'],
          data: this.data.map((value, index) => ({
            y: value.NegativePercent,
            name: categories[index],
            value: value.Negative_Frequency
          })),
          name: 'Negative',
          pointPlacement: 'on',
          type: 'area',
          color: '#C8102E',
          legendIndex: 1,
          //fillOpacity: 0.2,
          fillColor: '#c8102e57',
          marker: {
            symbol: 'circle',
            radius:2
          },
          events: {
            legendItemClick: function() {
              const series = this;
              const selectedLegend = this.name; // Name of the clicked legend
              classContext.handleLegendItemClick('Negative',series.visible);
            }
          }
        },
        
      ],
      // Define other chart options here
    };
  }

  handleLegendItemClick(name,isVisible) {
    if(name === 'Neutral') {
      this.neutralShow = !isVisible;
    } else if(name === 'Positive') {
      this.positiveShow = !isVisible;
    } else if(name === 'Negative') {
      this.negetiveShow = !isVisible;
    } 

  }

  onChartInstance(chart: Highcharts.Chart) {
    this.chartInstance = chart;
  }
  showDetails(selectedType) {
    this.commentStatus = false;
    this.prevSelectedType = this.selectedType;
    this.selectedType = selectedType;
    if (this.prevSelectedType === this.selectedType)
      this.status = !this.status;
    else
      this.status = true;
      this.prevSelectedType = this.selectedType;
      this.selectedType = selectedType;   
  }
  collapseDetails(itemValue) {
    this.prevSelectedLegend = this.selectedLegend;
    this.selectedLegend = itemValue == 1 ? 'Negative' : itemValue == 2 ? 'Positive' : itemValue == 3 ? 'Neutral': ''
    if(itemValue == 1) {
      this.negExpCollapse = true;
      this.posExpCollapse = false;
      this.neutExpCollapse = false;
    } else if(itemValue == 2) {
      this.posExpCollapse = true;
      this.negExpCollapse =false;
      this.neutExpCollapse = false;
    } else if(itemValue == 3) {
      this.neutExpCollapse = true;
      this.negExpCollapse = false;
      this.posExpCollapse =  false;
    }
    this.status = false;
    this.selectedType = '';
    if (this.prevSelectedLegend === this.selectedLegend) {
      this.datashown = !this.datashown;
      this.popoverShow = !this.popoverShow;
    } else {
      this.datashown = true;
      this.popoverShow = true;
    }
    
  }
  serachValue(event) {
    if(event.target.value.length >= 3 || (this.serachValueUpdated && this.serachValueUpdated.length ==3 && event.target.value.length ==0)) {
      this.serachValueUpdated = event.target.value;
        this.latestIndex = null;
        this.latestIndexFlag = null;
        this.latestIndexFlagView = null;
        this.toggleIndex = null;
        this.done();
  }
}
  toggleComments(category){
    const draggableElement = document.getElementById('feedbacks');
    this.flaggedmsgToggle = false;
    this.langdropdwnToggle = false;
    this.langCode = null;
    this.latestIndex = null;
    this.latestIndexFlag = null;
    this.latestIndexFlagView = null;
    this.toggleIndex = null;
    // this.showcategories = false;
    this.langdropdwnToggle =  false;
    this.searchareaToggle =  false;
    this.serachValueUpdated = null;
    this.cardClickedIndex = null;
    this.langCode = null;
    this.commentStatus = !this.commentStatus;
    this.commentHead = !this.commentHead;
    this.selectedCategory = category.Category;
    this.filterObj.filterFields.SentimentalCategory = [category.Category];
    this.filterObj.pageNumber = 1;
    this.dragStarted = false;
    if(draggableElement && !this.commentStatus){
      draggableElement.style.transform = 'none';
      draggableElement.style.left = '';
      draggableElement.style.top = '';
    }
    this.getFeedbackList(this.filterObj);
  }

  // openTaskEditPage(item,surveyData) {
  //   console.log(item, "test");
  //   if (surveyData.toUpperCase() == 'TRANS' || surveyData.toUpperCase() == 'DIST') {
  //     this.router.navigate(['ctls/task-listing/edit'], {
  //       queryParams: {
  //         TaskID: item.Feedbackid,
  //         selectedRadioOption: surveyData.toUpperCase() == 'TRANS' ? 1 : 2,
  //       },
  //       // skipLocationChange: true,
  //     });
  //   } else {
  //     this.router.navigate(['ctls/voc-listing/edit'], {
  //       queryParams: {
  //         TaskID: item.Feedbackid,
  //         selectedRadioOption: 1,
  //       },
  //       // skipLocationChange: true,
  //     });
  //   }
  // }


  showSurveyDetails(objRowData = null,index) {
    this.cardClickedIndex = index;
    // this.showDialog = true;
    if (!!objRowData) {
      this.ctlsDashboardCustomerService
                            .View360TransactionalSurveyDetailsById(objRowData.Feedbackid)
                            .pipe(takeUntil(this.destroy$))
                            .subscribe((res) => {
                                res['surveyType'] = 1;
                                this.openViewDialog(res);
                            });
    }
  }

  openViewDialog(data) {
    // data.surveyType = this.selectedRadioOption === 1 ? 1 : 2;
    // this.blnCreateTaskpopupClose = false;
    const ref = this.dialogService.open(CtlsViewSurveyDetailsComponent, {
        width: '75%',
        height: '70vh',
        transitionOptions: '0ms',
        data: data,
    });

    ref.onClose.pipe(takeUntil(this.destroy$)).subscribe((objSaveTask) => {
        //   this.blnCreateTaskpopupClose = true;
        // this.popupMessageService.clearMessage(); //when close clear popup data in service
    });
}
 
  getDropdowns() {
    this.ctlsTaskSurveyFeedbackService
      .getManualTaskFeedbackMasters(this.selectedTask.SurveyType, this.selectedTask.TaskID)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (data) => {
        if (data.Response) {
          let objAllDropdowns = await data.Response;
          this.objLanguages = objAllDropdowns.languages;
          this.objLanguages.map((item) => {
            item.Language = item.LanguageDescription + '(' + item.ISOCode + ')';
          });
          this.objLanguagesCopy = [...this.objLanguages];
        }
      }, () => {}, () => {});
  }


  showLang(){
    this.langdropdwnToggle = !this.langdropdwnToggle;
    this.objLanguages =this.objLanguagesCopy;
  }
  closeFeedback(){
    const draggableElement = document.getElementById('feedbacks');
    if(draggableElement){
      draggableElement.style.transform = 'none';
      draggableElement.style.left = '';
      draggableElement.style.top = '';
    }
    this.langCode = null;
    this.commentStatus = false;
    this.dragStarted = false;
    
  }


  feedbackStatus:CHART_STATE_OPTIONS;
  statusTaskCountData: CHART_STATE_OPTIONS;
  
  mapSentimentType(selectedLegend) {
    const sentimentTypes = this.sentimentalType.split('|');
    const selected = selectedLegend.toLowerCase();
    
    if (sentimentTypes.includes(selected)) {
        return selected;
    }
    
    // Default or fallback value, if necessary
    return '';
}
 getFeedbackList(filterObj){
        this.feedbackStatus = CHART_STATE_OPTIONS.LOADING;
        this.feedabackDetails = Object.assign([], []); //data.Response;
        this.spinnerService.hide();
        // this.filterObj.filterFields.SentimentalCategory = [];
          filterObj.filterFields.fromDate = this.globalFilterData.StartDate;
          filterObj.filterFields.toDate = this.globalFilterData.EndDate;
          filterObj.filterFields.periodType = this.globalFilterData.PeriodType;
          filterObj.filterFields.fAMIDs = this.globalFilterData.FAMCode.length >0? this.globalFilterData.FAMCode.split("|").map(x => Number(x)):[];
          filterObj.filterFields.bAIDs = this.globalFilterData.BACode ? this.globalFilterData.BACode.split("|").map(x => Number(x)):[];
          filterObj.filterFields.dIVIDs = this.globalFilterData.DivCode ? this.globalFilterData.DivCode.split("|").map(x => Number(x)):[]; 
          filterObj.filterFields.brandIDs = this.globalFilterData.BrandName ? this.globalFilterData.BrandName.split("|").map(x => Number(x)):[];
          this.filterObj.CustomerType = this.surveyType;
          filterObj.filterFields.SentimentType = this.mapSentimentType(this.selectedLegend);
        
        this.filterObj.filterFields.SentimentalSearch = "";
        this.filterObj.filterFields.SurveyInstanceID = [];
        this.ctlsDashboardCustomerService.getFeedbackListDashboard(filterObj)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("feedabackDetails", data)

                if (data !== null && data.Status !== 'Failure') {
                    this.feedbackStatus = CHART_STATE_OPTIONS.ERROR;
                    this.feedabackDetails = Object.assign([], []);
                    this.feedbackDataCopy = Object.assign([], []);
                }

                if ((data === null || data.Items == null  || (data.Items.length === 0))) {
                    this.feedbackStatus = CHART_STATE_OPTIONS.NO_DATA;
                    this.feedabackDetails = Object.assign([], []);
                    this.feedbackDataCopy = Object.assign([], []);

                }

                if (data && data.Items && data.Items.length > 0) {
                    this.totalRecords = data.TotalRecords;
                    this.feedabackDetails = Object.assign([], data.Items);
                    this.feedbackDataCopy = _.cloneDeep(data.Items);
                    let surveyIdArray = []
                  if (this.feedabackDetails.length > 0) {
                    this.feedabackDetails.forEach(element => {
                      if (element.Isread == false) {
                        surveyIdArray.push(element.Feedbackid)
                      }
                    });
                    if (surveyIdArray.length > 0) {
                      this.updateIsRead(surveyIdArray);
                    }
                  }
                    this.feedbackStatus = CHART_STATE_OPTIONS.SUCCESS;
                    setTimeout(() => {
                      // if(this.scrollableDivNeu && this.scrollableDivNeu.nativeElement && this.selectedLegend =='Neutral') {
                      //   this.renderer.listen(this.scrollableDivNeu.nativeElement, 'scroll', this.onScroll.bind(this));
                      // }
                      // if(this.scrollableDivNeg && this.scrollableDivNeg.nativeElement && this.selectedLegend =='Negative') {
                      //   this.renderer.listen(this.scrollableDivNeg.nativeElement, 'scroll', this.onScroll.bind(this));
                      // }
                      // if(this.scrollableDivPos && this.scrollableDivPos.nativeElement && this.selectedLegend =='Positive') {
                      //   this.renderer.listen(this.scrollableDivPos.nativeElement, 'scroll', this.onScroll.bind(this));
                      // }
                      
                    }, 3000);
                    
                }

            }, (err) => {
                this.statusTaskCountData = CHART_STATE_OPTIONS.ERROR;
            })
    }

    spliceData(data, reason) {
      try {
      return data.replace(/\\/g, '');
    } catch (error) {
      // console.error('Error parsing category reason:', error);
      return data;
    }
    }
    toggleMoredata(index){
      if(this.moredataToggle && this.latestIndex == index) {
        this.latestIndex = null;
        this.moredataToggle = false;
      } else{
        this.feedabackDetails[index].Isread = true;
        this.latestIndex = index;
        this.moredataToggle = true;
      }  
      // this.status = !this.status;
    }
    addFlag(flag,data,index) {
      if(!flag || flag.length == 0) {
        return;
      }
      if (!data || !data.Feedbackid) {
        console.error('Invalid data:', data);
        return;
      }
      this.filterObj.filterFields.SurveyInstanceID = [data.Feedbackid];
      this.filterObj.filterFields.comments = flag;
     this.ctlsDashboardCustomerService.saveFlag(this.filterObj)
             .pipe(takeUntil(this.destroy$))
             .subscribe((data) => {
                 console.log("feedabackDetails", data)
                 if (data !== null && data.Status !== 'Success') {      
                     this.flagData = Object.assign([], []);
                 }
 
                 if ((data === null || data.Response === null || (data.Response.length === 0))) { 
                     this.flagData = Object.assign([], []);
                 }
 
                 if (data.Response && data.Status == 'Success') { 
                     this.remove();
                     this.feedabackDetails[index].isFlaggedcomment = true;
                 }
             }, (err) => {
                 this.flagData = Object.assign([], []);
             })
   }
    getSavedFlag(data,event) {  
      // this.filterObj = this.feedabackDetails;
      this.filterObj.filterFields.SurveyInstanceID = [data.Feedbackid];
      this.ctlsDashboardCustomerService.getSavedFlag(this.filterObj)
              .pipe(takeUntil(this.destroy$))
              .subscribe((data) => {
                  console.log("feedabackDetails", data)
  
                  if (data !== null && data.Status !== 'Success') {
                      this.flagData = Object.assign([], []);
                  }
  
                  if ((data === null || data.Response === null || (data.Response.length === 0))) {
                      this.flagData = Object.assign([], []);
  
                  }
  
                  if (data.Response && data.Status == 'Success') {
                      this.flagData = Object.assign([], data.Response);
                      this.flagDataCopy = Object.assign([], data.Response);
                      this.flaggedmsgtextToggle = false;
                      if(this.flagData.length >0){
                        this.flaggedmsgToggle = true; // Show once data is received
                        this.positionPopover(event);
                      } else {
                        this.flaggedmsgToggle = false; // Hide on error
                      }
                  }
  
  
  
              }, (err) => {
                  this.flagData = Object.assign([], []);
              })
           
    }
    updateIsRead(surveyIdArray) {
      let filterObj = this.filterObj;
      filterObj.filterFields.SurveyInstanceID = surveyIdArray;
     this.ctlsDashboardCustomerService.updateIsread(filterObj)
             .pipe(takeUntil(this.destroy$))
             .subscribe((data) => {
                 console.log("data", data)
             }, (err) => {
             })
   }
   async showFlaggedmessages( event = null,data,index){
    event.stopPropagation();
    let indVal =this.latestIndexFlagView;
    this.latestIndexFlagView = index;
    this.feedabackDetails[index].Isread = true;
    this.flaggedmsgToggle = false;
    this.flaggedmsgtextToggle = false; 
    this.latestIndexFlag =  null;
    if(!this.flaggedmsgToggle && indVal != this.latestIndexFlagView) {
        await this.getSavedFlag(data,event);
    } else{
      this.flaggedmsgToggle = false;
      this.flagData = [];
      this.latestIndexFlagView = null;
      this.latestIndexFlag = null;
    }
   
  }
  makeFlaggedmessages(index,type){
    if(this.latestIndexFlag !=index || type) {
      this.flaggedmsgtextToggle = true;
      this.feedabackDetails[index].Isread = true;
      this.flaggedmsgToggle = false;
      this.latestIndexFlagView =  null;
      this.latestIndexFlag = index;
    } else {
      this.flaggedmsgToggle = false;
      this.latestIndexFlag = null;
      this.flaggedmsgtextToggle = false;
      this.latestIndexFlagView =  null;
    }
   
  }
    positionPopover(event) {
      let flaggedPopover = document.querySelector(".message-flag-popover") as HTMLElement;
      // const draggableElementRect = this.el.nativeElement.getBoundingClientRect();
      // console.log(event);
      if (flaggedPopover) {
        flaggedPopover.style.top = (event.layerY + 20) + 'px';
      }
    }
    remove() {
      this.latestIndexFlag = null;
      this.latestIndexFlagView = null;
      this.flagReason = null;
      this.flaggedmsgtextToggle = false;
    
    }
    displayText(fullText,ind) {
      return this.latestIndexexpand && ind==this.toggleIndex ? fullText : fullText.substring(0, 100);
    }
    
    toggleText(ind) {
      if(this.toggleIndex != ind) {
      this.toggleIndex = ind;
      this.feedabackDetails[ind].Isread = true;
      this.latestIndexexpand = true;
      } else {
        this.toggleIndex = null;
        this.latestIndexexpand = false;
      }
    }
    flagSearch(event) {
      if(event.target.value.length >= 3 || (this.searchValueFlag && this.searchValueFlag.length===3 && event.target.value.length==0)) {
        this.searchValueFlag = event.target.value;
        // this.objLanguages = {...this.objLanguagesCopy};
        if(event && event.target.value){
          this.flagData = [];
          this.flagDataCopy.forEach(element => {
            if(element.Comment.toLowerCase().includes((event.target.value).toLowerCase())) {
              this.flagData.push(element);
            }
          });
          // this.objLanguages= this.objLanguages.filter(data=> data.Language.toLowerCase().includes((event.target.value).toLowerCase()));
        } else {
          this.flagData =  this.flagDataCopy;
        }
        
      }
    }
    translateCall(objData) {
      this.ctlsDashboardCustomerService
      .getTranslateWithGoogle(objData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.Response && res.Response.TranslatedText) {
          let copyResponse = [...res.Response.TranslatedText];
          this.feedabackDetails.forEach((element,index) => {
            element.Description.forEach((data,ind)=> {
              this.feedabackDetails[index].Description[ind].ResponseValue = copyResponse[ind];
             
            })
            for(let i=0;i<element.Description.length;i++){
              copyResponse.splice(0,1);
            }
              
          })
        }
      });
    }
    languageSearch(event) {
      if(event.target.value.length >= 3 || (this.searchValue.length===3 && event.target.value.length==0)) {
        this.searchValue = event.target.value;
        if(event && event.target.value && event.target.value.length > 0){
          this.objLanguages = [];
          this.objLanguagesCopy.forEach(element => {
            if(element.Language.toLowerCase().includes((event.target.value).toLowerCase())) {
              this.objLanguages.push(element);
            }
          });
        } else {
          this.objLanguages =  this.objLanguagesCopy;
        }
        
      }
    }
    toggleSearcharea() { 
      this.searchareaToggle = !this.searchareaToggle;
      if(this.searchareaToggle) {
        setTimeout(() => {
          this.searchInput.nativeElement.focus();
        }, 10);
      }
    }
    closeSearcharea(){
      this.searchareaToggle = !this.searchareaToggle;
      if(this.serachValueUpdated && this.serachValueUpdated.length > 0) {
        this.serachValueUpdated = "";
        this.latestIndex = null;
        this.latestIndexFlag = null;
        this.latestIndexFlagView = null;
        this.toggleIndex = null;
        this.done();
      }
     
    }
    isLanguageSelected(langID): boolean {
      return this.langCode === langID;
    }
    translate(code,fromLoad?) {
      let _this = this;
      if(this.langCode == null) {
        this.feedbackDataFull =[...this.feedbackDataCopy];
      }
      // let base = this.objLanguages.find(data=>data.Language =='English(en-us)');
      if( this.langCode == code && fromLoad != 'fromLoad') {
        this.langCode = null;
        this.translatedFlag = false;
      } else if(this.langCode == code && fromLoad == 'fromLoad') {
        this.translatedFlag = true;
        this.langCode = code;
      } else {
        this.translatedFlag = true;
        this.langCode = code;
        
      }
      if(this.langCode || this.langCode !=null) {
        let translateData = []
        if(this.feedabackDetails && this.feedabackDetails.length > 0) {
          this.feedabackDetails.forEach(element => {
            element.Description.forEach(data => {
            translateData.push(data.ResponseValue);
          });
          });
        }
        let objData = {
          LanguageId: code,
          TextToTranslate: translateData,
        };
        
      
      this.translateCall(objData);
      } else {
        _this.feedabackDetails.forEach((element,index) => {
          element.Description.forEach((data,ind)=> {
            _this.feedabackDetails[index].Description[ind].ResponseValue = _this.feedbackDataFull[index].Description[ind].ResponseValue; 
          })     
        })
      }
      this.langdropdwnToggle = false;
    }
    cardcolor(data,index) {
      if(this.cardClickedIndex != index) {
        this.cardClickedIndex = index;
        this.feedabackDetails[index].Isread = true;
        if(index !=this.latestIndex)
          this.latestIndex = null;
        if(index !=this.latestIndexFlag)
          this.latestIndexFlag = null;
        if(index !=this.toggleIndex)
          this.toggleIndex = null;
        this.latestIndexFlagView = null;
        this.flaggedmsgToggle = false;
        if(index !=this.latestIndexFlag)
          this.flaggedmsgtextToggle = false;
      } else {
        if(this.flaggedmsgToggle)
          this.flaggedmsgToggle = false;
      }    
    }
    dragStart(event: any) {
      this.dragStarted = true;
    }
}