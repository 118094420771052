import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FIELDS } from 'src/app/shared/constants/FIELDS';
import { AuthService } from 'src/app/shared/services/auth-service.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Logger, CryptoUtils } from 'msal';
import { Dropdown } from 'primeng/dropdown';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  blockedUserError: string = '';
  allowRememberLogin: boolean = true; // or fetch from service
  screenOptions = [
    {
        "name": "Bounce Back",
        "code": "BB"
    },
    {
        "name": "Close the Loop Dashboard",
        "code": "CTL"
    },
    {
        "name": "CTLS Survey Feedback Listing",
        "code": "SF"
    },
    {
        "name": "CTLS Task Listing",
        "code": "TL"
    },
    {
        "name": "Customer 360 View",
        "code": "CM360"
    },
    {
        "name": "Customer Feedback",
        "code": "FS"
    },
    {
        "name": "Data and Error Tracking Dashboard",
        "code": "DTD"
    },
    {
        "name": "Distributor 360 View",
        "code": "DT360"
    },
    {
        "name": "File Tracking Dashboard",
        "code": "FTD"
    },
    {
        "name": "Relationship Dashboard",
        "code": "DD"
    },
    {
        "name": "Transactional Dashboard",
        "code": "TD"
    },
    {
        "name": "Voice of Customer",
        "code": "VC"
    }
]; // Populate with your screen options
  collapsed: boolean = false;
  loggedIn = false;
  selectedOption: string = '';
  userData: any;
  showLogin: boolean = false;
  statusMessage: any;
  showLoader: boolean = false;
  isLoading: boolean = false;
  username: string;
  password: string;
  rememberMe: boolean;
  screenKey: any;
  redirect: boolean = false;
  @ViewChild(Dropdown) screensDropdown: Dropdown;
  @ViewChild('falconLogin') falconLogin: ElementRef<HTMLButtonElement>;
  constructor(private fb: FormBuilder, private authService: AuthService, private storageService: StorageService, private router: Router, private broadcastService: BroadcastService, private msalService: MsalService) {
    
    // Check if credentials are stored in localStorage
    if (localStorage.getItem('rememberMe') === 'true') {
      this.username = localStorage.getItem('username') || '';
      this.password = localStorage.getItem('password') || '';
      this.screenKey = '';
      this.selectedOption = this.screenKey;
      this.rememberMe = true;
    }
     
    this.loginForm = this.fb.group({
      username: [this.username, Validators.required],
      password: [this.password, Validators.required],
      rememberLogin: [this.rememberMe],
      screenKey: [this.screenKey]
    });

  }

  ngOnInit() {
    let idToken = localStorage.getItem('msal.idtoken');
    if (idToken != null) {
      this.redirect = true;
      let skey = localStorage.getItem(FIELDS.SELECTED_SCREEN);
      if (this.authService.isAuthenticated()) {

        const loginData = {
          username: this.authService.getUserEmail(),
          password: null,
          screenKey: JSON.parse(skey),
          SSO: true
        };
        this.login(loginData);

      }

    }else{
    // Fetch screen options from a service
    // this.showLoader = true;
    // this.isLoading = true;
    // try{
    //   this.authService.loadScreens().subscribe(rawData => {
    //     const loadScreenResponse = rawData;
    //     this.screenOptions = loadScreenResponse.map((item) => {
    //       return {
    //         name: item.text,
    //         code: item.value
    //       }
    //     });
    //     console.log(this.screenOptions)
    //     this.showLoader = false;
    //     this.isLoading = false;
    //   });
    // }catch(e) {
    //   console.error('loadScreens Error: ', e.errorMessage);
    //   this.showLoader = false;
    //   this.isLoading = false;
    // }

    this.msalService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }

      console.log('Redirect Success: ', response);

      // if (response != null) {
      //   let skey = localStorage.getItem(FIELDS.SELECTED_SCREEN);
      //   if (this.authService.isAuthenticated()) {
 
      //     const loginData = {
      //       username: this.authService.getUserEmail(),
      //       password: null,
      //       screenKey: JSON.parse(skey),
      //       SSO: true
      //     };
      //     this.login(loginData);
 
      //   }
 
      // }

    });

    this.msalService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      console.log('MSAL Logging: ', message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false
    }));
  }
  }

  
  ngAfterViewInit() {
    if(this.screensDropdown && this.screensDropdown.editableInputViewChild){
    this.screensDropdown.renderer.setAttribute(
    this.screensDropdown.editableInputViewChild.nativeElement,
    "autocomplete",
    "no");
    }
    if(this.falconLogin){
      this.falconLogin.nativeElement.focus(); // Set focus to the button
    }
  }

  onFocusOutDD(){
    this.falconLogin.nativeElement.focus(); // Set focus to the button
  }

  handleEnterKey(event){
    console.log('enterKey')
    event.preventDefault();
    if(this.collapsed){
      this.ulogin();
    }else{
      this.falconLogin.nativeElement.click();
    }

  }

  ssoLogin() {
    const selectedScreen = this.loginForm.get('screenKey').value;
    localStorage.setItem(FIELDS.SELECTED_SCREEN, JSON.stringify(selectedScreen));

      this.msalService.loginRedirect();
  }

  toggleLoginArea() {
    this.collapsed = !this.collapsed;
  }

  clearError(field: string) {
    if (this.loginForm.get(field)) {
      this.loginForm.get(field).markAsUntouched();
    }
    this.blockedUserError = '';
    this.statusMessage = '';
  }

  ulogin() {
    const selectedScreen=this.loginForm.get('screenKey').value;
    if (this.loginForm.valid) {
      // Implement your login logic
      const loginData = this.loginForm.value;
      // Call your authentication service
      loginData.SSO = false;
      localStorage.setItem(FIELDS.SELECTED_SCREEN, JSON.stringify(selectedScreen));
      if (this.loginForm.get('rememberLogin').value) {
        // Store credentials in localStorage
        localStorage.setItem('username', loginData.username);
        localStorage.setItem('password', loginData.password);
        localStorage.setItem('rememberMe', 'true');
      } else {
        // Clear localStorage if not checked
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        localStorage.removeItem('rememberMe');
      }
      this.login(loginData)
    } else {
      if (this.loginForm.get('username').invalid && this.loginForm.get('password').invalid) {
        this.statusMessage = 'User ID and password are required';
      } else if (this.loginForm.get('username').invalid) {
        this.statusMessage = 'User ID is required';
      }
      else if (this.loginForm.get('password').invalid) {
        this.statusMessage = 'Password is required';
      }
    }
  }


  login(loginData) {
    this.showLoader = true;
    console.log('screenKey',loginData.screenKey);
    try {
      this.authService
        .userLogin(
          loginData.username,
          loginData.password,
          loginData.screenKey.code,
          loginData.SSO
        )
        .then((response) => {
          if (response && response.status == "Success") {
            this.storageService.saveData(FIELDS.USER_DETAILS, JSON.stringify({
              Username: loginData.username,
              ViewingPage: loginData.screenKey.code
            }));
            localStorage.setItem(FIELDS.ACCESS_TOKEN, response.response.accessToken);
            this.showLoader = false;
            localStorage.removeItem(FIELDS.SELECTED_SCREEN);
            this.router.navigate(['']);
          }else{
            this.showLoader = false;
            localStorage.removeItem(FIELDS.SELECTED_SCREEN);
            if(response.errors[0].code=='BlockedUser' || response.errors[0].code=='AccountNotActive' || response.errors[0].code=='NotExists')
            {
              this.router.navigate(['/auth/lockout']);
            }
            else if(response.errors[0].code=='PasswordExpired')
            {
              this.storageService.saveData(FIELDS.USER_DETAILS, JSON.stringify({
                Username: loginData.username,
                ViewingPage: loginData.screenKey.code
              }));
              localStorage.removeItem(FIELDS.SELECTED_SCREEN);
              this.router.navigate(['/auth/change-password']);
            }
            this.statusMessage = response.errors[0].message;
          }
        });
      }catch(e) {
        this.showLoader = false;
        localStorage.removeItem(FIELDS.SELECTED_SCREEN);
        console.error('Login Error: ', e.errorMessage);
        this.statusMessage = "Internal Server Error; Please try again."
      }
  }


}
