import { CtlsSharedModule } from './Modules/ctls/ctls-shared/ctls-shared.module';
import { CtlsComponent } from './Modules/ctls/ctls.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { FalconSharedModule } from './Modules/falcon/falcon-shared/falcon-shared.module';
import { FalconComponent } from './Modules/falcon/falcon.component';
//import { ResponseRateDonutChartComponent } from './components/dashboard/response-rate-donut-chart/response-rate-donut-chart.component';
import { HeaderFeedBackZoneComponent } from './shared/components/header-feedback-zone/header-feedback-zone.component';
import { HeaderUlLiItemComponent } from './shared/components/header-ul-li-item/header-ul-li-item.component';
import { HeaderUlItemComponent } from './shared/components/header-ul-item/header-ul-item.component';
import { UserProfileComponent } from './components/profile/user-profile/user-profile.component';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { appRouter } from './routes';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToggleButtonModule } from 'primeng/togglebutton';

import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/components/header/header.component';
//import { HeaderNavbarComponent } from './shared/components/header-navbar/header-navbar.component';
import { TopmenuComponent } from './shared/components/topmenu/topmenu.component';
import { DashboardModule } from './components/dashboard/dashboard.module';

//import { ManageSurvayTemplatesComponent } from './components/surveys/manage-survay-templates/manage-survay-templates.component';
//import { AddTemplateComponent } from './components/surveys/add-template/add-template.component';
//import { CreateSurveyTemplateComponent } from './components/surveys/create-survey-template/create-survey-template.component';
//import { QuestionAddToTemplateComponent } from './components/surveys/question-add-to-template/question-add-to-template.component';
//import { SurveyTemplatesComponent } from './components/surveys/survey-templates/survey-templates.component';
// add to specific module
//import { TemplatePreviewComponent } from './components/surveys/template-preview/template-preview.component';
// Star Rating
//import { ManageSurveysComponent } from './components/surveys/manage-surveys/manage-surveys.component';
//import { CreateSurveysComponent } from './components/surveys/create-surveys/create-surveys.component';
//import { StartSurveyComponent } from './components/surveys/survey-library/start-survey/start-survey.component';
//import { AssignTemplateComponent } from './components/surveys/survey-library/assign-template/assign-template.component';
//import { SurveyDetailsComponent } from './components/surveys/survey-library/survey-details/survey-details.component';

//import { SurveySignatureComponent } from './components/surveys/survey-signature/survey-signature.component';
//import { DesignModuleComponent } from './components/surveys/design-module/design-module.component';
//import { TemplateViewComponent } from './components/surveys/survey-library/template-view/template-view.component';

import { CreateSurveyService } from './components/surveys/survey-library/services/create-survey.service';
import { FamMasterService } from './components/fam-management/services/fam-master.service';
import { DialogService, ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/api';
//import { SurveysModule } from './components/surveys/surveys.module';
import { ChartDataService } from './components/dashboard/service/chart-data-service.service';
//import { ManageQuestionsComponent } from './components/surveys/manage-questions/manage-questions.component';
// import { ManageSurvayTemplatesComponent } from './components/surveys/manage-survay-templates/manage-survay-templates.component';
//import { GenericTemplateQuestionsComponent } from './components/surveys/generic-template-questions/generic-template-questions.component';
//import { FileTrackingComponent } from './components/dashboard/file-tracking/file-tracking.component';
//import { SurveyEditSignaturePopupComponent } from './components/surveys/survey-edit-signature-popup/survey-edit-signature-popup.component';
import { from } from 'rxjs';
//import { DesignModulePreviewComponent } from './components/surveys/design-module-preview/design-module-preview.component';
//import { TemplateTranslationLanguageListPopupComponent } from './components/surveys/template-translation-language-list-popup/template-translation-language-list-popup.component';
//import { FamMasterComponent } from './components/fam-management/fam-master/fam-master.component';
//import { XyBusinessRulesComponent } from './components/fam-management/xy-business-rules/xy-business-rules.component';
//import { AddXYRuleComponent } from './components/fam-management/add-xyrule/add-xyrule.component';
//import { OptoutBusinessRuleComponent } from './components/fam-management/optout-business-rule/optout-business-rule.component';
//import { NosendBusinessRuleComponent } from './components/fam-management/nosend-business-rule/nosend-business-rule.component';
//import { ReminderBusinessRuleComponent } from './components/fam-management/reminder-business-rule/reminder-business-rule.component';
//import { FamsaleschannelBusinessRuleComponent } from './components/fam-management/famsaleschannel-business-rule/famsaleschannel-business-rule.component';
//import { SaleschannelBusinessRuleComponent } from './components/fam-management/saleschannel-business-rule/saleschannel-business-rule.component';
//import { FileUploadModule } from 'primeng/fileupload';
//import { DataTrackingComponent } from './components/dashboard/data-tracking/data-tracking.component';
//import { ShowDesignModulesComponent } from './components/surveys/create-design-module/show-design-modules/show-design-modules.component';
//import { BAFAMDIVMappingComponent } from './components/surveys/create-design-module/ba-fam-div-mapping/ba-fam-div-mapping.component';
//import { CreateEmailDesignModuleComponent } from './components/surveys/create-design-module/create-email-design-module/create-email-design-module.component';
//import { CreatePageDesignModuleComponent } from './components/surveys/create-design-module/create-page-design-module/create-page-design-module.component';
//import { SurveySchedularandreminderComponent } from './components/surveys/survey-schedularandreminder/survey-schedularandreminder.component';;
//import { ShowGacListComponent } from './components/falcon-setup/GACCodeExport/show-gac-list/show-gac-list.component';

import { HttpErrorInterceptor } from './errorHandling/http-error.interceptor';
import { GenericGridComponent } from './shared/generic-grid/generic-grid.component';
//import { SendTestEmailComponent } from './components/surveys/survey-library/send-test-email/send-test-email.component';
//import { FeedbackListComponent } from './components/surveys/customer-feedback/feedback-list/feedback-list.component';
//import { FeedbackDetailsComponent } from './components/surveys/customer-feedback/feedback-details/feedback-details.component';
// tslint:disable-next-line:max-line-length
//import { CustomerFeedbackSummaryComponent } from './components/surveys/customer-feedback/customer-feedback-summary/customer-feedback-summary.component';
//import { UserAccessComponent } from './components/falcon-setup/user-management/user-access/user-access.component';
//import { DynamicTooltipDirective } from './dynamic-tooltip/dynamic.tooltip';
//import { DynamicTitleDirective } from './directives/dynamic-title';
import { DynamicTooltipInfluncerDirective } from './dynamic-tooltip/dynamic.tooltipInfluncer';
import { ChartModule } from 'primeng/chart';
// tslint:disable-next-line:max-line-length
//import { FeedbackResendSurveyComponent } from './components/surveys/customer-feedback/feedback-resend-survey/feedback-resend-survey.component';
import { ResponsiveSliderDirective } from './dynamic-tooltip/responsive-slider-width';
//import { BounceBackComponent } from './components/surveys/bounce-back/bounce-back.component';
//import { BounceBackDomainComponent } from './components/surveys/bounce-back-domain/bounce-back-domain.component';
// tslint:disable-next-line:max-line-length
//import { ViewServiceTypeExceptionComponent } from './components/fam-management/fam-summary/view-service-type-exception/view-service-type-exception.component';
//import { ResponseRateChartComponent } from './components/dashboard/response-rate-chart/response-rate-chart.component';
// import { DonutChartComponent } from './components/dashboard/donut-chart/donut-chart.component';

//import { CloneUserComponent } from './components/falcon-setup/user-management/clone-user/clone-user.component';

import { AccessDeniedComponent } from './shared/components/Errors/access-denied/access-denied.component';
import { UnexpectedErrorComponent } from './shared/components/Errors/unexpected-error/unexpected-error.component';

import { SharedService } from '../app/shared/services/shared.service';
import { ExportChartsService } from './shared/services/export-charts.service';
import { ExportChartDataService } from './shared/services/export-chart-data.service';

//import { AddNoSendTemplateComponent } from './components/fam-management/add-no-send-template/add-no-send-template.component';
import { SharedModule } from './shared/shared-module';
//import { NpsByTouchpointComponent } from './components/dashboard/nps-by-touchpoint/nps-by-touchpoint.component';
//import { NpsByTouchPointWidgetComponent } from './components/dashboard/nps-by-touch-point-widget/nps-by-touch-point-widget.component';
//import { FeedbackDetailsRelationshipComponent } from './components/surveys/customer-feedback/feedback-details-relationship/feedback-details-relationship.component';
//import { FeedbackQuestionnaireComponent } from './components/surveys/customer-feedback/feedback-questionnaire/feedback-questionnaire.component';

//import { WidgetExportComponent } from './shared/components/widget-export/widget-export.component';
//import { ProgressBarComponent } from './shared/components/progress-bar/progress-bar.component';
import { UserFeedbackComponent } from 'src/app/shared/components/user-feedback/user-feedback.component';
import { OnlineHelpDirective } from 'src/app/shared/directives/online-help.directive';
import { DistributorsComponent } from './Modules/distributor-master/components/distributors/distributors.component';
import { CompanyComponent } from './Modules/distributor-master/components/company/company.component';
import { ContactsComponent } from './Modules/distributor-master/components/contacts/contacts.component';
import { DynamicTooltipDistributorMasterDirective } from './dynamic-tooltip/dynamicTooltipDistributorMaster';
import { RevenueComponent } from './Modules/distributor-master/components/revenue/revenue.component';
import { DistributorContactsComponent } from './Modules/distributor-master/components/distributor-contacts/distributor-contacts.component';
import { SegmentComponent } from './Modules/distributor-master/components/segment/segment.component';
import { CompanyContactEditPopComponent } from './Modules/distributor-master/components/company-contact-edit-pop/company-contact-edit-pop.component';
import { MarkedForSurveyComponent } from './Modules/distributor-master/components/marked-for-survey/marked-for-survey.component';
import { TwoDigitDecimaNumberDirective } from './shared/directives/two-digit-decimal-number.directive';
// import { TableModule } from '../../node_modules/primeng/table';
import { PaginatorModule } from '../../node_modules/primeng/paginator';
// import { DropdownModule } from '../../node_modules/primeng/dropdown';
import { TooltipModule } from '../../node_modules/primeng/tooltip';
import { MessagesModule } from '../../node_modules/primeng/messages';
import { MessageModule } from '../../node_modules/primeng/message';
// import { DialogModule } from '../../node_modules/primeng/dialog';
//import { CurrencyMasterListComponent } from './Modules/currency-master/components/currency-master-list/currency-master-list.component';
import { CurrencyMasterService } from './Modules/currency-master/Services/currency-master.service';
import { RevenueReportListComponent } from './Modules/revenue-report/components/revenue-report-list/revenue-report-list.component';
import { RevenueReportService } from './Modules/revenue-report/services/revenue-report.service';
//import { CreateRelationshipSurveyComponent } from './Modules/relationship-survey/components/create-relationship-survey/create-relationship-survey.component';
//import { RelationshipSurveyDetailsComponent } from './Modules/relationship-survey/components/relationship-survey-details/relationship-survey-details.component';
//import { QuestionnaireComponent } from './Modules/relationship-survey/components/questionnaire/questionnaire.component';
//import { ConfigurationsComponent } from './Modules/relationship-survey/components/configurations/configurations.component';
//import { InstancesComponent } from './Modules/relationship-survey/components/instances/instances.component';
//import { BusinessMappingExclusionsComponent } from './Modules/relationship-survey/components/instances/business-mapping-exclusions/business-mapping-exclusions.component';
//import { BusinessMappingActiveGridComponent } from './Modules/relationship-survey/components/instances/business-mapping-active-grid/business-mapping-active-grid.component';
//import { BusinessMappingHistoryGridComponent } from './Modules/relationship-survey/components/instances/business-mapping-history-grid/business-mapping-history-grid.component';
//import { InstanceEditComponent } from './Modules/relationship-survey/components/instances/instance-edit/instance-edit.component';
//import { SurveyStarRangeComponent } from './Modules/relationship-survey/components/survey-star-range/survey-star-range.component';
//import { SearchCategoryDialogComponent } from './Modules/relationship-survey/components/search-category-dialog/search-category-dialog.component';
//import { CategoryDetailDialogComponent } from './Modules/relationship-survey/components/category-detail-dialog/category-detail-dialog.component';
//import { AddFromTemplateDialogComponent } from './Modules/relationship-survey/components/add-from-template-dialog/add-from-template-dialog.component';
//import { AddLogicDialogComponent } from './Modules/relationship-survey/components/add-logic-dialog/add-logic-dialog.component';
//import { TemplatePreviewDialogComponent } from './Modules/relationship-survey/components/template-preview-dialog/template-preview-dialog.component';
//import { SaveTemplateDialogComponent } from './Modules/relationship-survey/components/save-template-dialog/save-template-dialog.component';
//import { ManageRelationshipSurveyComponent } from './Modules/relationship-survey/components/manage-relationship-survey/manage-relationship-survey.component';
import { ScrollPanelModule } from '../../node_modules/primeng/scrollpanel';
import { PrimengModule } from './primeng.module';
//import { AddEditAuthorityComponent } from './components/fam-management/add-edit-authority/add-edit-authority.component';
//import { AddFamComponent } from './components/fam-management/add-fam/add-fam.component';
//import { AddNoSendTemplateComponent } from './components/fam-management/add-no-send-template/add-no-send-template.component';
//import { AuthorityComponent } from './components/fam-management/authority/authority.component';
//import { BranchesComponent } from './components/fam-management/branches/branches.component';
//import { BranchIstoryComponent } from './components/fam-management/branch-istory/branch-istory.component';
//import { BrandManagementComponent } from './components/fam-management/brand-management/brand-management.component';
//import { BrandsComponent } from './components/fam-management/brands/brands.component';
//import { DivBlMappingComponent } from './components/fam-management/div-bl-mapping/div-bl-mapping.component';
//import { DRBusinessRuleComponent } from './components/fam-management/dr-business-rule/dr-business-rule.component';
//import { EditFamComponent } from './components/fam-management/edit-fam/edit-fam.component';
//import { FamMasterStepComponent } from './components/fam-management/fam-master-step/fam-master-step.component';
//import { LanguagesComponent } from './components/fam-management/languages/languages.component';
//import { MergeBranchComponent } from './components/fam-management/merge-branch/merge-branch.component';
//import { MergeZoneComponent } from './components/fam-management/merge-zone/merge-zone.component';
//import { OptoutBusinessRuleComponent } from './components/fam-management/optout-business-rule/optout-business-rule.component';
//import { OtherDetailsComponent } from './components/fam-management/other-details/other-details.component';
//import { OutboundTriggerComponent } from './components/fam-management/outbound-trigger/outbound-trigger.component';
//import { SalesEngineersComponent } from './components/fam-management/sales-engineers/sales-engineers.component';
//import { ServiceTechniciansComponent } from './components/fam-management/service-technicians/service-technicians.component';
//import { SestComponent } from './components/fam-management/sest/sest.component';
//import { StBusinessRuleComponent } from './components/fam-management/st-business-rule/st-business-rule.component';
//import { XyBusinessRulesComponent } from './components/fam-management/xy-business-rules/xy-business-rules.component';
//import { ViewFamComponent } from './components/fam-management/view-fam/view-fam.component';
//import { SurveyDesignComponent } from './components/fam-management/survey-design/survey-design.component';
//import { ZonesBranchesComponent } from './components/fam-management/zones-branches/zones-branches.component';
//import { ZonesComponent } from './components/fam-management/zones/zones.component';
//import { ZoneBranchMappingComponent } from './components/fam-management/zone-branch-mapping/zone-branch-mapping.component';
//import { ViewDIVBrandsLanguagesComponent } from './components/fam-management/fam-summary/view-div-brands-languages/view-div-brands-languages.component';
//import { ViewBrandsLanguagesComponent } from './components/fam-management/fam-summary/view-brands-languages/view-brands-languages.component';
//import { ViewOtherDetailsComponent } from './components/fam-management/fam-summary/view-other-details/view-other-details.component';
//import { ViewZoneBranchesComponent } from './components/fam-management/fam-summary/view-zone-branches/view-zone-branches.component';
//import { ViewSalesEngineersServiceTechniciansComponent } from './components/fam-management/fam-summary/view-sales-engineers-service-technicians/view-sales-engineers-service-technicians.component';
//import { ViewSalesEngineersComponent } from './components/fam-management/fam-summary/view-sales-engineers/view-sales-engineers.component';
//import { ViewServiceTechniciansComponent } from './components/fam-management/fam-summary/view-service-technicians/view-service-technicians.component';
//import { ViewSaleChannelExceptionComponent } from './components/fam-management/fam-summary/view-sale-channel-exception/view-sale-channel-exception.component';
//import { ViewServiceTypeExceptionComponent } from './components/fam-management/fam-summary/view-service-type-exception/view-service-type-exception.component';
//import { ViewZoneComponent } from './components/fam-management/fam-summary/view-zone/view-zone.component';
//import { ViewBranchComponent } from './components/fam-management/fam-summary/view-branch/view-branch.component';
//import { ViewZoneBranchMappingComponent } from './components/fam-management/fam-summary/view-zone-branch-mapping/view-zone-branch-mapping.component';
// import { MultiSelectModule } from '../../node_modules/primeng/multiselect';
//import { SurveyLibraryComponent } from './components/surveys/survey-library/survey-library/survey-library.component';
//import { CreateSurveyComponent } from './components/surveys/survey-library/create-survey/create-survey.component';
//import { BuisnessMappingComponent } from './components/surveys/survey-library/buisness-mapping/buisness-mapping.component';
//import { FamMappingComponent } from './components/surveys/survey-library/fam-mapping/fam-mapping.component';
//import { BrandMappingComponent } from './components/surveys/survey-library/brand-mapping/brand-mapping.component';
//import { SelectTemplatesComponent } from './components/surveys/survey-library/select-templates/select-templates.component';
//import { PendingInfoSurveyComponent } from './components/surveys/survey-library/pending-info-survey/pending-info-survey.component';
//import { SendTestEmailComponent } from './components/surveys/survey-library/send-test-email/send-test-email.component';
//import { AccessComponent } from './components/falcon-setup/access/access.component';
//import { UserManagementComponent } from './components/falcon-setup/user-management/user-management.component';
//import { AddUserComponent } from './components/falcon-setup/user-management/add-user/add-user.component';
//import { UserAccessComponent } from './components/falcon-setup/user-management/user-access/user-access.component';
//import { UserRolesComponent } from './components/falcon-setup/user-management/user-roles/user-roles.component';
//import { AddAccessComponent } from './components/falcon-setup/user-management/add-access/add-access.component';
import { CloneUserComponent } from './components/falcon-setup/user-management/clone-user/clone-user.component';
//import { CreateDesignComponent } from './components/surveys/survey-design/create-design/create-design.component';
//import { SurveyDesignComponent } from './components/surveys/survey-design/survey-design/survey-design.component';
//import { DesignDetailsComponent } from './components/surveys/survey-design/design-details/design-details.component';
//import { EmailDesignComponent } from './components/surveys/survey-design/email-design/email-design.component';
//import { SurveyQuestionnaireComponent } from './components/surveys/survey-design/survey-questionnaire/survey-questionnaire.component';
//import { ViewDesignComponent } from './components/surveys/survey-design/view-design/view-design.component';
import { AdvancedSearchComponent } from './Modules/Distributor_Survey/components/advanced-search/advanced-search.component';
import { DashboardComponent } from './Modules/Distributor_Survey/components/dashboard/dashboard.component';
import { DistributorSurveyModule } from './Modules/Distributor_Survey/distributor-survey.module';
//import { NpsByCountriesComponent } from './Modules/Distributor_Survey/components/nps-by-countries/nps-by-countries.component';
//import { Top3QuestionsComponent } from './Modules/Distributor_Survey/components/top3-questions/top3-questions.component';
//import { TopTenDistributorsComponent } from './Modules/Distributor_Survey/components/top-ten-distributors/top-ten-distributors.component';
//import { RelationshipDistsurveyTopheaderComponent } from './Modules/Distributor_Survey/components/relationship-distsurvey-topheader/relationship-distsurvey-topheader.component';
//import { RelationshipStoryboardComponent } from './Modules/Distributor_Survey/components/relationship-storyboard/relationship-storyboard.component';
//import { RelationalChartListingComponent } from './Modules/Distributor_Survey/components/chartlisting.component/relational.chartlisting.component';
//import { NpsWorldChartComponent } from './Modules/Distributor_Survey/components/nps-world-chart/nps-world-chart.component';
//import { ResponseRateLineChartComponent } from './Modules/Distributor_Survey/components/response-rate-line-chart/response-rate-line-chart.component';
//import { NpsCountriesChartComponent } from './Modules/Distributor_Survey/components/nps-countries-chart/nps-countries-chart.component';
//import { SurveyInstanceDropdownComponent } from './Modules/Distributor_Survey/components/survey-instance-dropdown/survey-instance-dropdown.component';
//import { SurveyInstanceListViewComponent } from './Modules/Distributor_Survey/components/survey-instance-list-view/survey-instance-list-view.component';
//import { StackBarChartComponentRelational } from './Modules/Distributor_Survey/components/chartlisting.component/stack-bar-chart/stack-bar-chart.component';
//import { Top3QuestionsDetailsComponent } from './Modules/Distributor_Survey/components/top3-questions-details/top3-questions-details.component';
//import { TopThreeQuestionsDetailsComponent } from './Modules/Distributor_Survey/components/top-three-questions-details/top-three-questions-details.component';
//import { TopThreeProgressbarComponent } from './Modules/Distributor_Survey/components/top-three-progressbar/top-three-progressbar.component';
//import { Top3FreeTextComponent } from './Modules/Distributor_Survey/components/top3-free-text/top3-free-text.component';
//import { StoryboardSliderComponent } from './Modules/Distributor_Survey/components/storyboard-slider/storyboard-slider.component';
//import { NpsResponselistDistributorComponent } from './Modules/Distributor_Survey/components/nps-responselist-distributor/nps-responselist-distributor.component';
//import { EditSurveyTimingsComponent } from './components/surveys/survey-timing/edit-survey-timings/edit-survey-timings.component';
//import { ManageSurveyTimingsComponent } from './components/surveys/survey-timing/manage-survey-timings/manage-survey-timings.component';
//import { HomeComponent } from './components/dashboard/home/home.component';
//import { ChartExportComponent } from './components/dashboard/chartlisting.component/chart-export/chart-export.component';
//import { FilterComponent1 } from './shared/components/filter/filter.component';
//import { PublishDesignModuleComponent } from './components/surveys/create-design-module/publish-design-module/publish-design-module.component';
//import { FeedbackFilterPanelComponent } from './components/surveys/customer-feedback/feedback-filter-panel/feedback-filter-panel.component';

import { MaintenanceComponent } from './components/maintenance/maintenance.component';
//import { RegionComponent } from './components/master-data/region/region.component';

/* Customer Master Starts */
import { CustomersComponent } from './Modules/customer-master/components/customers/customers.component';
import { CustomerCompanyComponent } from './Modules/customer-master/components/customer-company/customer-company.component';
import { CustomerContactsComponent } from './Modules/customer-master/components/customer-contacts/customer-contacts.component';
import { CustomerCompanyContactEditPopComponent } from './Modules/customer-master/components/customer-company-contact-edit-pop/customer-company-contact-edit-pop.component';
import { ViewExclusionsComponent } from './Modules/customer-master/components/view-exclusions/view-exclusions.component';
import { AddRemoveExclusionComponent } from './Modules/customer-master/components/add-remove-exclusion/add-remove-exclusion.component';
import { AddEditCompanyComponent } from './Modules/customer-master/components/add-edit-company/add-edit-company.component';
import { AddEditContactComponent } from './Modules/customer-master/components/add-edit-contact/add-edit-contact.component';
import { AddEditCompanyRightPanelComponent } from './Modules/customer-master/components/add-edit-company-right-panel/add-edit-company-right-panel.component';
/* Cusomter Master Ends */
import { HeaderCtlsComponent } from './shared/components/header-ctls/header-ctls.component';
import { CtlsModule } from './Modules/ctls/ctls.module';
import { FalconModule } from './Modules/falcon/falcon.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CtlsCustomPreloadingStrategyService } from './Modules/ctls/ctls-shared/ctls-services/ctls-custom-preloading-strategy.service';
import { NotificationComponent } from '../app/shared/components/notification/notification.component';
//import { AddRegionComponent } from './components/master-data/add-region/add-region.component';
//import { AddBusinessAreaComponent } from './components/master-data/add-business-area/add-business-area.component';
//import { BusinessAreaComponent } from './components/master-data/business-area/business-area.component';
//import { DivisionComponent } from './components/master-data/division/division.component';
//import { CurrencyComponent } from './components/master-data/currency/currency.component';
//import { CountryComponent } from './components/master-data/country/country.component';
//import { TimezoneComponent } from './components/master-data/timezone/timezone.component';
//import { GacComponent } from './components/master-data/gac/gac.component';
//import { BusinessLineComponent } from './components/master-data/business-line/business-line.component';
//import { BrandComponent } from './components/master-data/brand/brand.component';




import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { TreeModule } from 'primeng/tree';
//import { LazyloadVirtualscroll } from './components/master-data/lazyload-virtualscroll/lazyload-virtualscroll.component';
//import { TouchpointComponent } from './components/master-data/touchpoint/touchpoint.component';
//import { ServiceTypeComponent } from './components/master-data/service-type/service-type.component';
//import { InfluenceLevelComponent } from './components/master-data/influence-level/influence-level.component';
//import { SalesChannelComponent } from './components/master-data/sales-channel/sales-channel.component';
//import { BranchComponent } from './components/master-data/branch/branch.component';
//import { ZoneComponent } from './components/master-data/zone/zone.component';

// import { BubbleChartRelationalComponent } from './Modules/Distributor_Survey/components/revenuelist.component/bubble-chart/bubble-chart.component';
// import { RelationalRevenueListComponent } from './Modules/Distributor_Survey/components/revenuelist.component/relational.revenuelist.component';

// import { RelationalNoResponseRevenueChartComponent } from './Modules/Distributor_Survey/components/noresponserevenuechart.component/relational.noresponserevenuechart.component';
// import { BubbleChartComponentRelational } from './Modules/Distributor_Survey/components/noresponserevenuechart.component/bubble-chart/bubble-chart.component';
// import { RelationalRevenueAtRisknpsChartComponent } from './Modules/Distributor_Survey/components/revenueatrisknpschart.component/relational.revenueatrisknpschart.component';
// import { BubbleChartRevenueAtRisknpsComponentRelational } from './Modules/Distributor_Survey/components/revenueatrisknpschart.component/bubble-chart/bubble-chart.component';
// import { ManageHttpInterceptor } from './errorHandling/managehttp.interceptor';
// import { HttpCancelService } from './shared/services/httpCancel.service';
//import { CurrencyMasterModule } from './Modules/currency-master/currency-master.module';
import { CustomerMasterModule } from './Modules/customer-master/customer-master.module';
//import { DistributorMasterModule } from './Modules/distributor-master/distributor-master.module';
import { RevenueReportModule } from './Modules/revenue-report/revenue-report.module';
//import { RelationshipSurveyModule } from './Modules/relationship-survey/relationship-survey.module';
//import { FalconSetupModule } from './components/falcon-setup/falcon-setup.module';
import { FamManagementModule } from './components/fam-management/fam-management.module';
// import { MasterDataModule } from './components/master-data/master-data.module';
import { SurveysModule } from './components/surveys/surveys.module';
import { HttpCancelService } from './shared/services/http-cancel.service';
import { SigninOidcComponent } from './signin-oidc/signin-oidc.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { StartupComponent } from './startup/startup.component';
import { SignoutCallbackOidcComponent } from './signout-callback-oidc/signout-callback-oidc.component';
import { DynamicComponentService } from './shared/services/dynamic-component.service';
import { HeatMapDetailsPopupComponent } from './Modules/ctls/ctls-shared/heat-map-details-popup/heat-map-details-popup.component';
import { HeatMapDetailsListComponent } from './Modules/ctls/ctls-shared/heat-map-details-list/heat-map-details-list.component';
import { HeatMapModule } from './Modules/ctls/ctls-shared/heat-map/heat-map.module';
import { TreeTableModule } from 'primeng/treetable';
import { ExportDistChartDataService } from './shared/services/export-dist-chart-data.service';
import { ExportDistChartsService } from './shared/services/export-dist-charts-service';




const appInitializerFn = (appConfig: SharedService) => {
  return () => {
    // window.location.href = 'http://localhost:4200/start-up';
    return appConfig.loadConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    // ProgressBarComponent,
    HeaderUlItemComponent,
    HeaderUlLiItemComponent,
    HeaderFeedBackZoneComponent,
    HeaderCtlsComponent,
    //HeaderNavbarComponent,
    TopmenuComponent,
    // ManageQuestionsComponent,
    //ManageSurvayTemplatesComponent,
    //AddTemplateComponent,
    //CreateSurveyTemplateComponent,
    //QuestionAddToTemplateComponent,
    //SurveyTemplatesComponent,
    // TemplatePreviewComponent,

    //SurveyTemplatesComponent,
    //ManageSurveysComponent,
    //CreateSurveysComponent,
    //StartSurveyComponent,
    //FileTrackingComponent,
    //SurveyDetailsComponent,
    //DesignModuleComponent,
    //SurveySignatureComponent,
    //TemplateViewComponent,
    // RadioScaleComponent,
    //GenericTemplateQuestionsComponent,
    //SurveyEditSignaturePopupComponent,
    //DesignModulePreviewComponent,
    //TemplateTranslationLanguageListPopupComponent,
    //AssignTemplateComponent,
    //FamMasterComponent,
    //AddXYRuleComponent,
    //NosendBusinessRuleComponent,
    //ReminderBusinessRuleComponent,
    //FamsaleschannelBusinessRuleComponent,
    //SaleschannelBusinessRuleComponent,
    //DataTrackingComponent,

    //ShowDesignModulesComponent,
    //CreateEmailDesignModuleComponent,
    //CreatePageDesignModuleComponent,

    //BAFAMDIVMappingComponent,
    //ShowGacListComponent,
    //GenericGridComponent,
    //FeedbackListComponent,
    //CustomerFeedbackSummaryComponent,
    //FeedbackResendSurveyComponent,
    //FeedbackDetailsComponent,
    //FeedbackDetailsRelationshipComponent,
    //FeedbackQuestionnaireComponent,
    DynamicTooltipInfluncerDirective,
    ResponsiveSliderDirective,
    //BounceBackComponent,
    //BounceBackDomainComponent,
    UserProfileComponent,
    // SurveySchedularandreminderComponent,
    // ResponseRateChartComponent,
    AccessDeniedComponent,
    UnexpectedErrorComponent,
    //NpsByTouchpointComponent,
    // NpsByTouchPointWidgetComponent,
    UserFeedbackComponent,
    OnlineHelpDirective,
    // add for aot build to work start------------------


    //----distributor master-module start
    //DistributorsComponent,
    //CompanyComponent,
    //ContactsComponent,
    DynamicTooltipDistributorMasterDirective,
    //RevenueComponent,
    //DistributorContactsComponent,
    //SegmentComponent,
    //CompanyContactEditPopComponent,
    //MarkedForSurveyComponent,
    TwoDigitDecimaNumberDirective,

    //CurrencyMasterListComponent,

    // RevenueReportListComponent,

    //------------------------- relationship survey module start
    //ManageRelationshipSurveyComponent,
    //CreateRelationshipSurveyComponent,
    //RelationshipSurveyDetailsComponent,
    //QuestionnaireComponent,
    // ConfigurationsComponent,
    //InstancesComponent,
    // BusinessMappingExclusionsComponent,
    // BusinessMappingActiveGridComponent,
    // BusinessMappingHistoryGridComponent,
    // InstanceEditComponent,
    // ScrollSpyDirective,
    //SurveyStarRangeComponent,
    //SearchCategoryDialogComponent,
    //CategoryDetailDialogComponent,
    //AddFromTemplateDialogComponent,
    //AddLogicDialogComponent,
    //TemplatePreviewDialogComponent,
    //SaveTemplateDialogComponent,
    // DeleteQuestionsListComponent 
    // --------------- fam management module to starts
    //XyBusinessRulesComponent,
    //OptoutBusinessRuleComponent,
    //AddFamComponent,
    //EditFamComponent,
    //ViewFamComponent,
    //StBusinessRuleComponent,
    //DRBusinessRuleComponent,
    //FamMasterStepComponent,
    //DivBlMappingComponent,
    //OtherDetailsComponent,
    //BrandsComponent,
    //LanguagesComponent,
    //AuthorityComponent,
    //AddEditAuthorityComponent,
    //ZonesBranchesComponent,
    //ZonesComponent,
    //BranchesComponent,
    // MergeBranchComponent,
    //BranchIstoryComponent,
    //ZoneBranchMappingComponent,
    //SestComponent,
    //SalesEngineersComponent,
    //ServiceTechniciansComponent,
    //MergeZoneComponent,
    //BrandManagementComponent,
    // ViewDIVBrandsLanguagesComponent,
    //ViewBrandsLanguagesComponent,
    //ViewOtherDetailsComponent,
    //ViewZoneBranchesComponent,
    //ViewSalesEngineersServiceTechniciansComponent,
    //ViewSalesEngineersComponent,
    //ViewServiceTechniciansComponent,
    //OutboundTriggerComponent,
    // ViewSaleChannelExceptionComponent,
    //ViewServiceTypeExceptionComponent,
    //ViewZoneComponent,
    //ViewBranchComponent,
    //ViewZoneBranchMappingComponent,
    //AddNoSendTemplateComponent,
    //AddRegionComponent,
    //----survey library module starts
    //SurveyLibraryComponent,
    //SurveyDetailsComponent,
    //SendTestEmailComponent,
    //CreateSurveyComponent,
    //BuisnessMappingComponent,
    //FamMappingComponent,
    //BrandMappingComponent,
    //SelectTemplatesComponent,
    //PendingInfoSurveyComponent,
    //SendTestEmailComponent,
    // --------------- falcon setup start
    //AccessComponent,
    // UserManagementComponent,
    //AddUserComponent,
    //UserAccessComponent,
    //UserRolesComponent,
    //AddAccessComponent,
    //CloneUserComponent,
    //--------------- survey design module start
    //SurveyDesignComponent,
    // CreateDesignComponent,
    //DesignDetailsComponent,
    //EmailDesignComponent,
    //SurveyQuestionnaireComponent,
    //ViewDesignComponent,
    //--- relationshi-p dashboard start-----------------------
    // GlobalFilterDistributorComponent,
    // DashboardComponent,

    AdvancedSearchComponent,
    //DashboardComponent,
    //NpsByCountriesComponent,
    //Top3QuestionsComponent,
    //TopTenDistributorsComponent,
    //RelationshipDistsurveyTopheaderComponent,
    //RelationshipStoryboardComponent,
    //RelationalChartListingComponent,
    //RelationalRevenueListComponent,
    //BubbleChartRelationalComponent,
    // RelationalNoResponseRevenueChartComponent,
    // BubbleChartComponentRelational,

    // RelationalRevenueAtRisknpsChartComponent,
    // BubbleChartRevenueAtRisknpsComponentRelational,
    //NpsWorldChartComponent,
    //ResponseRateLineChartComponent,
    // NpsCountriesChartComponent,
    //SurveyInstanceDropdownComponent,
    //SurveyInstanceListViewComponent,
    //StackBarChartComponentRelational,
    //Top3QuestionsDetailsComponent,

    //TopThreeQuestionsDetailsComponent,
    //TopThreeProgressbarComponent,
    //Top3FreeTextComponent,
    //StoryboardSliderComponent,
    // ViewSelectorDistributorComponent
    //NpsResponselistDistributorComponent,

    //--------- survey timing module start----------
    // ManageSurveyTimingsComponent,
    //EditSurveyTimingsComponent,
    //FilterComponent1
    //HomeComponent,
    //ChartExportComponent,
    //PublishDesignModuleComponent,
    //FeedbackFilterPanelComponent,
    //CustomersComponent,
    //CustomerCompanyComponent,
    //CustomerContactsComponent,
    // CustomerCompanyContactEditPopComponent,
    // ViewExclusionsComponent,
    // AddRemoveExclusionComponent,
    // AddEditContactComponent,
    // AddEditCompanyComponent,
    // AddEditCompanyRightPanelComponent,
    NotificationComponent,
    MaintenanceComponent,
    SigninOidcComponent,
    //RegionComponent,

    //----- Mater Data ------
    //BusinessAreaComponent,
    //AddBusinessAreaComponent,
    //DivisionComponent,
    //CurrencyComponent,
    //CountryComponent,
    //TimezoneComponent,
    //GacComponent,
    //BusinessLineComponent,
    //BrandComponent,
    //LazyloadVirtualscroll,
    //TouchpointComponent,
    //ServiceTypeComponent,
    //InfluenceLevelComponent,
    //SalesChannelComponent,
    //BranchComponent,
    //ZoneComponent,
    SigninOidcComponent,
    StartupComponent,
    SignoutCallbackOidcComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRouter),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    //DashboardModule,
    ChartModule,
    // SurveyDesignModule,
    SharedModule,
    TableModule,
    PaginatorModule,
    DropdownModule,
    TooltipModule,
    MessagesModule,
    MessageModule,
    DialogModule,
    ConfirmDialogModule,
    ScrollPanelModule,
    PrimengModule,
    MultiSelectModule,
    TreeModule,
    TreeTableModule,
    //CtlsModule,
    CtlsSharedModule,
    ToggleButtonModule,
    //FalconModule,
    FalconSharedModule,
    HeatMapModule,
    //CurrencyMasterModule,
    //CustomerMasterModule,
    //DistributorMasterModule,
    //RevenueReportModule,
    //RelationshipSurveyModule,
   // DistributorSurveyModule,
    //FalconSetupModule,
    //FamManagementModule,
    // MasterDataModule,
    //SurveysModule
    OAuthModule.forRoot()

  ],
  // tslint:disable-next-line:max-line-length
  providers: [DialogService, DynamicDialogConfig, DynamicDialogRef, FamMasterService, ChartDataService, CreateSurveyService, ConfirmationService,HttpCancelService,DynamicComponentService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    SharedService, ExportChartsService, ExportChartDataService,ExportDistChartDataService,ExportDistChartsService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [SharedService]
    },
    Title,
    CurrencyMasterService,
    RevenueReportService
  ],
  entryComponents: [
    //AddTemplateComponent,
    //QuestionAddToTemplateComponent,
    //CreateSurveysComponent,
    //StartSurveyComponent,
    //TemplateViewComponent,
    //SurveyEditSignaturePopupComponent,
    //DesignModulePreviewComponent,
    //AssignTemplateComponent,
    //AddXYRuleComponent,
    UserProfileComponent,
    UserFeedbackComponent,
    //ViewFamComponent,
    //BranchIstoryComponent,
    //AddEditAuthorityComponent,
    // MergeBranchComponent,
    //MergeZoneComponent,
    //AddNoSendTemplateComponent,
    //PendingInfoSurveyComponent,
    // SendTestEmailComponent,
    //AddAccessComponent,
    //CloneUserComponent,
    //ViewDesignComponent,
    AdvancedSearchComponent,
    //AddRegionComponent,
    //BusinessAreaComponent,
    //AddBusinessAreaComponent,
    HeatMapDetailsPopupComponent,
    HeatMapDetailsListComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
  // exports: [ProgressBarComponent]
})
export class AppModule { }
