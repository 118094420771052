import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as Rx from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ChartSharedService {

    chartToggleParamBehaviousSubject = new Rx.BehaviorSubject('Month');
    widgetLeftLocationSubject = new Rx.BehaviorSubject<any>('WidgetLeftLocation');
    // widgetNpsLocationSubject = new Rx.BehaviorSubject<any>('WidgetNpsLocation');
    widgetCenterLocationSubject = new Rx.BehaviorSubject<any>('WidgetCenterLocation');
    widgetDDLocationSubject = new Rx.BehaviorSubject<any>('WidgetDDLocation');
    widgetSavedOrderListSubject = new Rx.BehaviorSubject<any>('WidgetSavedOrderlist');
    widgetCloseSubject = new Rx.BehaviorSubject<any>('widgetClose');
    
    dashboardStateSubject = new Rx.BehaviorSubject<string>('');
    STORYBOARD_VIEW: string = 'storyboard';
    TRENDLINE_VIEW: string = 'chartview';
    EMAIL_VIEW: string = 'emailview';

    exportFamComparison: string[] = ['zone', 'branch', 'engineer', 'technician'];
    updateChartToggleParameter(chartViewBy: string) {
        this.chartToggleParamBehaviousSubject.next(chartViewBy);
    }

    changeWidgetLeftLocation(widgetlocationLeft:any){
        this.widgetLeftLocationSubject.next(widgetlocationLeft);
    }

    // changeWidgetNpsLocation(widgetLocationNps: any){
    //     this.widgetNpsLocationSubject.next(widgetLocationNps);
    // }

    changeWidgetCenterLocation(widgetLocationCenter: any){
        this.widgetCenterLocationSubject.next(widgetLocationCenter);
    }

    changeWidgetDDLocation(widgetLocationDD: any){
        this.widgetDDLocationSubject.next(widgetLocationDD);
    }

    changeWidgetSavedOrderList(widgetOrderSavedMsg: any){
        this.widgetSavedOrderListSubject.next(widgetOrderSavedMsg);
    }

    // Appsettings - Shared code

  constructor(private http: HttpClient) { }
}


