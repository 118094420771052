
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { RelationshipFilterService } from 'src/app/shared/services/filter-relationship.service';
import { DistDashbaordService } from '../../Services/dist-dashbaord.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-download-excel',
  templateUrl: './download-excel.component.html',
  styleUrls: ['./download-excel.component.scss']
})
export class DownloadExcelComponent implements OnInit {
  pptExportBlockUI: boolean = false;
  msgs: any = [];
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  genericErrorMessage = 'An Error occurred, please contact ' + this.sharedService.configuration.ContactUsEmail;
  successMsg: any = false;
  ShowError: boolean;

  @Output() tabEvent = new EventEmitter();
  @Input() seletedCategory :any;
  loading: any = false;
  successMsgForDownload: any = true;
  parameter: any = {
    StartDate: null,
    EndDate: null,
    PeriodType: null,
    BACode: null,
    DivCode: null,
    BLCode: null,
    Country: null,
    FAMCode: null,
    BrandName: null,
    Zone: null,
    Branch: null,
    SalesEngineer: null,
    ServiceTechnician: null,
    Touchpoint: null,
    Continent: null,
    Region: null,
    Influencer: null,
    RequiredInSurveyLanguage: 'true',
    ReportType: ''
  };
  selectedValue: string = 'val1';
  finishedCount: number = 0;
  XLListlength: number;
  showDownloadPanel: boolean = false;
  results: any[] = [];
  XLList: string[] = [];
  count: number;
  checked: boolean = false;
  public dataDownloadPermissionCheck: boolean = true;
  constructor(private filterService: RelationshipFilterService
    , private distDashboardService: DistDashbaordService
    , private sharedService: SharedService) { }

  ngOnInit() {
  }
  ngOnChanges() {
    console.log(this.seletedCategory)
  }
  exportData(event) {

    let fileName;

    if (event === 'NPS') {
      fileName = 'NPS';
      this.parameter.ReportType = 'NPS';
      this.loading = true;
      this.responseRateExport(this.parameter, fileName);
    } else if (event === 'RESPONSE') {
      fileName = 'ResponseRate';
      this.parameter.ReportType = 'RESPONSE';
      this.loading = true;
      this.responseRateExport(this.parameter, fileName);
    }

    // else if (event.toString().indexOf('CHARTS_') > -1) {
    //   // this.sharedService.updateChartExportSubject(event.toString());
    //   this.showDownloadPanel = false;
    //   setTimeout(() => {
    //     this.sharedService.updateChartExportSubject(event.toString());
    //     // this.exportChartsToReports(event.toString().split('_')[1]);
    //   }, 300);
    // }
  }

  Checkbox(event) {
    this.count = this.XLList.length;
    if (this.count == 0) {
      this.checked = false;
      //console.log(this.count);
    }
    else {
      this.checked = true;
      this.count;
      //console.log(this.count);
    }
  }

  handleChange(e) {

    var index = e.index;
    this.tabEvent.emit(index);
  }

  downloadExcel() {
    this.finishedCount = 0;
    this.XLListlength = this.XLList.length;
    for (var i = 0; i < this.XLList.length; i++) {
      if (this.XLList[i] == 'NPS') {
        this.loading = true;
        this.exportData('NPS');
      }
      if (this.XLList[i] == 'RESPONSE') {
        this.loading = true;
        this.exportData('RESPONSE');
      }
      if (this.XLList[i] == 'TOP5') {
        this.loading = true;
        this.exportData('TOP5');
      }
      if (this.XLList[i] == 'NPSCancellation') {
        this.loading = true;
        this.exportData('NPSCancellation');
      }
    }
  }

  responseRateExport(parameter, fileName) {    
    const param = this.filterService.filterObj;
    param['RequiredInSurveyLanguage'] = this.parameter.RequiredInSurveyLanguage;
    param['ReportType'] = this.parameter.ReportType;

    this.distDashboardService
      .exportExcelReport(param)
      .then(rawData => {
        // code for time out or unauthorize
        if (rawData.size === 0 && rawData.type === 'text/plain') {
          window.sessionStorage.clear();
          window.location.href = './home/logout';
          console.log('rrt export excel logout');
          return;
        }
        this.finishedCount = this.finishedCount + 1;
        const rawResponse: any = rawData;
        if (rawData.size > 0) {
          this.converBlobtToFile(rawResponse, fileName + '_' + this.datetimestamp() + '.xlsx');

        } else {

          this.successMsgForDownload = true;
          this.callErrorMsg(
            'error',
            '',
            this.genericErrorMessage
          );
        }
        if(this.finishedCount == this.count)
          this.loading = false;
      })
      .catch(e => {
        this.loading = false;
        this.successMsgForDownload = true;
        this.callErrorMsg(
          'error',
          '',
          this.genericErrorMessage
        );
      });

  }

  converBlobtToFile(theBlob: Blob, fileName: string) {
    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
      window.navigator.msSaveBlob(theBlob, fileName);
    } else {
      const file: any = new Blob([theBlob], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
  }

  // severity: info/success/warn/error
  callErrorMsg(type, summary = '', detail = '') {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    window.scrollTo(0, 0);
    setTimeout(function () {
      document.getElementById('ClearMsg').click();
    }, this.MessageTimeOut);
  }

  clearMessage() {
    this.msgs = [];
    if (this.successMsg) {
      this.successMsg = false;
    }
    if (this.successMsgForDownload) {
      this.successMsgForDownload = false;
    }

  }

  datetimestamp() {
    var today = new Date();
    var sToday = (((today.getMonth() + 1) < 10 ? '0' : '') + (today.getMonth() + 1)).toString();
    sToday += ((today.getDate() < 10 ? '0' : '') + today.getDate()).toString();
    sToday += today.getFullYear().toString() + '_';
    sToday += (today.getHours() < 10 ? '0' : '') + today.getHours().toString();
    sToday += (today.getMinutes() < 10 ? '0' : '') + today.getMinutes().toString();
    return sToday;
  }
  downloadPanelMouseLeave() {
    this.showDownloadPanel = false;
  }
  widgetToDownloadEvent(event) {
    // this.sharedService.updateChartExportSubject(event.toString());
    this.showDownloadPanel = false;
    this.pptExportBlockUI = true;
    setTimeout(() => {
      this.sharedService.updateChartExportSubject(event);
      // this.exportChartsToReports(event.toString().split('_')[1]);
    }, 300);
    this.downloadPanelMouseLeave();
    setTimeout(() => {
      this.pptExportBlockUI = false;
    }, 90000);
  }
}
