import { Component, ElementRef, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { HeatMapDetailsPopupComponent } from '../heat-map-details-popup/heat-map-details-popup.component';
import { DynamicComponentService } from "src/app/shared/services/dynamic-component.service"

@Component({
  selector: 'app-heat-map-details',
  templateUrl: './heat-map-details.component.html',
  styleUrls: ['./heat-map-details.component.scss']
})
export class HeatMapDetailsComponent implements OnInit {
  private imagepath = 'assets/images/';
  map: mapboxgl.Map | undefined;
  @ViewChild('mapElement') mapElement: ElementRef;
  style = 'mapbox://styles/mapbox/streets-v11';
  lat: number = 0;
  lng: number = 0;
  zoom: number = -1;
  popup = null;
  loader = false;
  selectedEntityType = ['P', 'N', 'D', 'NR'];
  @Input('filterObj') filterObj;
  @Input('dataObj') dataObj;
  @Input('resizeIndex') resizeIndex;
  @Output() detailsViewChange: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public dynamicComponentService: DynamicComponentService,
  ) {

  }

  ngOnInit() {

    (mapboxgl as any).accessToken = environment.mapbox.accessToken;

    let mapObj = {
      container: this.mapElement.nativeElement,
      style: this.style,
      center: [this.lng, this.lat],
      zoom: this.zoom,
      projection: 'mercator',
      minZoom: -1
    };

    this.map = new mapboxgl.Map(mapObj);
    /* this.map.getCanvas().style.width = '1000px';
    this.map.getCanvas().style.height = '58.7vh';
    this.map.resize(); */
    let highlightCountryData = this.dataObj.highlightCountryData,
    hilightedCountry = null,
    _this = this;
    _this.popup = null;
    this.loader = true;
    _this.map.on('load', () => {
      _this.map.setRenderWorldCopies(false);
      _this.map.resize();
      _this.map.addControl(new mapboxgl.FullscreenControl());

      // Add zoom and rotation controls to the _this.map.
      _this.map.addControl(new mapboxgl.NavigationControl());

      _this.map.addSource('countries', {
        type: 'vector',
        url: 'mapbox://mapbox.country-boundaries-v1?optimize=true'
      });
      _this.map.addLayer(
        {
          'id': 'countries-layer',
          'type': 'fill',
          'source': 'countries',
          'source-layer': 'country_boundaries',
          'paint': {
            'fill-color': _this.dataObj.matchExpression,
            "fill-opacity": ['interpolate',
            // Set the exponential rate of change to 0.5
            ['exponential', 0.5],
            ['zoom'],
            2,
            1,
            22,
            0.025],
          },
          'filter': ["all"]
        },
        'admin-1-boundary-bg'
      );

      _this.map.setPaintProperty('water', 'fill-color', '#DFE8ED');


      _this.map.addLayer({
        "id": "cfh",  // country-fills-hover",
        "type": "fill",
        "source": "countries",
        "source-layer": "country_boundaries",
        "layout": {},
        "paint": {
          "fill-color": '#A9D18E',
          "fill-opacity": ['interpolate',
          // Set the exponential rate of change to 0.5
          ['exponential', 0.5],
          ['zoom'],
          2,
          1,
          22,
          0.025],
          "fill-outline-color": "#000000",
        },
        "filter": ["==", "name", ""]
      },
      'admin-1-boundary-bg'
      );

      // Add an image to use as a custom marker
      _this.map.loadImage(
        this.imagepath +'mapbox-marker-icon-20px-green.png',
        (error, image) => {
          if (error) throw error;
          _this.map.addImage('custom-marker-green', image);
        }
      );

      // Add an image to use as a custom marker
      _this.map.loadImage(
        this.imagepath +'mapbox-marker-icon-20px-red.png',
        (error, image) => {
          if (error) throw error;
          _this.map.addImage('custom-marker-red', image);

        }
      );

      // Add an image to use as a custom marker
      _this.map.loadImage(
        this.imagepath + 'mapbox-marker-icon-20px-yellow.png',
        (error, image) => {
          if (error) throw error;
          _this.map.addImage('custom-marker-yellow', image);
        }
      );

      // Add an image to use as a custom marker
      _this.map.loadImage(
        this.imagepath + 'mapbox-marker-icon-20px-gray.png',
        (error, image) => {
          if (error) throw error;
          _this.map.addImage('custom-marker-gray', image);
          _this.map.addSource('points-source', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': [..._this.dataObj.featureCollection],
            }
          });
          _this.map.addLayer({
            'id': 'points',
            'type': 'symbol',
            'source': 'points-source',
            'layout': {
              'icon-image': [
                'match',
                ['get', 'PNDStatus'], // type corresponds to the field name you are keying off of
                ['P'],
                'custom-marker-green',
                ['D'],
                'custom-marker-red',
                ['N'],
                'custom-marker-yellow',
                'custom-marker-gray' // fallback icon
              ],
              'symbol-sort-key': [
                'match',
                ['get', 'PNDStatus'], // type corresponds to the field name you are keying off of
                ['P'],
                2,
                ['D'],
                4,
                ['N'],
                3,
                1
              ],
              'icon-allow-overlap': true,
              "text-allow-overlap": true,
              "icon-size": ['interpolate',
                // Set the exponential rate of change to 0.5
                ['exponential', 0.5],
                ['zoom'],
                0,
                0.09,
                18,
                1],
            }
          },
            'admin-1-boundary-bg'
          );
        }
      );
      this.loader = false;
    });

    _this.map.on('mousemove', 'countries-layer', function (e) {
      var features = _this.map.queryRenderedFeatures(e.point, { layers: ['countries-layer'] });
      let hilightCountry = null;
      if (features.length > 0 && highlightCountryData) {
        highlightCountryData.forEach((item) => {
          if (item.code === features[0].properties.iso_3166_1_alpha_3) {
            hilightCountry = features[0].properties.name
          }
        });
        if (hilightCountry) {
          if (hilightedCountry !== null) {
            _this.map.setFilter("cfh", ["in", "name", hilightCountry, hilightedCountry]);
          } else {
            _this.map.setFilter("cfh", ["==", "name", hilightCountry]);
          }

        } else {
          if (hilightedCountry !== null) {
            _this.map.setFilter("cfh", ["==", "name", hilightedCountry]);
          } else {
            _this.map.setFilter("cfh", ["==", "name", ""]);
            _this.map.getCanvas().style.cursor = '';
          }
        }
      } else {
        if (hilightedCountry !== null) {
          _this.map.setFilter("cfh", ["==", "name", hilightedCountry]);
        } else {
          _this.map.setFilter("cfh", ["==", "name", ""]);
          _this.map.getCanvas().style.cursor = '';
        }
      }
    });
    _this.map.on('mouseleave', 'countries-layer', function (e) {
      _this.map.setFilter("cfh", ["==", "name", ""]);
      _this.map.getCanvas().style.cursor = '';
    });

    _this.map.on('zoom', () => {
      if (!_this.popup) return;
      else if (_this.popup.isOpen()) _this.popup.remove();
    });

    _this.map.on('click', 'countries-layer', (e) => {
      if (_this.map.getZoom() < 4) {
        var features = _this.map.queryRenderedFeatures(e.point, { layers: ['countries-layer'] });
        let hilightCountry = null;
        let standardAccount = null;
        if (features.length > 0 && highlightCountryData) {
          highlightCountryData.forEach((item) => {
            if (item.code === features[0].properties.iso_3166_1_alpha_3) {
              hilightCountry = features[0].properties.name
              standardAccount = { country: hilightCountry, ...item };
            }
          });
          if (hilightCountry) {
            hilightedCountry = hilightCountry;
            _this.map.setFilter("cfh", ["==", "name", hilightCountry]);
            var coordinates = e.lngLat;
            let popupContent = this.dynamicComponentService.injectComponent(
              HeatMapDetailsPopupComponent,
              x => {
                x.entity = { ...standardAccount, EntityId: null, GlobalEntityId: null };
                x.filterObj = { ...this.filterObj, EntityId: null, GlobalEntityId: null };
              });

            _this.popup = new mapboxgl.Popup({ closeButton: false })
              .setLngLat(coordinates)
              .setDOMContent(popupContent)
              .addTo(_this.map);
            _this.popup.on('close', () => {
              hilightedCountry = null;
            });


            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

          } else {
            _this.map.setFilter("cfh", ["==", "name", ""]);
            _this.map.getCanvas().style.cursor = '';
            hilightedCountry = null;
          }
        } else {
          _this.map.setFilter("cfh", ["==", "name", ""]);
          _this.map.getCanvas().style.cursor = '';
          hilightedCountry = null;
        }
      }
    });


    _this.map.on('click', 'points', (e) => {
      var features = _this.map.queryRenderedFeatures(e.point, { layers: ['points'] });
      var coordinates = e.features[0].geometry.coordinates.slice();
      let popupContent = this.dynamicComponentService.injectComponent(
        HeatMapDetailsPopupComponent,
        x => {
          x.entity = { ...e.features[0].properties, GlobalEntityId: null, StandardEntityId: null };
          x.filterObj= { ...this.filterObj, GlobalEntityId: null, StandardEntityId: null };
        });

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      _this.popup = new mapboxgl.Popup({ closeButton: false, offset: 25 })
        .setLngLat(coordinates)
        .setDOMContent(popupContent)
        .addTo(_this.map);
      _this.popup.on('close', () => {
        hilightedCountry = null;
      });

    });
  }
  ngOnChanges(changes) {
    if (changes.resizeIndex && changes.resizeIndex.currentValue) {
      if (this.map) {
        this.map.getContainer().style.height = (this.map.getContainer().offsetParent.offsetHeight - 1) + 'px';
        this.map.resize();
      }
      if (!this.popup) return;
      else if (this.popup.isOpen()) this.popup.remove();
    }
  }
  backTo360() {
    this.detailsViewChange.emit({
      backTo360: true
    });
  }
  filterMarker(type) {
    let arr = [];
    let ind = this.selectedEntityType.findIndex(x => x === type);
    if (ind >= 0) {
      this.selectedEntityType.splice(ind, 1);
    } else {
      this.selectedEntityType.push(type);
    }
    if (this.selectedEntityType.includes('D')) {
      arr.push(['D'], 'custom-marker-red');
    } else {
      arr.push(['D'], '');
    }
    if (this.selectedEntityType.includes('N')) {
      arr.push(['N'], 'custom-marker-yellow');
    } else {
      arr.push(['N'], '');
    }
    if (this.selectedEntityType.includes('P')) {
      arr.push(['P'], 'custom-marker-green');
    } else {
      arr.push(['P'], '');
    }
    if (this.selectedEntityType.includes('NR')) {
      arr.push('custom-marker-gray');
    } else {
      arr.push('');
    }
    if (arr.length) {
      arr = [
        'match',
        ['get', 'PNDStatus'], ...arr];
    }

    this.map.setLayoutProperty('points', 'icon-image', arr);
  }
}